import React, {useEffect, useState} from 'react';
import {Col, Collapse, notification, Row, Spin} from 'antd';
import CostoIngredienti from '../costoIngredienti/CostoIngredienti';
import style from '../NewOfferta.module.scss';
import {DeleteOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
import {lingua} from '../../../Config/Lingua_it';
import FloatNumber from '../../floatNumber/FloatNumber';
import NoIngredienti from '../noIngredienti/NoIngredienti';
import FloatText from '../../floatText/FloatText';
import {getRequest} from '../../../Config/axiosClient';
import {ParseToDecimal4}from '../../../helpers/Helper';
import {toFixedX} from '../../../helpers/Helper';
import FloatSelectCode from '../../floatSelectCode/FloatSelectCode';

const {Panel} = Collapse;

//
// const genExtra = () => (
//   <DeleteOutlined
//     onClick={event => {
//       // If you don't want click extra trigger collapse, you can prevent this:
//       event.stopPropagation();
//       console.log('Ricette - CLICK DELETE -  >>> ');
//     }}
//   />
// );

const Ricetta = props => {

  // const {ingredientiPlane} = useSelector(state => state.authenticateReducer);
  // const {loader} = useSelector(state => state.authenticateReducer);

  const [nome, setRicettaName] = useState(lingua.offerta.nuovaRicetta);
  const [ricettaId, setRicettaId] = useState(null);
  // const [ricettaScelta, setRicettaScelta] = useState(null);
  const [margineScelto, setMargineScelto] = useState(null);
  const [scartoScelto, setScartoScelto] = useState(null);
  const [techmodScelto, setTechmodScelto] = useState('');

  // const [pezziCartoneOfferta, setPezziCartoneOfferta] = useState(0);

  // const [totale, setTotale] = useState(0);
  const [totale1, setTotale1] = useState(0);
  const [totale2, setTotale2] = useState(0);
  const [totale3, setTotale3] = useState(0);
  const [totale4, setTotale4] = useState(0);
  const [rateManodoperaScelto, setRateManodopera] = useState(null);
  const [rateEnergiaScelto, setRateEnergia]= useState(null);
  const [ricettaObj, setRicettaObj]= useState({});

  const [loader, setLoader]= useState(false);
  // const [ricettaCompleta, setRicettaCompleta ]= useState([]);
  const [ingredientiTree, setIngredientiTree ]= useState([]);
  const [ingredientiPlane, setIngredientiPlane]= useState([]);

  /**
   *
   * BELL'IDEA:
   // store the startTime prop in local state
   const [startTime, setStartTime] = useState(props.startTime)
   //
   useEffect(() => {
      if (props.startTime !== startTime) {
        setStartTime(props.startTime);
      }
    }, [props.startTime]);

   */

  //////////////// START UTILS

  const resetRicetta = () =>{
    // console.log(' -                                                 R   E   S   E   T    -  >>> '   );

    //reset campi
    setRicettaId(null);
    setRicettaName(lingua.offerta.nuovaRicetta);
    // setRicettaScelta(null);
    setIngredientiPlane([]);
    setIngredientiTree([]);
    setMargineScelto(null);
    setTechmodScelto('');
    setScartoScelto(null);
    setRateManodopera(null);
    setRateEnergia(null);
    setTotale1(0);
    setTotale2(0);
    setTotale3(0);
    setTotale4(0);

    //reset dell'obj della ricetta
    setRicettaObj({});
    // setIngredientiObj([]);
    //
    updateObj({ricetta_id:null}, true);

    //toDo capire se questo serve davvero:
    const me = {bloccoId:props.id}
    //passo un oggetto vuoto
    props.onUpdate(me);
  }

  function planeIngredienti(listone){

    let listaPiatta = [];
    // esiste? perché alcune ricette non hanno dati
    if(listone && listone.length > 0){
      let nl = listone.length;
      let i = -1;
      while(++i < nl){
        let elm = listone[i];

        const {pezziCartone, grammi} = props;
        const riga_scarto = elm.scarto?elm.scarto:0;
        const ric_scarto = scartoScelto?scartoScelto:0;
        const tot_riga = ParseToDecimal4(
          (elm.prezzo*elm.grammi*(1 + riga_scarto/100)*pezziCartone*grammi/1000000)*(1+ric_scarto/100));


        let obj = {ricetta_ingrediente_id:elm.id, prezzo: elm.prezzo, costo:tot_riga};

        // salvo sempre l'elemeto, anche il parent, come detto da Anto:
        // listaPiatta.push(obj);

        if(elm.ingredienti && elm.ingredienti.length > 0 ){
          // ha figli? metto il costo a 0 così da non usarlo nella somma totale, visto che da email del 27 non tornano i conti
          obj.costo = 0;
          listaPiatta.push(obj);
          //ha figli? ricorsione:
          listaPiatta = [...listaPiatta, ...planeIngredienti(elm.ingredienti)];
        }else{
          listaPiatta.push(obj);
        }
      }
    }else{
      // console.warn('NESSUN INGREDIENTE');
    }
    return listaPiatta;
  }

  const openErrorNotification = (erp_err = false) => {
    const args = {
      message: lingua.errors.titolo,
      description: erp_err? lingua.errors.erpFails : lingua.errors.ricettaNotLoaded,
      duration: erp_err? 8 : 2,
      placement:'bottomRight',
      type:'error',
    };
    notification.open(args);
  };

  //////////////// END UTILS


  useEffect(() => {
    const ingredientiPlaneRicetta = planeIngredienti(ingredientiTree);
    setIngredientiPlane(ingredientiPlaneRicetta);
  }, [scartoScelto]);

  useEffect(() => {
    updateTotali();
  }, [ricettaId, margineScelto, rateManodoperaScelto, rateEnergiaScelto, ingredientiPlane, totale1]);

  useEffect(() => {
    updateTotali();
  }, [props.pezziCartone, props.grammi, props.costiCartone, props.costiPackaging]);


  const genDelete = () => <>
    {/*<span className={style.totaleCollapse}>Costo: <b>{this.showTotale(this.state.totale)}</b></span>*/}
    <DeleteOutlined
      className={style.deleteCollapse}
      onClick={event => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
        props.onDelete(props.id);
      }}/>
  </>;

  /**
   * spara l'oggetto aggiornato all'offerta
   * @param nuovo nuovo obj per aggiornare in props.onUpdate
   */
  const updateObj = (nuovo ) => {
    // if(nuovo.ricetta_id) {
    //   console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>                             ',nuovo.ricetta_id);
    // }


    // per distinguerle visto che possono esistere ricette con id uguali
    const me = {bloccoId:props.id}

    //fondo tutto, me, la ricetta
    // let objId = {};
    // if(props.record && !ricettaId){
    //   // alert(' NON C'é L' ID ', ricettaId)
    //   objId.ricetta_id = props.record.id;
    //   setRicettaObj(props.record.id);
    // }
    //messo questo controllo perché al primo giro a volte non imposta gli ingredienti
    // const tot = nuovo.ingredienti? {...me, ...objId, ...ricettaObj, ...nuovo}:
    //   {...me, ...ricettaObj, ...nuovo, ...{ingredienti:ingredientiPlane}}
    // console.table(ricettaObj  );
    const tot = nuovo.ingredienti && nuovo.ingredienti.length > 0? {...me, ...ricettaObj, ...nuovo}:
      {...me, ...ricettaObj, ...nuovo, ...{ingredienti:ingredientiPlane}}

    // const tot =   {...me, ...objId, ...ricettaObj, ...nuovo, ...{ingredienti:ingredientiPlane}};

    setRicettaObj(tot);
    // if(dontSend) return;

    // lo invio aggiornato all'offerta
    props.onUpdate(tot);
  }

  const updateTotali = (ingrNuovi) =>{

    let costi_ingredienti_tot;
    if(ingrNuovi && ingrNuovi.length > 0){
      // c'è una lista nuova
      costi_ingredienti_tot = ingrNuovi.reduce((cur, elm) => ({costo: cur.costo+elm.costo}) );
    }else if(ingredientiPlane && ingredientiPlane.length >0){
      //uso la lista precendente
      costi_ingredienti_tot = ingredientiPlane.reduce((cur, elm) => ({costo: cur.costo+elm.costo}) );
    }else {
      setTotale2(0);
      setTotale1(0);
      setTotale3(0);
      setTotale4(0);

      updateObj({prezzo_vendita:0,costo_confezione:0, costo_singolo_pack:0, prezzo_singolo_pack:0});
      return
    }


    const cManod = getCostoManodopera();
    const cEner = getCostoEnergia();
    // console.log(' ......................   - updateTotali -  ', props.costiCartone, props.costiPackaging);
    // console.log(' *************  updateTotali - costi_ingredienti_tot, cManod, cEner >>> ', costi_ingredienti_tot, cManod, cEner  );
    // console.log(' - updateTotali -  >>> ', ingredientiPlane  );
    // console.log(' ......................   - updateTotali -  ');
    let costo_cartone = (costi_ingredienti_tot.costo+cManod+cEner);
    if(props.costiCartone || props.costiCartone === 0) costo_cartone +=props.costiCartone;
    if(props.costiPackaging || props.costiPackaging === 0) costo_cartone +=props.costiPackaging;
    setTotale2(ParseToDecimal4(costo_cartone));

    let margine = (100 - margineScelto)/100;
    if (margine === 0) margine = 1;

    const prezzo_vendita_dec = (costo_cartone) / margine;
    const prezzo_vendita = toFixedX (prezzo_vendita_dec, true);

    setTotale1(prezzo_vendita);
    const tot3_dec = props.pezziCartone?((costo_cartone)/props.pezziCartone):0;
    const tot3 = toFixedX(tot3_dec, true);
    setTotale3(tot3);

    let tot4_dec =  ((tot3_dec) / margine);
    let tot4 = toFixedX (tot4_dec, true, 3);
    setTotale4(tot4);

    updateObj({prezzo_vendita:prezzo_vendita,costo_confezione:toFixedX(costo_cartone, true),
      costo_singolo_pack:tot3,
      prezzo_singolo_pack:tot4,
      scarto:scartoScelto, techmod:techmodScelto});

  }

  const onSelectedRicetta = (value) => {
    if (value === undefined) {
      resetRicetta();
      return;
    }
    if(value !== ricettaId){
      // cambiato la ricetta scelta, resetto i campi - mi serviva con quelle da record, ora come ora è un filo useless
      // resetRicetta();
    }

    // const {listaRicette} = props;

    setRicettaId(value);
    // reset?
    setMargineScelto(null);
    setRateManodopera(null);
    setRateEnergia(null);
    // tutto ora dentro la chiamata:
    // setRicettaName(`${scelto?.code} - ${scelto?.nome}`)
    // setRicettaScelta(scelto?.ingredienti);
    // setScartoScelto(scelto?.scarto);
    // setTechmodScelto(scelto?.techmod);

    setTotale1(0);
    setTotale2(0);
    setTotale3(0);
    setTotale4(0);

    setTechmodScelto('');
    setScartoScelto(null);
    // setIngredientiPlane([]);
    // setIngredientiTree([]);

    /// questo va messo subito così da impostarlo immediatamente
    updateObj({ricetta_id:value});


    const urlmodificata = `ricetta/${value}`;
    setLoader(true);

    //recuperato la ricetta dalla chiamata ai service senza usare il dispatch così da non avere :
    const ricettaIngredienti = getRequest(urlmodificata).then(response  => {

      const ricettaResult = response.data.data.ricetta;

      setRicettaName(`${ricettaResult.code} - ${ricettaResult.nome}`)
      setIngredientiTree(ricettaResult.ingredienti);
      setTechmodScelto(ricettaResult.techmod);
      setRicettaId(value);

      const ingredientiPlaneRicetta = planeIngredienti(ricettaResult.ingredienti);
      // console.log(' -  - ingredientiPlaneRicetta >>> ', ingredientiPlaneRicetta  );

      setIngredientiPlane(ingredientiPlaneRicetta);
      // OCCHIO setScartoScelto per l'USEFFECT va sempre DOPO setIngredientiPlane
      setScartoScelto(ricettaResult.scarto);
      // setRicettaScelta(ricettaResult.ingredienti);
      updateObj({ricetta_id:value, scarto:ricettaResult.scarto, techmod:ricettaResult.techmod, ingredienti:ingredientiPlaneRicetta});
      // updateTotali(ingredientiPlaneRicetta);
      // updateTotali(ingredientiPlaneRicetta? ingredientiPlaneRicetta:[]);
      // updateObj({});
      setLoader(false);
    }).catch(error => {
      console.error('Errore nel caricamento della ricetta:', error);
      setLoader(false);
      setIngredientiPlane([]);
      setIngredientiTree([]);

      updateObj({prezzo_vendita:0,costo_confezione:0, costo_singolo_pack:0});

      if (error.response
          && error.response.data
          && error.response.data.code === 101
          && error.response.data.data
          && error.response.data.data.error === 'Bad configuration prevents application or service to start'
      ) {
        openErrorNotification(true);
      } else {
        openErrorNotification();
      }


    });

  }

  const onUpdateRigaIngrediente = (value, id, costo, grammi) =>{
    // console.log('%c - onUpdateRigaIngrediente -  >>> ', 'background: #222; color: #bada55');
    // console.log(' - onUpdateRigaIngrediente - value, id, costo >>> ', value, id, costo  );
    //aggiorno il prezzo
    const elm = ingredientiPlane.find(elm => elm.ricetta_ingrediente_id === id);
    if (elm) {
      elm.prezzo = value;
      elm.costo = costo;
      elm.grammi = grammi;
      updateTotali();
    }


    // QUESTO FORSE DA FASTIDIO:
    //update della ricetta
    /*
      updateTotali();
      updateObj({});
    */
  }

  const onRateManodoperaSelected = (value) => {
    setRateManodopera(value);
    //non sono sicuro di questo, al momento commento così è sempre null:
    // if(value === null) value = undefined;
    updateObj({rate_manodopera:value});
  }

  const onRateEnergiaSelected = (value) => {
    setRateEnergia(value);
    updateObj({rate_energia:value});
  }

  const onMargineSelected = (value) => {
    setMargineScelto(value);
    updateObj({margine:value});
  }

  const onScartoSelected = (value) => {
    // ATTENZIONE questo scatena lo useEffect su planeIngredienti
    if(value === null) value = 0;
    setScartoScelto(value);

    updateObj({scarto:value});
  }


  const getLista = (nome, nuovaLista, index) => {
    // const {rateManodoperaScelto, rateEnergiaScelto} = this.state;

    if (nuovaLista?.length === 0 ) {
      return (<Collapse
        forceRender={true}
        collapsible={'disabled'} className='site-collapse-custom-collapse'
        expandIconPosition='right'>
        <Panel header={nome} key={`last ${index} ${nome}`} forceRender={true} extra={genDelete()}>
        </Panel>
      </Collapse>);
    }
    // senza ingredienti
    const foglia = nuovaLista?.filter(ing => ing.ingredienti?.length === 0);
    foglia?.map(elm => elm.nomecode = `${elm.code} - ${elm.nome}`)

    // con ingredienti
    const sottoLivello = nuovaLista?.filter(ing => ing.ingredienti?.length !== 0);

    ++index;

    const getCollapse1 = () => <Collapse
      key={`first ${index} ${nome}`}
      forceRender={true}
      className='site-collapse-custom-collapse' expandIconPosition='left'>
      <Panel collapsible={nome === '' || nome === lingua.offerta.nuovaRicetta || loader? 'disabled':''} forceRender={true} header={nome} key={`firstPanel ${index} ${nome}`} extra={genDelete()}>
        {foglia?.length > 0?<CostoIngredienti
          onUpdateRiga={onUpdateRigaIngrediente}
          pezziCartone={props?.pezziCartone}
          grammi={props?.grammi}
          scartoRicetta={scartoScelto}
          data={foglia}/>:null}
        {sottoLivello.length > 0? (sottoLivello.map(el => getLista(`${el.code} - ${el.nome}`, el.ingredienti, index))):null}
        <br/>
        {props?
          <Row gutter={[{xs:6, sm:12, md:25}, {xs:6, sm:12, md:24}]}>
            <Col xs={24} sm={24} md={6}>
              <FloatNumber
                isPerc={false}
                newValue={rateManodoperaScelto}
                minValue={0}
                field={lingua.offerta.ricette.rateManodopera}
                onSelected={onRateManodoperaSelected}/>
              {/*<FloatAutoComplete*/}
              {/*  field={lingua.offerta.ricette.rateManodopera}*/}
              {/*  newValue={rateManodoperaScelto}*/}
              {/*  options={props.listaRateManodopera}*/}
              {/*  onSelected={onRateManodoperaSelected}/>*/}
            </Col>
            <Col xs={24} sm={24} md={6}>
              <FloatNumber
                isPerc={false}
                minValue={0}
                newValue={rateEnergiaScelto}
                field={lingua.offerta.ricette.rateEnergia}
                onSelected={onRateEnergiaSelected} />
              {/*<FloatAutoComplete*/}
              {/*  field={lingua.offerta.ricette.rateEnergia}*/}
              {/*  newValue={rateEnergiaScelto}*/}
              {/*  options={props.listaRateEnergia}*/}
              {/*  onSelected={onRateEnergiaSelected}/>*/}
            </Col>
            {/*<Col span={24}>*/}
            {/*  <h2 className={style.totale}>{showTotale()}</h2>*/}
            {/*</Col>*/}
          </Row>:null}
      </Panel>
    </Collapse>;


    const getCollapse2 = () => <Collapse
      key={`child ${index} ${nome}`} forceRender={true} expandIconPosition='left'>
      <Panel header={nome} key={`childPanel ${index} ${nome}`} forceRender={true} ghost>
        {foglia.length>0?<CostoIngredienti
          onUpdateRiga={onUpdateRigaIngrediente}
          updateTotali={updateTotali}
          pezziCartone={props?.pezziCartone}
          grammi={props?.grammi}
          scartoRicetta={scartoScelto}
          data={foglia}/>:null}
        {sottoLivello.length > 0? (sottoLivello.map(el => getLista(`${el.code} - ${el.nome}`, el.ingredienti, index))):null}
      </Panel>
    </Collapse>;

    return (index === 1? getCollapse1():getCollapse2())
  }

  // const {ricettaId, nome, ricettaScelta, margineScelto} = this.state;


  const getCostoManodopera = () =>{
    const {pezziCartone, grammi} = props;
    if(!pezziCartone || !grammi || !rateManodoperaScelto) return 0;
    return (pezziCartone*rateManodoperaScelto*grammi)*.001;
  }
  const getCostoEnergia = () =>{
    const {pezziCartone, grammi} = props;

    if(!pezziCartone || !grammi || !rateEnergiaScelto) return 0;

    return (pezziCartone*rateEnergiaScelto*grammi)*.001;

  }

  // const showCostoConfezione = () =>{
  //   // const {totale} = this.state;
  //   if (!totale) return getCostoManodopera();
  //   return 0;
  // }
  //
  // const showPrezzoVendita = () =>{
  //   // const {totale} = this.state;
  //   if (!totale) return getCostoEnergia();
  //   return 0;
  // }
  //
  // const showCostoSingoloPack = () => {
  //   // const {totale} = this.state;
  //   if (!totale) return 0;
  //   let tot = totale % 1 > 0? `${totale.toString().replace('.', ',')} €`:
  //     `${totale},00 €`
  //   return (<span>{tot}</span>);
  // }


  const onSetValueFromRecord = () => {
    const {record} = props;

    if (record && record.id) {
      //esiste il record, autocompleto
      if (ricettaId === null) {
        //elm_scelto esiste nel caso in cui ho cambiato la select e quindi qui non ci entro

        setRicettaId(record.ricetta_id)
        const ingredientiPlane = planeIngredienti(record.ingredienti);
        setIngredientiPlane(ingredientiPlane);
        // setRicettaCompleta(record);

        setRicettaName(`${record.code} - ${record.nome}`)
        // setRicettaScelta(record.ingredienti);
        setIngredientiTree(record.ingredienti);

        // OCCHIO setScartoScelto per l'USEFFECT va sempre DOPO setIngredientiPlane
        setScartoScelto(record.scarto);
        setTechmodScelto(record.techmod);
        // setRicettaScelta(record.ingredienti);
        setRateEnergia(record.rate_energia);
        setRateManodopera(record.rate_manodopera);
        setMargineScelto(record.margine);

        updateObj({ricetta_id:record.id, ...record});
      }
    }
    return record;
  }
  onSetValueFromRecord();

  // const onInputChange = (evt) =>{
  //   let margine = evt/totale2;
  //   if (margine < 1) margine = 0;
  //
  //   setMargineScelto(margine);
  // }

  // console.log('planeIngredienti - Ricetta - props.record >>> ', props.record  );
  return <>
    {/*<div><h2>{props.id} -- Scarto: {scartoScelto} </h2></div>*/}
    <Row gutter={[{xs:6, sm:12, md:25}, {xs:6, sm:12, md:24}]}>
      <Col xs={24} sm={24} md={12}>
        <FloatSelectCode
          // notAllowClear={props.dontClear}
          notAllowClear={true}
          disabled={props.dontClear}
          newValue={ricettaId}
          field={'Ricetta'}
          isRicette={true}
          options={props.listaRicette}
          onSelected={onSelectedRicetta}/>
      </Col>
      <Col xs={24} sm={24} md={4}>
        <FloatText left={true} field={lingua.offerta.ricette.descrizione} newValue={techmodScelto}/>
      </Col>

      <Col xs={24} sm={24} md={4}>
        <FloatNumber
          isPerc={true}
          field={lingua.offerta.ricette.techmod}
          newValue={scartoScelto}
          disabled={!ricettaId}
          minValue={0}
          onSelected={onScartoSelected}/>
      </Col>
      <Col xs={24} sm={24} md={4}>

        <FloatNumber
          isPerc={true}
          field={lingua.offerta.ricette.margine}
          newValue={margineScelto}
          disabled={!ricettaId}
          onSelected={onMargineSelected}/>
        {/*<FloatAutoComplete*/}
        {/*  disabled={!ricettaId}*/}
        {/*  field={lingua.offerta.ricette.ricarico}*/}
        {/*  newValue={margineScelto}*/}
        {/*  options={props.listaRicarico}*/}
        {/*  onSelected={onMargineSelected}/>*/}

        {/*<NumericInput icona={<EuroIcon/>} style={{height:50}}*/}
        {/*              record={record}*/}
        {/*              record={record.costo.toString()}*/}
        {/*              onChange={(evt) => onEditCosto(evt, record)}/>*/}
      </Col>
    </Row>
    <Row gutter={[{xs:6, sm:12, md:25}, {xs:6, sm:12, md:24}]}>
      <Col xs={24} sm={24} md={{span:6, offset:0}}>
        {ricettaId !== null? <h2 className={style.totale}><span>{lingua.offerta.ricette.totale1} </span>{toFixedX(totale1)}</h2>:null}
      </Col>
      <Col xs={24} sm={24} md={{span:6, offset:0}}>
        {ricettaId !== null? <h2 className={style.totale}><span>{lingua.offerta.ricette.totale2} </span>{toFixedX(totale2)}</h2>:null}
      </Col>
      <Col xs={24} sm={24} md={{span:6, offset:0}}>
        {ricettaId !== null? <h2 className={style.margine}><span>{lingua.offerta.ricette.totale4} </span>{toFixedX(totale4, false, 3)}</h2>:null}
      </Col>
      <Col xs={24} sm={24} md={{span:6, offset:0}}>
        {ricettaId !== null? <h2 className={style.margine}><span>{lingua.offerta.ricette.totale3} </span>{toFixedX(totale3)}</h2>:null}
      </Col>
      {/*<Col xs={24} sm={24} md={{span:6, offset:0}}>*/}
      {/*  <InputNumber value={totale1} onInput={onInputChange}  ></InputNumber>*/}
      {/*</Col>*/}
    </Row>
    {loader?<Spin color={'#ff0000'} />:null}
    {ingredientiTree? getLista(nome, ingredientiTree, 0):(ricettaId?<NoIngredienti loader={loader}/>:null)  }
  </>;

}

Ricetta.propTypes = {
  id:PropTypes.number,
  idRicettaScelta:PropTypes.number,
  onDelete:PropTypes.func,
  onUpdate:PropTypes.func,

  record:PropTypes.object,

  pezziCartone:PropTypes.number,
  grammi:PropTypes.number,
  costiPackaging:PropTypes.number,
  costiCartone:PropTypes.number,

  dontClear:PropTypes.bool,

  listaRicette:PropTypes.arrayOf(
    PropTypes.shape({
      key:PropTypes.string,
      nome:PropTypes.string,
      grammi:PropTypes.number,
      scarto:PropTypes.number,
      costo:PropTypes.number,
      techmod:PropTypes.string,
    }),
  ),
  listaFormato:PropTypes.arrayOf(PropTypes.object).isRequired,
  listaPezziCartone:PropTypes.arrayOf(PropTypes.object).isRequired,
  listaRateManodopera:PropTypes.arrayOf(PropTypes.object).isRequired,
  listaRateEnergia:PropTypes.arrayOf(PropTypes.object).isRequired,
  listaRicarico:PropTypes.arrayOf(PropTypes.object).isRequired,

};

export default Ricetta;
