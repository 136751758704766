import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import store from 'redux/store'
import {ConfigProvider} from 'antd';
import itIT from 'antd/lib/locale/it_IT';

// import './fonts/open-sans-regular.ttf';
// import './fonts/open-sans-700.ttf';

ReactDOM.render(
  <React.StrictMode>
    {/*Redux Provider is included access the store values from anywhere inside the child components.*/}
    <Provider store={store()}>

      <ConfigProvider locale={itIT}>
        <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
