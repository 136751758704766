import React from 'react';
import {Input, Tooltip} from 'antd';
import style from './NumericInput.module.scss';
import PropTypes from 'prop-types';
import {lingua} from '../../Config/Lingua_it';
import debounce from 'lodash/debounce';

function formatNumber(value) {
  value += '';

  const posDecim = value.indexOf('.');
  let decimale = '';
  if(posDecim > -1) {
    value = value.replace('.', ',');
    decimale = value.slice(posDecim);
    value = value.slice(0, posDecim);
  }

  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-'? '-':'';
  let num = prefix? list[0].slice(1):list[0];
  let result = '';
  // spacca la cifra a gruppi di 3 con il punto
  while (num.length > 3) {
    result = `.${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1]? `.${list[1]}`:''}${decimale}`;
}

class NumericInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      last_value:0,
    };
    this.myInput = React.createRef();
  }


  // constructor(props) {
  //   super(props);
  //   this.state = {
  //   };
  // }
  // Define the method directly in your class
  onChangeDebouce = debounce((e) => {
    // funziona ma nel useeffect
    // OCCHIO: ovviamente rallenta l'apparizione delle cifre nel campo di testo
    const {onChange} = this.props;
    onChange(e, this.props.record);
  }, 100)

  onValChange = e => {
    // const {onChange} = this.props;
    const {value} = e.target;
    //se è diverso dall'ultimo salvato cambio altrimenti non faccio nulla
    if(this.state.last_value !== value){
      this.setState({last_value:value});

      const {onChange} = this.props;
      const reg = /^-?\d*(\.\d*)?$/;
      if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
        onChange(value, this.props.record);
        // this.onChangeDebouce(record);
      }
    }
    if(this.props.disabled) {
      this.onBlur();
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const {value, onBlur, onChange} = this.props;
    //se è diverso dall'ultimo salvato cambio altrimenti non faccio nulla
    if(this.state.last_value !== value && value !== null && value !== undefined) {
      this.setState({last_value:value});
      let valueTemp = value.toString();
      if (valueTemp.charAt(value.length - 1) === '.' || value === '-') {
        valueTemp = valueTemp.slice(0, -1);
      }
      onChange(valueTemp.replace(/0*(\d+)/, '$1'));
      if (onBlur) {
        onBlur();
      }
    }
  };

  onKeyHandler = evt => {
    if(evt.key === 'Enter') {
      this.myInput?.current?.blur();
    }
  }

  render() {
    const {value, maxLength} = this.props;
    const title = value? (
      <span className='numeric-input-title'>{value !== '-'? formatNumber(value):'-'}</span>
    ):(
      lingua.generic.inputCosto
    );
    return (
      <Tooltip className={style.numeric}
        trigger={['hover', 'focus']}
        title={title}
        placement='left'
        overlayClassName='numeric-input'>
        <Input
          prefix={this.props.icona}
          {...this.props}
          onChange={this.onValChange}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyHandler}
          placeholder={lingua.generic.inputCosto}
          maxLength={maxLength || 10}
          ref={this.myInput}/>
      </Tooltip>
    );
  }
}

NumericInput.propTypes = {
  icona:PropTypes.element,
  onChange:PropTypes.func.isRequired,
  disabled:PropTypes.bool,
  record:PropTypes.object,
  maxLength:PropTypes.number,
};

export default NumericInput;
