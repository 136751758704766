// import logo from 'logo.svg';
// import {Image} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import User from '../components/user/User';
import OfferteHeader from '../components/offerteHeader/OfferteHeader';
import ListaOfferte from '../components/ListaOfferte/ListaOfferte';
import actions from '../redux/Authenticate/actions';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Modal, notification} from 'antd';
import {lingua} from '../Config/Lingua_it';


const clienti = [];
for (let i = 0; i<10; ++i){
  clienti.push(`Cliente ${i}`);
}

const OffertePage = props => {

  const history = useHistory();
  const [page, setPage] = useState(1);
  const [idClienteFiltrato, setClienteFiltrato] = useState(null);

  const {error} = useSelector(state => state.authenticateReducer);
  const {responseType} = useSelector(state => state.authenticateReducer);
  const {message} = useSelector(state => state.authenticateReducer);
  // const {messages} = useSelector(state => state.authenticateReducer);
  const {loader} = useSelector(state => state.authenticateReducer);
  const {listaOfferte} = useSelector(state => state.authenticateReducer);
  // const [listaTutteOfferte, setTutte] = useState([]);
  const [listaAggiornata, setLista] = useState([]);

  const isInitialMount = useRef(true);
  const dispatch = useDispatch();

  const [modal, contextHolder] = Modal.useModal();
  const config = {
    title:(<h3>{lingua.errors.titolo}</h3>),
    centered:true,
    width:563,
    height:300,
    okText:lingua.bts.chiudi,
    content:(<div><p>{message?`${lingua.errors.generic} ${message}`:lingua.errors.offertaNotDeleted}</p> </div>),
    onOk:() => {
      Modal.destroyAll();
      //update
      dispatch({
        type:actions.GET_PAGE_OFFERTE,
      });
      setPage(page + 1);
    },
  };


  //per messaggi di errore
  useEffect(() => {
    if (isInitialMount.current) {
      //blocco al primo giro
      isInitialMount.current = false;
      // console.log('isInitialMount -  - listaTutteOfferte >>> ', listaTutteOfferte  );
      // if(listaTutteOfferte.length === 0) setTutte(listaOfferte);
    } else {

      if(error) {
        config.content =(<div><p>{message?`${lingua.errors.generic} ${message}`:lingua.errors.offertaNotDeleted}</p> </div> ) ;
        modal.error(config);
      }
      console.log(` .-.-.-.-.-.-.-.-.-.-.-.  ERRROR .-.-.-.-.-.-.-.-.-.-.-. ${error}`);
    }
  }, [error]);

  useEffect(() => {
    // console.log(' ......................  E F F E C T -  -  ');
    dispatch({
      type:actions.GET_PAGE_OFFERTE,
      payload:{params:{page:page}},
    });
  }, [page, dispatch]);

  // per il primo giro
  useEffect(() => {

    // console.log(' ......................  P R I M O  -  -  ');

    // se c'è un filtro in corso sui clienti, applico il filtro
    if(idClienteFiltrato) {
      setLista([...listaOfferte.filter(elm => elm.cliente?.id === idClienteFiltrato)]);
    }else{
      setLista(listaOfferte);
    }

    if(error && responseType !== actions.GET_PAGE_OFFERTA_FAILURE && responseType !== actions.SAVE_OFFERTA_FAILURE) {
      modal.error(config);
    }
  }, [listaOfferte], error);

  function onRicercaSuCliente(idCliente) {
    // console.log('onRicercaSuRicetta - onRicercaSuRicetta - filtroRicetta >>> ', filtroRicetta);

    //toDO di test, questo richiama il saga
    // setPage(3);
    setClienteFiltrato(idCliente);

    // cambio della lista filtrata e ricarica della lista
    // listaOfferte.map(elm => console.log(elm.ricetteIncluse));
    idCliente? setLista([...listaOfferte.filter(elm => elm.cliente?.id === idCliente)]): setLista([...listaOfferte])
  }

  function getListaFiltrataRicetta(idRicetta) {

    // è presente?
    const offerteConRicetta = listaOfferte.filter(elm => {
      const res = elm.ricette.filter(ric => ric.id === idRicetta);
      return res.length > 0? res:null;
    });

    // tolgo le altre ricette per mostrare solo quella scelta
    const offerteConSoloRicetta = offerteConRicetta.map(elm => {
      //clono la ricetta per non sovrascrivere l'originale
      const elmTemp = {...elm};
      elmTemp.ricette = elmTemp.ricette.filter(ric => ric.id === idRicetta);
      return elmTemp;

    });

    setLista(offerteConSoloRicetta);
    return [];
  }

  function onRicercaSuRicetta(filtroRicetta) {
    // console.log('onRicercaSuRicetta - onRicercaSuRicetta - filtroRicetta >>> ', filtroRicetta);

    // cambio della lista filtrata e ricarica della lista
    // listaOfferte.map(elm => console.log(elm.ricetteIncluse));
    filtroRicetta? getListaFiltrataRicetta(filtroRicetta): setLista([...listaOfferte])
  }

  function onRicercaSuData(date, dateString) {
    // console.log('onRicercaSuData - onRicercaSuData - date, dateString >>> ', date, dateString  );
    // console.log('onRicercaSuData - onRicercaSuData - filtroRicetta >>> ', date, dateString);

    // dataInizio=2021-05-2&dataFine=2021-05-27

    // clienteId e pure ricetta
    if(idClienteFiltrato){
      dispatch({
        type:actions.GET_PAGE_OFFERTE_BY_DATE,
        period:{
          dataInizio:dateString[0],
          dataFine:dateString[1],
        },
        cliente_id:idClienteFiltrato,
      });

    }else{

      dispatch({
        type:actions.GET_PAGE_OFFERTE_BY_DATE,
        period:{
          dataInizio:dateString[0],
          dataFine:dateString[1],
        },
      });
    }
    // setPage(page + 1);
  }

  const  refreshLista = () => {

    // sta cosa del refresh va un attimo capita, al momento lo rilancio così ma NON è il metodo giusto:
    dispatch({
      type:actions.GET_PAGE_OFFERTE,
    });
    setPage(page + 1);
  }

  function onEliminaRecords(records) {
    const ids = records.map(elm => elm.id);
    // console.log('onEliminaRecords - onEliminaRecords - ids >>> ', ids  );

    let contami = -1;
    let listaOfferteAggiornata = [...listaOfferte];
    const lenIds = ids.length;
    while(++contami < lenIds){
      listaOfferteAggiornata = [...listaOfferteAggiornata.filter((elm, contami) => elm?.id !== ids[contami])];
    }
    setLista(listaOfferteAggiornata);

    dispatch({
      type:actions.DELETE_OFFERTE,
      payload:ids,
    });

    // refreshLista();
  }

  function onStampaRecord(record) {
    // visto che non stampa se chiamato da service, l'ho inserito come href dentro l'html
    // dispatch({
    //   type:actions.STAMPA_OFFERTA,
    //   payload:record.id,
    // });
    openNotification(record);

  }

  const openNotification = (quale) => {
    const args = {
      message: <div>{lingua.offerte.stampa.titolo} <strong>{quale?.codice}</strong></div>,
      description:
        lingua.offerte.stampa.testo,
      duration: 2,
      placement:'bottomRight',
    };
    notification.open(args);
  };
  function onEliminaRecord(record) {
    // console.log('onEliminaRecord - onEliminaRecord - record >>> ', record);
    // cancello intanto dalla lista l'elemento in attesa della risposta del service

    setLista([...listaOfferte.filter(elm => elm?.id !== record.id)]);

    dispatch({
      type:actions.DELETE_OFFERTA,
      payload:record.id,
    });

    // refreshLista();
  }

  function onCopiaRecord(record) {
    //toDO di test, questo richiama il saga
    //toDO metterci la chiamata giusta di copia
    setPage(page+1);
  }


  function getListaCliente(elm) {
    // lista.map(elm => {elm.label = elm.nome; return elm}) :[];
    if(elm && elm.nome) elm.label = elm.nome;

    // recupero = lista.map(elm => elm[tipo]);
    // // const unique = [...new Set(recupero)]; // non accetta i duplicati il set
    // const unique = recupero.filter((item, index) => recupero.indexOf(item) === index);
    // finale = unique.map(elm => ({text:elm, record:elm}));

    return elm;

  }

  function getClientiDaOfferte(listaOfferteCurr){
  //recupero il nome dei clienti dalla lista delle offerte caricate, senza duplicati
    if(listaOfferteCurr){

      let nuova = listaOfferteCurr.map(elm => getListaCliente(elm?.cliente));
      nuova = nuova.filter(elm => elm !== null && elm !== undefined);

      return nuova.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
    }
    return [];
  }

  function getRicetteDaOfferte(listaOfferte) {
    //recupero il nome delle ricette dalla lista delle offerte caricate, senza duplicati
    if(listaOfferte){

      let grRicette = listaOfferte.map(elm => elm.ricette);
      let plainRicette = [];
      const nGrRicette = grRicette.length;
      let i = -1;
      while(++i < nGrRicette){
        plainRicette = [...plainRicette, ...grRicette[i]];
      }

      // let nuova = listaOfferte.map(elm => getListaCliente(elm?.ricette));
      // nuova = nuova.filter(elm => elm !== null && elm !== undefined);

      return plainRicette.length > 0 ? plainRicette.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i) : [];
    }
    return [];
  }

  return (
    <div className='App'>
      {contextHolder}

      <header className='App-header'>
        <div className={'header-box'}>
          <img
            className={'header-logo'}
            alt={'Bertagni logo'} src={'/assets/bertagni.png'}/>
          <User/>
        </div>
      </header>
      <div className={'App-page'}>
        <OfferteHeader
          // ricette={listaNomiRicette}
          ricette={getRicetteDaOfferte(listaOfferte)}
          clienti={getClientiDaOfferte(listaOfferte)}
          // clienti={listaOfferte?listaOfferte.map(elm => elm.cliente):[]}
          // clienti={listaOfferte?(listaOfferte.map(elm => getListaCliente(elm?.cliente))).filter((v,i,a)=> a.findIndex(t=>(t.id === v.id))===i):[]}
          onSelectedCliente={onRicercaSuCliente}
          onSelectedRicetta={onRicercaSuRicetta}
          onSelectedDateRange={onRicercaSuData}/>
        {/*<Space>*/}
        {/*  <EliminaModal  />*/}
        {/*  <Button onClick={confirm}>Confirm</Button>*/}
        {/*</Space>*/}
        <ListaOfferte
          history={history}
          onCopiaRecord={onCopiaRecord}
          onStampaRecord={onStampaRecord}
          onEliminaRecord={onEliminaRecord}
          onEliminaRecords={onEliminaRecords}
          loader={loader} lista={listaAggiornata}/>
      </div>
      {/*<ul>*/}
      {/*  {listaOfferte && listaOfferte.data? listaOfferte.data.map(elm => <li key={elm.id+elm.cliente}>{elm.email} {elm.formato} {elm.cliente}</li>):null}*/}
      {/*</ul>*/}
    </div>
  );
}

export default OffertePage;

