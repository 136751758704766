import React from 'react';
import 'antd/dist/antd.css';
import './../../App.scss';
import FloatLabel from '../floatLabel/FloatLabel';
import PropTypes from 'prop-types';
import style from './FloatText.module.scss';


const FloatText = props => (props.newValue? <FloatLabel label={props.field} name='name' value={props.newValue}>
  <p className={`${style.testo} ${props.left? style.left:''}`}>{props.newValue}</p>
</FloatLabel>:<FloatLabel label={props.field} name='name' value={''}>
  <p className={`${style.testo} ${props.left? style.left:''}`}></p>
</FloatLabel>);
;


FloatText.propTypes = {
  field:PropTypes.string.isRequired,
  newValue:PropTypes.any,
  left:PropTypes.bool,

};
export default FloatText;
