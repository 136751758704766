import {LoadingOutlined} from '@ant-design/icons';
import React from 'react';
import PropTypes from 'prop-types';
// import {Link} from 'react-router-dom';


const GenericLoading = props => (<div className={`loadingOfferta  ${props.loader ? 'loadingOffertaHidden' : ''}`}><LoadingOutlined/> <span>Caricamento in corso...</span></div>)

GenericLoading.propTypes = {
  loader:PropTypes.bool,
};

export default GenericLoading;
