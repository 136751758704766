import React from 'react';
import style from './NewOfferta.module.scss';
import {Col, Input, Row} from 'antd';
import {UserIcon} from '../../helpers/Icons';
import PropTypes from 'prop-types';
import {lingua} from '../../Config/Lingua_it';
import FloatAutoComplete from '../floatAutoComplete/FloatAutoComplete';
import FloatSelect from '../floatSelect/FloatSelect';

const {TextArea} = Input;

class Cliente extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cliente_scelto:undefined,
      descrizione:undefined,
      listaNazioni:[{value:undefined, label:undefined}],
      offerta_obj:{},
    };
  }


  onChangeDescrizione = ({target:{value}}) => {
    if (value === undefined) value = '';
    this.setState((precstate, props) => ({
      descrizione:value,
    }));
    // const {offerta_obj} = this.props;
    const {offerta_obj} = this.state;
    offerta_obj.cliente_descrizione = value;
    //tolto l'invio via metodo per usare direttamente l'obj nelle props
    this.props.onUpdateCliente(offerta_obj);
  };


  onClienteSelected = (value) => {

    const {clienti} = this.props;
    const {offerta_obj} = this.state;
    let zonaGeo = '';
    let nazione = '';

    if (value === undefined) {
      //RESET
      value = '';
      offerta_obj.cliente_id =
        offerta_obj.cliente_zona_id =
          offerta_obj.cliente_nazione_id = null;

      offerta_obj.cliente = {
        id:null,
        nome:null,
      };
      offerta_obj.cliente_zona = {
        id:null,
        nome:null,
      };
      offerta_obj.cliente_nazione = {
        id:null,
        nome:null,
      };
    } else {
      //cerco il clinte tra quelli della lista dei services
      const cliente_obj = clienti.find(elm => `${elm.code} - ${elm.nome}` === value);

      // this.setState({nazione:cliente_scelto.nazione.nome});
      // const clinteScelto = clienti.find(elm =>  elm.nome === record);
      zonaGeo = cliente_obj && cliente_obj.zona? cliente_obj.zona.nome:null;
      nazione = cliente_obj && cliente_obj.nazione? cliente_obj.nazione.nome:null;

      //cliente trovato nella lista, automcpleto
      if (cliente_obj) {

        //tolto l'invio via metodo per usare direttamente l'obj nelle props
        // this.props.onUpdateCliente(
        //   {
        //     cliente_id:cliente_obj.id,
        //     cliente_zona_id:cliente_obj.zona.id,
        //     cliente_nazione_id:cliente_obj.nazione.id,
        //   });

        offerta_obj.cliente = {
          id:cliente_obj.id,
          nome:null,
        };
        offerta_obj.cliente_zona = {
          id:cliente_obj.zona?.id,
          nome:null,
        };
        offerta_obj.cliente_nazione = {
          id:cliente_obj.nazione?.id,
          nome:null,
        };

      }else{
        //toDo gesitre il caso di inserimento a mano - al momento tira un errore
        offerta_obj.cliente = {
          id:null,
          nome:value,
        };
        /*
        "cliente":{
          "id":null,
            "nome":"TestCliente"
        },
        "cliente_zona":{
          "id":null,
            "nome":"zonamia"
        },
        "cliente_nazione":{
          "id":null,
            "nome":"Sealand"
        },*/
      }
    }

    this.props.onUpdateCliente(offerta_obj);
    // console.log('Cliente - onClienteSelected - offerta_obj >>> ', offerta_obj  );
    this.setState({cliente_scelto:value, zonaGeografica_scelto:zonaGeo, nazione_scelto:nazione})
    //
    /*

        'cliente_zona_id':1,
        'cliente_nazione_id':2,
        'cliente_incoterms_id':1,
        'cliente_descrizione':'uno due tre',
     */
  }

  onZonaSelected = (value) => {
    // const listaNazioni = record? this.props.nazioni[record] :[{record:undefined, label:undefined}];

    //
    //tolto l'invio via metodo per usare direttamente l'obj nelle props
    // this.props.onUpdateCliente({
    //   zonaGeografica_scelto:record,
    // });
    const {zone} = this.props;
    const {offerta_obj} = this.state;
    const zona_value = zone.find(elm => elm.nome === value);
    if (zona_value) {
      //recuperato, esiste già
      offerta_obj.cliente_zona = {
        id:zona_value.id,
        nome:null,
      };
    } else {
      // non esiste tra i presenti
      // è reset?
      if (value === undefined) {
        //reset
        value = '';
      }
      //metto il record oppure reset
      offerta_obj.cliente_zona = {
        id:null,
        nome:value === ''?null:value,
      };
    }
    this.props.onUpdateCliente(offerta_obj);
    this.setState({zonaGeografica_scelto:value});
  }

  onNazioneSelected = (value) => {
    if (value === undefined) value = '';
    this.setState({nazione_scelto:value})
    //
    //tolto l'invio via metodo per usare direttamente l'obj nelle props
    // this.props.onUpdateCliente({
    //   nazione_scelto:record,
    // });
    const {nazioni} = this.props;
    const {offerta_obj} = this.state;
    const nazione_value = nazioni.find(elm => elm.nome === value);
    if (nazione_value) {
      //recuperato, esiste già
      offerta_obj.cliente_nazione = {
        id:nazione_value.id,
        nome:null,
      };
    } else {
      // non esiste tra i presenti
      // è reset?
      if (value === undefined) {
        //reset
        value = '';
      }
      //metto il record oppure reset
      offerta_obj.cliente_nazione = {
        id:null,
        nome:value === ''?null:value,
      };
    }
  }

  onIncotermsSelected = (value) => {
    if (value === undefined) value = '';
    this.setState({incoterm_scelto:value});
    //
    const {incoterms} = this.props;
    const {offerta_obj} = this.state;
    const incoterm_value = incoterms.find(elm => elm.id === value);
    if (incoterm_value) {
      //tolto l'invio via metodo per usare direttamente l'obj nelle props
      // this.props.onUpdateCliente({
      //   cliente_incoterms_id:incoterm_value.id,
      // });
      offerta_obj.cliente_incoterms_id = incoterm_value.id;
    } else {
      //tolto l'invio via metodo per usare direttamente l'obj nelle props
      // this.props.onUpdateCliente({
      //   cliente_incoterms_id:null,
      // });
      offerta_obj.cliente_incoterms_id = null;
    }
    this.props.onUpdateCliente(offerta_obj);
  }

  onSetValueFromRecord = (elm_scelto, daCheLista, campo, give_id) => {
    const {record} = this.props;

    if (record) {
      //esiste il record, autocompleto
      if (elm_scelto === undefined) {
        //elm_scelto esiste nel caso in cui ho cambiato la select e quindi qui non ci entro
        const obj_id = record[campo]?record[campo].id:null;
        const elm_trovato = daCheLista.find(elm => elm.id === obj_id);
        if (elm_trovato){

          if(!give_id)return elm_trovato.code? `${elm_trovato.code} - ${elm_trovato.nome}`:elm_trovato.nome;
          else return elm_trovato.id;
        }
      }
    }
    return elm_scelto;
  }

  render() {
    const {cliente_scelto, zonaGeografica_scelto, nazione_scelto, incoterm_scelto, descrizione} = this.state;
    const {zone, clienti, nazioni, incoterms, record} = this.props;

    //toDo sistemare questi in modo che siano più efficenti, per quanto potrebbero cambiare:
    const clienti_name = clienti? clienti.map(elm => ({value:`${elm.code} - ${elm.nome}`})):[];
    const clienti_zone = zone? zone.map(elm => ({value:elm.nome})):[];
    let clienti_incoterms = incoterms? incoterms.map(elm => {elm.label = elm.nome; return elm}) :[];
    // if(clienti_incoterms.length > 0) {
    //   clienti_incoterms.every(elm => elm.label = elm.nome);
    // }
    // const clienti_incoterms = incoterms? incoterms:[];
    const clienti_nazioni = nazioni? nazioni.map(elm => ({value:elm.nome})):[];

    let desc = descrizione;

    const cliente_value = this.onSetValueFromRecord(cliente_scelto, clienti, 'cliente');
    const naz_value = this.onSetValueFromRecord(nazione_scelto, nazioni, 'cliente_nazione');
    const zona_value = this.onSetValueFromRecord(zonaGeografica_scelto, zone, 'cliente_zona');
    const incoterm_value = this.onSetValueFromRecord(incoterm_scelto, clienti_incoterms, 'cliente_incoterm', true);

    if (record) {
      /*

              '':3,
              '':5,
              '':2,
              'cliente_descrizione': 'la descrizione',
              'packaging_fondo_id':2,
              'packaging_fondo_costo':10,
       */
      //esiste il record, autocompleto
      // if(cliente_scelto === undefined ){
      //   const cliente_trovato = clienti.find(elm =>  elm.id === record?.cliente_id );
      //   if(cliente_trovato) cli = cliente_trovato.nome;
      // }
      if (descrizione === undefined) {
        desc = record.cliente_descrizione;
      } else {
        desc = descrizione;
      }
    }

    return <div className={style.gruppo}>
      <div className={style.titleBox}><h3><UserIcon/> {lingua.offerta.cliente.nome}</h3></div>
      {this.props.clienti? <Row gutter={[{xs:6, sm:12, md:25}, {xs:6, sm:12, md:24}]}>
        <Col xs={24} sm={24} md={12}>

          <FloatAutoComplete
            newValue={cliente_value}
            field={lingua.offerta.cliente.denominazione}
            options={clienti_name}
            onSelected={this.onClienteSelected}/>

        </Col>
        <Col xs={24} sm={24} md={7}>
          <FloatAutoComplete
            newValue={zona_value}
            field={lingua.offerta.cliente.zonaGeografica} options={clienti_zone}
            onSelected={this.onZonaSelected}/>
        </Col>
        <Col xs={24} sm={24} md={5}>
          <FloatAutoComplete
            field={lingua.offerta.cliente.nazione}
            //disabled={!zonaGeograficaScelta}
            newValue={naz_value}
            options={clienti_nazioni}
            onSelected={this.onNazioneSelected}/>
        </Col>

        <Col xs={24} sm={24} md={12}>

          <FloatSelect
            newValue={incoterm_value}
            field={lingua.offerta.cliente.incoterms} options={clienti_incoterms}
            onSelected={this.onIncotermsSelected}/></Col>

        <Col span={24}>
          <TextArea
            className={style.descrizione}
            value={desc}
            onChange={this.onChangeDescrizione}
            placeholder={lingua.offerta.cliente.descrizione}
            autoSize={{minRows:5, maxRows:5}}
          /></Col>
      </Row>:null}
    </div>
  }
}

export default Cliente;

Cliente.propTypes = {
  // offerta_obj:PropTypes.object.isRequired,
  cliente:PropTypes.object,
  clienti:PropTypes.arrayOf(PropTypes.object).isRequired,
  zone:PropTypes.arrayOf(PropTypes.object).isRequired,
  nazioni:PropTypes.arrayOf(PropTypes.object).isRequired,
  // nazione:PropTypes.arrayOf(
  //   PropTypes.shape({
  //     record:PropTypes.string,
  //   })),
  incoterms:PropTypes.arrayOf(PropTypes.object).isRequired,
  record:PropTypes.object,
  onUpdateCliente:PropTypes.func,
  // hours:PropTypes.arrayOf(
  //   PropTypes.shape({
  //
  //     key:PropTypes.string,
  //     ingrediente:PropTypes.string,
  //     grammi:PropTypes.number,
  //     scarto:PropTypes.number,
  //     costo:PropTypes.number,
  //     // days:PropTypes.arrayOf(PropTypes.string),
  //     // record:PropTypes.string.isRequired,
  //   }),
  // ),
};
