import React from 'react';
import 'antd/dist/antd.css';
import './../../index.css';
import 'App.scss';

import {Button, Divider, Dropdown, Menu, Modal, Table} from 'antd';
import {MoreOutlined} from '@ant-design/icons';
// import Icon from '@ant-design/icons';
import NoOfferte from './../noOfferte/NoOfferte';
import {CestinoIcon, ForchetteIcon, StampanteIcon} from '../../helpers/Icons';
import PropTypes from 'prop-types';
import {lingua} from '../../Config/Lingua_it';
import DeleteRowsButton from './DeleteRowsButton';
import GenericLoading from '../genericLoading/GenericLoading';
import constants from '../../Config/constants';

//



class ListaOfferte extends React.Component {

  state = {
    bloccoDaEliminare:[],
    selectedRecord:{}, // lascio questa proprietà ma è useless
    selectedRowKeys:[], // Check here to configure the default column
  };
  // const [isModalVisible, setIsModalVisible] = useState(false);

  selected_record = {};

  onDeleteConfirm = (evt) => {
    this.props.onEliminaRecord(this.state.bloccoDaEliminare);

    // let {lista} = this.props;
    // const listaNew = [...lista.splice(0, lista.indexOf(this.selected_record)), ...lista.splice(lista.indexOf(this.selected_record)) ];
    // console.log('ListaOfferte - onDelete - listaNew >>> ', lista.indexOf(this.selected_record), listaNew  );
    // lista = [...listaNew];
    // this.setState({});
  }

  onMultiDeleteConfirm = (evt) => {
    this.props.onEliminaRecords(this.state.bloccoDaEliminare);
  }

  onStampa = () => {
    this.props.onStampaRecord(this.selected_record);
  }

  onElimina = () => {
    // icon: <ExclamationCircleOutlined />,
    // this.state.bloccoDaEliminare = [this.selected_record];
    this.setState({bloccoDaEliminare:this.selected_record})
    const title = `${lingua.offerte.eliminaRicette.titolo} ${this.selected_record.codice} ?`;
    let mod = Modal.confirm({
      title:(<h3>{title}</h3>),
      width:563,
      height:300,
      centered:true,
      content:(<div><p>{lingua.offerte.eliminaRicette.testo}</p></div>),
      cancelText:lingua.bts.annulla,
      okText:lingua.bts.elimina,
      onOk:()=>{ mod.destroy(); this.onDeleteConfirm();},
    });
    // this.setState({isModalVisible:true});
  }

  onCopia = () => {
    // icon: <ExclamationCircleOutlined />,
    this.props.onCopiaRecord(this.selected_record);
  }

  onEliminaRows = () =>{
    const listaScelte = [...this.props.lista.filter (elem => this.state.selectedRowKeys.includes(elem.key))];
    this.setState({bloccoDaEliminare: listaScelte} );

    const quante = listaScelte.length;
    const titolo1 = listaScelte[0].codice;//offerta;

    const title = quante>1? lingua.offerte.eliminaSelezionate.titolo.replace('#numero', quante): `${lingua.offerte.eliminaRicette.titolo} ${titolo1} ?`;
    let mod = Modal.confirm({
      title:(<h3>{title}</h3>),
      width:563,
      height:300,
      centered:true,
      content:(<div><p>{lingua.offerte.eliminaSelezionate.testo}</p></div>),
      cancelText:lingua.bts.annulla,
      okText:lingua.bts.elimina,
      onOk:()=>{ mod.destroy(); this.onMultiDeleteConfirm();},
    });
  }

  onRicetteIncluse = () => {
    // const lista = this.selected_record? [...this.selected_record.ricetteIncluse]:[]
    //return;
    // if(record ){
    //   console.log('ListaOfferte - onRicetteIncluse - record >>> ', record  );
    //   return
    // }
    // Modal.info({
    //   title:lingua.offerte.ricetteIncluse.titolo,
    //   wrapClassName:'classeMia',
    //   // icon: <ExclamationCircleOutlined />,
    //   content:`${lingua.offerte.ricetteIncluse.testo} ${lista}`,
    //   okText:lingua.bts.chiudi});

    // console.log('ListaOfferte - onRicetteIncluse - this.selected_record >>> ', this.selected_record  );

    Modal.info({
      title:(<h3>{lingua.offerte.ricetteIncluse.titolo}</h3>),
      width:563,
      height:300,
      icon:<ForchetteIcon />,
      okText:lingua.bts.chiudi,
      centered:true,
      content: (
        this.selected_record && this.selected_record.ricette.length > 0? (
          <div>
            <p>{lingua.offerte.ricetteIncluse.testo}</p>
            <ul>
              {this.selected_record.ricette.map((elm, index) => <li key={`• ${index} ${elm.nome} ${elm.descrizione}`}>{elm.code} {elm.nome}</li>)}
            </ul>
          </div>):(<div><p>{lingua.offerte.ricetteIncluse.nessunaRicetta}</p></div>)
      ),
      onOk() {},
    });
    // this.setState({isModalVisible:true});
  }

  dropmenu = (record) =>(
    <Menu>
      <Menu.Item onClick={this.onRicetteIncluse}>
        <p>
          <ForchetteIcon/>
          {lingua.offerte.dropdown[0]}
        </p>
      </Menu.Item>
      {/*todo SCOMMENTARE*/}
      {/*<Menu.Item  onClick={this.onCopia}>*/}
      {/*  <p>*/}
      {/*    <CopyIcon/>*/}
      {/*    {lingua.offerte.dropdown[1]}*/}
      {/*  </p>*/}
      {/*</Menu.Item>*/}
      <Menu.Item onClick={this.onElimina}>
        <p>
          <CestinoIcon/>
          {lingua.offerte.dropdown[2]}
        </p>
      </Menu.Item>
      <Menu.Item onClick={this.onStampa}>
        <p>
          <a className={'stampa'} href={`${constants.HOST_URL}export/${record.id}`}>
            <StampanteIcon/>
            {lingua.offerte.dropdown[3]}
          </a>
        </p>
      </Menu.Item>
    </Menu>
  );

  getListaRicette = () =>{
    const {lista} = this.props;

    const flatRicetteName = lista.map(r => r.ricette?.map((elm) => elm.nome)).flat();
    // per i filtri obj fatti di {text:testo, value:valore}
    return [...new Set(flatRicetteName)].map(elm => {
      return {text:elm, value:elm}
    });

  }
  getFiltroObj = (tipo, sottotipo) => {
    const {lista} = this.props;
    let finale, recupero;
    //lista dovrebbe sempre esistere, di fatto, ma tirava errori con url in env sbagliate:
    if(lista){
      if(sottotipo){
        const listapadre = lista?.map(elm => elm[tipo]);
        recupero = listapadre?.map( elm => elm != null ? elm[sottotipo]:'');

        const unique = recupero?.filter((item, index) => recupero.indexOf(item) === index);
        finale = unique?.map(elm => (elm?{text:elm, value:elm}:{text:lingua.offerte.campoVuoto, value:' '}));
        // finale = unique.map(elm => ({text:elm, record:elm}));

      }else{

        recupero = lista?.map(elm => elm[tipo]);
        // const unique = [...new Set(recupero)]; // non accetta i dupplicati il set
        const unique = recupero?.filter((item, index) => recupero.indexOf(item) === index);
        finale = unique.map(elm => ({text:elm, value:elm}));
      }
      if(finale && finale.length === 1 && finale[0].value === '') finale = [];
      return finale;

    }else{
      return [];
    }
  }

  getColumnsWithFilters = () => [
    {
      title:lingua.offerte.table.codice,
      dataIndex:'codice',
      fixed: 'left',
      // colSpan: 3,
      // render: renderContent,
      // render:(record) => <h3> {record}</h3>,
    },
    {
      title:lingua.offerte.table.cliente,
      dataIndex:['cliente','nome'],
      fixed: 'left',
      // colSpan: 3,
      // render: renderContent,
    },
    {
      title:lingua.offerte.table.formato,
      dataIndex:['formato', 'code'],
      filters: this.getFiltroObj('formato','code'),
      filterMultiple:false,
      // onFilter:(record, record) => record.formato.indexOf(record) === 0,
      // DALLE RICETTE
      onFilter:(value, record) => this.onGetFilteredCodeColumn(value, record.formato),
    },
    {
      title:lingua.offerte.table.pezziCartone,
      dataIndex:'formato_pezzi_cartone',
      filters: [...this.getFiltroObj('formato_pezzi_cartone')],
      filterMultiple:false,
      // è un numero:
      // DALL'OFFERTA ma per ora non inseribile
      onFilter:(value, record) => record.formato_pezzi_cartone === value,
    },
    {
      title:lingua.offerte.table.ricette,
      dataIndex:['ricette', 'code'],
      filterMultiple: false,
      filters: [...this.getListaRicette()],
      onFilter: (value, record) => {
        return record.ricette.filter(elm => elm.nome === value).length;
      },
      render: (value, record) =>
        <div className={'ricetteIncluse'}>
          {record.ricette.map((elm, index) => <div key={`• ${index} ${elm.nome} ${elm.descrizione}`} className={'costi'}>
            {elm.code} {elm.nome}
            <Divider orientation="right"> {elm?.prezzo_singolo_pack} €</Divider>
          </div>)}
        </div>,
    },
    {
      title:lingua.offerte.table.fondo,
      dataIndex:['packaging_fondo', 'label'],
      filters: [...this.getFiltroObj('packaging_fondo', 'label')],
      filterMultiple:false,
      onFilter:(value, record) => this.onGetFilteredColumn(value, record.packaging_fondo),
    },
    {
      title:lingua.offerte.table.top,
      dataIndex:['packaging_top', 'label'],
      filters: [...this.getFiltroObj('packaging_top', 'label')],
      filterMultiple:false,
      onFilter:(value, record) => this.onGetFilteredColumn(value, record.packaging_top),
    },
    {
      title:lingua.offerte.table.conf1,
      dataIndex:['packaging_confezionamento', 'label'],
      filters: [...this.getFiltroObj('packaging_confezionamento', 'label')],
      filterMultiple:false,
      onFilter:(value, record) => this.onGetFilteredColumn(value, record.packaging_confezionamento),
    },
    {
      title:lingua.offerte.table.confr2,
      dataIndex:['packaging_confezionamento2', 'label'],
      filters: [...this.getFiltroObj('packaging_confezionamento2', 'label')],
      filterMultiple:false,
      onFilter:(value, record) => this.onGetFilteredColumn(value, record.packaging_confezionamento2),
    },
    // {
    //   title:lingua.offerte.table.prezzo,
    //   dataIndex:['ricette', 'code'],
    //   render: (value, record) =>
    //     <div>
    //       {record.ricette.map((elm, index) => <p key={`• ${index} ${elm.nome} ${elm.code}`}>{elm.code}</p>)}
    //     </div>,
    // },
    // {
    //   title:'',
    //   dataIndex:'',
    //   key:'x',
    //   render:() => <a> <StampanteIcon/></a>,
    // },
    {
      title:'',
      dataIndex:'',
      fixed: 'right',
      // colSpan: 1,
      render:(value, record) => <Dropdown  overlay={this.dropmenu(record)} placement='bottomRight' trigger={['click']}>
        <Button className={'btn_tendina'}><MoreOutlined/></Button>
      </Dropdown>,
    },
  ];

  onGetFilteredColumn = (value, recordCampo, noLabel) =>{
    if(value === ' '){
      // prendo tutte le righe vuote
      if(recordCampo === null) return true;
    }else{
      if(recordCampo === null) return false;
    }
    // console.log('RESULT >>> ', recordCampo.label.indexOf(record)  === 0 );

    return noLabel? recordCampo.indexOf(value) === 0:recordCampo.label.indexOf(value) === 0 ;
  }

  onGetFilteredCodeColumn = (value, recordCampo) =>{
    if(value === ' '){
      // prendo tutte le righe vuote
      if(recordCampo === null) return true;
    }else{
      if(recordCampo === null) return false;
    }
    // console.log('RESULT >>> ', recordCampo.label.indexOf(record)  === 0 );

    return recordCampo.code.indexOf(value) === 0 ;
  }

  onSelectChange = selectedRowKeys => {
    this.setState({selectedRowKeys});
  };

  // handleOk = () => {
  //   this.setState({isModalVisible:false});
  // };
  //
  // handleCancel = () => {
  //   this.setState({isModalVisible:false});
  // };

  render() {
    const {selectedRowKeys} = this.state;
    const columns_table = this.getColumnsWithFilters();
    const {lista} = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange:this.onSelectChange,
      selections:[
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        // Table.SELECTION_NONE,
        {
          key:Table.SELECTION_NONE,
          text:lingua.generic.deseleziona,
          onSelect:changableRowKeys => {
            let newSelectedRowKeys = [];
            // newSelectedRowKeys = changableRowKeys.filter((key, index) => return false; );
            this.setState({selectedRowKeys:newSelectedRowKeys});
            //aggiunto per richiamare il metodo e quindi nascondere il cestino
            // this.onSelectChange([]);
          },
        },
      ],
    };
    if(lista) lista.map(elm => elm.key = elm.id);

    //il loading funziona
    return lista === undefined? <GenericLoading />: (lista.length > 0? <>
      {/*<Modal title='Basic Modal' visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>*/}

      {/*<Modal*/}
      {/*  closable={false}*/}
      {/*  title={`${lingua.offerte.eliminaRicette.titolo} ${this.selected_record.codice}`}*/}
      {/*  visible={isModalVisible}*/}
      {/*  onOk={this.handleOk}*/}
      {/*  onCancel={this.handleCancel}*/}
      {/*  footer={[<Button key='back' onClick={this.handleCancel}>{lingua.bts.annulla}</Button>,<Button key='submit' type='primary' loading={true} onClick={this.handleOk}>{lingua.bts.elimina}</Button>]}>*/}
      {/*  <p>{lingua.offerte.eliminaRicette.testo}</p>*/}
      {/*</Modal>*/}

      {selectedRowKeys.length>0? <DeleteRowsButton
        onDeleteSelected={this.onEliminaRows}
        rows={selectedRowKeys} />:null}

      <Table
        loading={this.props.loader}
        rowClassName={(record, index) => (index % 2 === 0? 'table-row-dark':'table-row-light')}

        pagination={{position:['bottomCenter']}}
        rowSelection={rowSelection} columns={columns_table} dataSource={lista}
        scroll={{ x: 'max-content'}}
        onRow={(record, rowIndex) => ({
          onClick:event => {
            const pz = event.target;


            this.setState({selectedRecord:record});
            this.selected_record = record;
            // controllo la classe per non far partire la modifica se clicco su il dropmenu
            if(pz.classList.contains('ant-table-cell')){
              // console.log('ListaOfferte - onClick - record >>> ', record  );

              //ora inserite nel mock

              // const offerta_fake = {
              //   'nome':`${this.selected_record.offerta} /21`,
              //   'cliente_id':Math.floor(Math.random()*3)+1,
              //   'cliente_zona_id':3,
              //   'cliente_nazione_id':1,
              //   'cliente_incoterms_id':2,
              //   'cliente_descrizione': 'la descrizione',
              //   'packaging_fondo_id':4,
              //   'packaging_fondo_quantita_id':2,
              //   'packaging_fondo_costo':10,
              // };

              // this.props.history.push('/offerta','asdsadsa');
              this.props.history.push({
                pathname: '/offerta',
                record: this.selected_record,
              });


            }

          }, // click row

        })}
      /> </>: <NoOfferte
            loading={this.props.loader}/>
    )
  }
}

ListaOfferte.propTypes = {
  componentName:PropTypes.string,
  loader:PropTypes.bool,
  roba:PropTypes.arrayOf(PropTypes.string),
  onCopiaRecord:PropTypes.func.isRequired,
  onStampaRecord:PropTypes.func.isRequired,
  onEliminaRecord:PropTypes.func.isRequired,
  onEliminaRecords:PropTypes.func.isRequired,
  lista:PropTypes.arrayOf(
    PropTypes.shape({
      key:PropTypes.number,
      ricetteIncluse:PropTypes.arrayOf(PropTypes.string),
      codice:PropTypes.string,
      cliente:PropTypes.object,
      formato:PropTypes.object,
      pezzicartone:PropTypes.object,
      packaging_fondo:PropTypes.object,
      top:PropTypes.string,
      conf1:PropTypes.string,
      confr2:PropTypes.string,
      // days:PropTypes.arrayOf(PropTypes.string),
      // record:PropTypes.string.isRequired,
    }),
  ),
};
/**
 *
 key:i,
 ricetteIncluse:[
 ...ricette.slice(0, Math.random() * (ricette.length - 1)),
 ],
 conf1:'Neutro',
 confr2:'Etichetta retro',

 */
export default ListaOfferte;

