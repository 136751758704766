import React, {useState} from 'react';
import style from './NewOfferta.module.scss';
import {Col, Row} from 'antd';
import PropTypes from 'prop-types';
import {lingua} from '../../Config/Lingua_it';
import {AppstoreOutlined} from '@ant-design/icons';
import FloatSelectCode from '../floatSelectCode/FloatSelectCode';
import FloatNumber from '../floatNumber/FloatNumber';

const BloccoFormato = props => {

  const [formatoScelto, setFormato] = useState(null);
  const [pezzicartoneScelti, setPezziCartone] = useState(null);


  const onSetValueFromRecord = (elm_scelto, campo) => {
    const {record} = props;
    if (record) {
      //esiste il record, autocompleto
      // null PRIMO giro, se cancelli diventa undefined
      if (elm_scelto === null) {
        //elm_scelto esiste nel caso ho cambiato la select e quindi qui non ci entro
        const obj_id = record[campo]?record[campo].id:null;
        if(obj_id ){
          // se è null qui parte il render a manetta quindi va messo un controllo
          setFormato(obj_id);
        }
        return obj_id;
        // const elm_trovato = props[daCheLista].find(elm => elm.id === obj_id);
        // if (elm_trovato){
        //
        //   // if(!give_id)return elm_trovato.code? `${elm_trovato.code} - ${elm_trovato.nome}`:elm_trovato.nome;
        //   // else
        //   return elm_trovato.id;
        // }
      }
    }
    return elm_scelto;
  }

  const onSetPezziFromRecord = (pezzi, daCheLista, campo, give_id) => {
    const {record} = props;

    if (record) {
      //esiste il record, autocompleto
      // unde
      if (pezzi === null) {
        return record.formato_pezzi_cartone;
      }
    }
    return pezzi;
  }

  const onFormatoSelected = (value) => {
    // this.setState({formatoScelto:record});
    setFormato(value);
    if(value === undefined) {
      // forzo fondi e top a un array vuoto
      props.onUpdateFormato({fondi:[], top:[]});
      return;
    }
    props.onUpdateFormato(props.formato.find(elm => elm.id === value));

  }

  const onPezziCartoneSelected = (value) => {
    // this.setState({pezzicartoneScelti:record});
    if(value === null) value = undefined;
    setPezziCartone(value);
    // if(value === undefined) {
    //   return;
    // }
    props.onUpdatePezziCartone({formato_pezzi_cartone:value});
  }

  // const {formatoScelto, pezzicartoneScelti} = this.state;
  const formato_value = onSetValueFromRecord(formatoScelto, 'formato');
  const pezzi_value = onSetPezziFromRecord(pezzicartoneScelti, 'formato_pezzi_cartone');

  return <div className={style.gruppoFormato}>
    {/*<h2> totale: {totale}</h2>*/}
    <div className={style.titleBox}>
      <h3><AppstoreOutlined /> {props.nome}</h3>
      {/*<h3>{props.icona} {props.nome}</h3>*/}
    </div>

    {props? <Row gutter={[{xs:6, sm:12, md:25}, {xs:6, sm:12, md:24}]}>
      <Col xs={24} sm={24} md={12}>
        <FloatSelectCode
          field={lingua.offerta.ricette.formato}
          newValue={formato_value}
          options={props.formato}
          onSelected={onFormatoSelected}/>
      </Col>
      {/*<Col xs={24} sm={24} md={6}>*/}
      {/*  <FloatAutoComplete*/}
      {/*    field={lingua.offerta.ricette.pezziCartone}*/}
      {/*    newValue={pezzicartoneScelti}*/}
      {/*    options={[]}*/}
      {/*    onSelected={onPezziCartoneSelected}/>*/}
      {/*</Col>*/}
      <Col xs={24} sm={24} md={6}>
        <FloatNumber
          disabled={!formato_value}
          field={lingua.offerta.ricette.pezziCartone}
          newValue={pezzi_value}
          onSelected={onPezziCartoneSelected}/>
      </Col>
    </Row>:null}
  </div>;

}

BloccoFormato.propTypes = {
  nome:PropTypes.string.isRequired,
  icona:PropTypes.element,
  listaFormato:PropTypes.arrayOf(PropTypes.object),
  listaPezziCartone:PropTypes.arrayOf(PropTypes.object),
  record:PropTypes.object,
  onUpdateFormato:PropTypes.func.isRequired,
  onUpdatePezziCartone:PropTypes.func.isRequired,
};


export default BloccoFormato;
