import axios from 'axios';
import constants from 'Config/constants';

const axiosClient = axios.create();

axiosClient.defaults.baseURL = constants.HOST_URL; // endpoint di Anto
console.log('defaults -  - axiosClient.defaults.baseURL >>> ', axiosClient.defaults.baseURL  );
console.log('defaults -  - REACT_APP_LOCAL_LOGIN >>> ',  process.env.REACT_APP_LOCAL_LOGIN  );
console.log('defaults -  - REACT_APP_SKIP_LOGIN >>> ', process.env.REACT_APP_SKIP_LOGIN  );
console.log('defaults -  - REACT_APP_ENV >>> ', process.env.REACT_APP_ENV  );
//esempio:
// axiosClient.defaults.baseURL = 'https://jsonplaceholder.typicode.com/';

axiosClient.defaults.headers = constants.headers;

// To share cookies to cross site domain, change to true.
axiosClient.defaults.withCredentials = false;

export function getRequest(URL, payload) {
  // console.log('*******************            getRequest - getRequest - payload >>> ', payload  );
  // params = {params:params}
  // console.log('getRequest - getRequest - payload >>> ', payload  );
  return axiosClient.get(`/${URL}`, payload).then(response => response);
}

export function postRequest(URL, payload) {
  return axiosClient.post(`/${URL}`, payload).then(response => response);
}

export function patchRequest(URL, payload) {
  return axiosClient.patch(`/${URL}`, payload).then(response => response);
}

export function deleteRequest(URL) {
  // params = {params:params}
  return axiosClient.delete(`/${URL}`).then(response => response);
}

// aggiuntivo per la modifica
export function putRequest(URL, payload) {
  return axiosClient.put(`/${URL}`, payload).then(response => response);
}
