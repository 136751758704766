import React from 'react';
import style from './NoIngredienti.module.scss';
import PropTypes from 'prop-types';
import {lingua} from '../../../Config/Lingua_it';

const NoIngredienti =  props => (props.loader ? <p></p>: <p className={style.noIngredientiText}>{lingua.offerta.ricette.nessunIngrediente}</p>);


NoIngredienti.propTypes = {
  loader:PropTypes.bool,

};

export default NoIngredienti;
