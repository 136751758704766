const actions = {
  LOGIN:'LOGIN',
  LOGIN_SUCCESS:'LOGIN_SUCCESS',
  LOGIN_FAILURE:'LOGIN_FAILURE',
  CASCONNECT:'CASCONNECT',
  CASCONNECT_RESPONSE:'CASCONNECT_RESPONSE',
  CASCONNECT_FAILURE:'CASCONNECT_FAILURE',

  LOGOUT:'LOGOUT',
  LOGOUT_SUCCESS:'LOGOUT_SUCCESS',
  LOGOUT_FAILURE:'LOGOUT_FAILURE',

  GET_PAGE_OFFERTE:'GET_PAGE_OFFERTE',
  GET_PAGE_OFFERTE_BY_DATE:'GET_PAGE_OFFERTE_BY_DATE',
  GET_PAGE_OFFERTE_SUCCESS:'GET_PAGE_OFFERTE_SUCCESS',
  GET_PAGE_OFFERTE_FAILURE:'GET_PAGE_OFFERTE_FAILURE',
  GET_OFFERTE:'GET_OFFERTE',
  // GET_NOMI_RICETTE:'GET_NOMI_RICETTE',

  GET_PAGE_OFFERTA:'GET_PAGE_OFFERTA',
  GET_PAGE_OFFERTA_SUCCESS:'GET_PAGE_OFFERTA_SUCCESS',
  GET_PAGE_OFFERTA_FAILURE:'GET_PAGE_OFFERTA_FAILURE',
  GET_ALL_RICETTE:'GET_ALL_RICETTE',
  GET_ALL_COSTI:'GET_ALL_COSTI',


  GET_OFFERTA:'GET_OFFERTA',
  GET_INGREDIENTI_RICETTA:'GET_INGREDIENTI_RICETTA',
  GET_INGREDIENTI_RICETTA_START:'GET_INGREDIENTI_RICETTA_START',
  GET_INGREDIENTI_RICETTA_SUCCESS:'GET_INGREDIENTI_RICETTA_SUCCESS',
  GET_INGREDIENTI_RICETTA_FAILURE:'GET_INGREDIENTI_RICETTA_FAILURE',

  SAVE_OFFERTA:'SAVE_OFFERTA',
  SAVE_OFFERTA_SUCCESS:'SAVE_OFFERTA_SUCCESS',
  SAVE_OFFERTA_FAILURE:'SAVE_OFFERTA_FAILURE',
  SAVE_OFFERTA_END:'SAVE_OFFERTA_END',
  MODIFICA_OFFERTA:'MODIFICA_OFFERTA',

  UPDATE_OFFERTA:'UPDATE_OFFERTA',
  UPDATE_GET_ALL_COSTI:'UPDATE_GET_ALL_COSTI',
  UPDATE_OFFERTA_CAMPI:'UPDATE_OFFERTA_CAMPI',
  UPDATE_OFFERTA_SUCCESS:'UPDATE_OFFERTA_SUCCESS',
  UPDATE_OFFERTA_FAILURE:'UPDATE_OFFERTA_FAILURE',

  // STAMPA_OFFERTA:'STAMPA_OFFERTA',
  // STAMPA_OFFERTA_SUCCESS:'STAMPA_OFFERTA_SUCCESS',
  // STAMPA_OFFERTA_FAILURE:'STAMPA_OFFERTA_FAILURE',

  DELETE_OFFERTA:'DELETE_OFFERTA',
  DELETE_OFFERTE:'DELETE_OFFERTE',
  DELETE_OFFERTA_SUCCESS:'DELETE_OFFERTA_SUCCESS',
  DELETE_OFFERTA_FAILURE:'DELETE_OFFERTA_FAILURE',
  DELETE_OFFERTA_END:'DELETE_OFFERTA_END',

  RESET_RECORD:'RESET_RECORD',
  RESET_ERROR:'RESET_ERROR',
}

export default actions;
