export const ParseToDecimal4 = (x) => {

  if (!x || x === undefined || isNaN(x)) {
    x = 0;
    return x;
  }

  if(Number.isInteger(x)) return x;

  x = Number.parseFloat(x).toFixed(4);
  let ultimo = `${x}`;
  //taglio via le ultime cifre con 0
  x = parseFloat(ultimo);

  //uselsess con la conversione in string che mantiene se presenti tutte e 4 le cifre decimali
  // while(ultimo.slice(ultimo.length-1) ===  0) {
  //   --leng;
  //   x = Number.parseFloat(x).toFixed(leng);
  //   console.log('ParseToDecimal4 - ParseToDecimal4 - x >>> ', x  );  //
  // }
  return x;
}

export const toFixedX = (x, getfloat = false, fd = 2) => {
  if (!x || x === undefined || isNaN(x)) {
    x = 0;
  }
  x = Number.parseFloat(x).toFixed(fd);
  return getfloat? Number.parseFloat(x) : x;
}

