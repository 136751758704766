import Icon from '@ant-design/icons';

const ForchetteSvg = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
    <g fill='none' fillRule='evenodd' opacity='.5'>
      <g>
        <g>
          <g>
            <g>
              <path d='M0 0L24 0 24 24 0 24z' transform='translate(-1255 -554) translate(24 350) translate(1217 191) translate(14 13)'/>
              <path fill='#000' fillRule='nonzero' d='M16 6v8h3v8h2V2c-2.76 0-5 2.24-5 4zm-5 3H9V2H7v7H5V2H3v7c0 2.21 1.79 4 4 4v9h2v-9c2.21 0 4-1.79 4-4V2h-2v7z' transform='translate(-1255 -554) translate(24 350) translate(1217 191) translate(14 13)'/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>

);
export const ForchetteIcon = props => <Icon component={ForchetteSvg} {...props} />;


const StampanteSvg = () => (

  <svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24'>
    <g fill='none' fillRule='evenodd' opacity='.5'>
      <g>
        <g>
          <g>
            <g transform='translate(-1340 -508) translate(24 350) translate(0 142) translate(1316.946 16)'>
              <path d='M0 0L24.017 0 24.017 24 0 24z'/>
              <path fill='#000' fillRule='nonzero' d='M19.014 8h-1.001V3H6.004v5h-1C3.342 8 2 9.34 2 11v6h4.003v4h12.009v-4h4.003v-6c0-1.66-1.341-3-3.002-3zM8.006 5h8.006v3H8.006V5zm8.006 14H8.006v-4h8.006v4zm2-4v-2H6.005v2H4.003v-4c0-.55.45-1 1-1h14.01c.551 0 1.001.45 1.001 1v4h-2.001z'/>
              <ellipse cx='18.013' cy='11.5' fill='#000' fillRule='nonzero' rx='1.001' ry='1'/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>

);
export const StampanteIcon = props => <Icon component={StampanteSvg} {...props} />;


const CalendarioSvg = () => (


  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
    <g fill='none' fillRule='evenodd' opacity='.5'>
      <g>
        <g>
          <g>
            <path d='M0 0L24 0 24 24 0 24z' transform='translate(-957 -245) translate(944 229) translate(13 16)'/>
            <path fill='#000' fillRule='nonzero' d='M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2zm-7 5h5v5h-5v-5z' transform='translate(-957 -245) translate(944 229) translate(13 16)'/>
          </g>
        </g>
      </g>
    </g>
  </svg>


);
export const CalendarioIcon = props => <Icon component={CalendarioSvg} {...props} />;

const OffertaSvg = () => (

  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
    <g fill='none' fillRule='evenodd' opacity='.5'>
      <g>
        <g>
          <g>
            <path d='M0 0L24 0 24 24 0 24z' transform='translate(-708 -435) translate(534 435) translate(174)'/>
            <g fill='#000' fillRule='nonzero'>
              <path d='M16.5 1.5L15 0l-1.5 1.5L12 0l-1.5 1.5L9 0 7.5 1.5 6 0 4.5 1.5 3 0v14H0v3c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3V0l-1.5 1.5zM12 18H3c-.55 0-1-.45-1-1v-1h10v2zm4-1c0 .55-.45 1-1 1s-1-.45-1-1v-3H5V3h11v14z' transform='translate(-708 -435) translate(534 435) translate(174) translate(3 2)'/>
              <path d='M6 5H12V7H6zM13 5H15V7H13zM6 8H12V10H6zM13 8H15V10H13z' transform='translate(-708 -435) translate(534 435) translate(174) translate(3 2)'/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>

);
export const OffertaIcon = props => <Icon component={OffertaSvg} {...props} />;

const LogoutSvg = () => (

  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
    <g fill='none' fillRule='evenodd' opacity='.5'>
      <g>
        <g>
          <g>
            <path d='M0 0L24 0 24 24 0 24z' transform='translate(-1212 -123) translate(1197 112) translate(15 11)'/>
            <path fill='#000' fillRule='nonzero' d='M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z' transform='translate(-1212 -123) translate(1197 112) translate(15 11)'/>
          </g>
        </g>
      </g>
    </g>
  </svg>

);

export const LogoutIcon = props => <Icon component={LogoutSvg} {...props} />

const UserSvg = () => (

  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
    <g fill='none' fillRule='evenodd' opacity='.5'>
      <g>
        <g>
          <g>
            <path d='M0 0L24 0 24 24 0 24z' transform='translate(-51 -218) translate(26 198) translate(25 20)'/>
            <path fill='#000' fillRule='nonzero' d='M12 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0 10c2.7 0 5.8 1.29 6 2H6c.23-.72 3.31-2 6-2m0-12C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z' transform='translate(-51 -218) translate(26 198) translate(25 20)'/>
          </g>
        </g>
      </g>
    </g>
  </svg>
);


export const UserIcon = props => <Icon component={UserSvg} {...props} />

const PackagingSvg = () => (

  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
    <g fill='none' fillRule='evenodd' opacity='.5'>
      <g>
        <g>
          <g transform='translate(-51 -1324) translate(26 1304) translate(25 20)'>
            <path d='M0 0L24 0 24 24 0 24z'/>
            <path fill='#000' fillRule='nonzero' d='M2.53 19.65l1.34.56v-9.03l-2.43 5.86c-.41 1.02.08 2.19 1.09 2.61zm19.5-3.7L17.07 3.98c-.31-.75-1.04-1.21-1.81-1.23-.26 0-.53.04-.79.15L7.1 5.95c-.75.31-1.21 1.03-1.23 1.8-.01.27.04.54.15.8l4.96 11.97c.31.76 1.05 1.22 1.83 1.23.26 0 .52-.05.77-.15l7.36-3.05c1.02-.42 1.51-1.59 1.09-2.6zm-9.2 3.8L7.87 7.79l7.35-3.04h.01l4.95 11.95-7.35 3.05z'/>
            <circle cx='11' cy='9' r='1' fill='#000' fillRule='nonzero'/>
            <path fill='#000' fillRule='nonzero' d='M5.88 19.75c0 1.1.9 2 2 2h1.45l-3.45-8.34v6.34z'/>
          </g>
        </g>
      </g>
    </g>
  </svg>

);

export const PackagingIcon = props => <Icon component={PackagingSvg} {...props} />


const CartoneSvg = () => (

  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
    <g fill='none' fillRule='evenodd' opacity='.5'>
      <g>
        <g>
          <g>
            <path d='M0 0L24 0 24 24 0 24z' transform='translate(-51 -1680) translate(26 1660) translate(25 20)'/>
            <path fill='#000' fillRule='nonzero' d='M20.54 5.23l-1.39-1.68C18.88 3.21 18.47 3 18 3H6c-.47 0-.88.21-1.16.55L3.46 5.23C3.17 5.57 3 6.02 3 6.5V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6.5c0-.48-.17-.93-.46-1.27zM6.24 5h11.52l.81.97H5.44l.8-.97zM5 19V8h14v11H5z' transform='translate(-51 -1680) translate(26 1660) translate(25 20)'/>
          </g>
        </g>
      </g>
    </g>
  </svg>

);
export const CartoneIcon = props => <Icon component={CartoneSvg} {...props} />;


const TotaleSvg = () => (

  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
    <g fill='none' fillRule='evenodd' opacity='.5'>
      <g>
        <g>
          <g>
            <path d='M0 0L24 0 24 24 0 24z' transform='translate(-51 -2058) translate(26 2038) translate(25 20)'/>
            <path fill='#000' fillRule='nonzero' d='M15 18.5c-2.51 0-4.68-1.42-5.76-3.5H15v-2H8.58c-.05-.33-.08-.66-.08-1 0-.34.03-.67.08-1H15V9H9.24C10.32 6.92 12.5 5.5 15 5.5c1.61 0 3.09.59 4.23 1.57L21 5.3C19.41 3.87 17.3 3 15 3c-3.92 0-7.24 2.51-8.48 6H3v2h3.06c-.04.33-.06.66-.06 1 0 .34.02.67.06 1H3v2h3.52c1.24 3.49 4.56 6 8.48 6 2.31 0 4.41-.87 6-2.3l-1.78-1.77c-1.13.98-2.6 1.57-4.22 1.57z' transform='translate(-51 -2058) translate(26 2038) translate(25 20)'/>
          </g>
        </g>
      </g>
    </g>
  </svg>

);

export const TotaleIcon = props => <Icon component={TotaleSvg} {...props} />;


const CestinoSvg = () => (

  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24">
    <g fill="none" fillRule="evenodd" opacity='.5'>
      <g fill="#000" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path d="M1.286 21.333C1.286 22.8 2.443 24 3.857 24h10.286c1.414 0 2.571-1.2 2.571-2.667v-16H1.286v16zM3.857 8h10.286v13.333H3.857V8zM13.5 1.333L12.214 0H5.786L4.5 1.333H0V4h18V1.333h-4.5z" transform="translate(-1234 -633) translate(22 384) translate(1193 156) translate(19 93)"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>

);

export const CestinoIcon = props => <Icon component={CestinoSvg} {...props} />;


const CopySvg = () =>(

  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd" opacity='.5'>
      <g>
        <g>
          <g>
            <g>
              <path d="M0 0L24 0 24 24 0 24z" transform="translate(-1231 -591) translate(22 384) translate(1193 156) translate(16 51)"/>
              <path fill="#000" fillRule="nonzero" d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" transform="translate(-1231 -591) translate(22 384) translate(1193 156) translate(16 51)"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const CopyIcon = props => <Icon component={CopySvg} {...props} />;

const BackSvg = () => (

  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd" opacity='.5'>
      <g>
        <g>
          <path d="M0 0L24 0 24 24 0 24z" transform="translate(-26 -134) translate(26 134)"/>
          <path fill="#000" fillRule="nonzero" d="M21 11L6.83 11 10.41 7.41 9 6 3 12 9 18 10.41 16.59 6.83 13 21 13z" transform="translate(-26 -134) translate(26 134)"/>
        </g>
      </g>
    </g>
  </svg>

);

export const BackIcon = props => <Icon component={BackSvg} {...props} />;


const EuroSvg = () => (

  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd" opacity='.5'>
      <g>
        <g>
          <g>
            <g>
              <path d="M0 0L24 0 24 24 0 24z" transform="translate(-1200 -1141) translate(26 898) translate(1163 233) translate(11 10)"/>
              <path fill="#000" fillRule="nonzero" d="M15 18.5c-2.51 0-4.68-1.42-5.76-3.5H15v-2H8.58c-.05-.33-.08-.66-.08-1 0-.34.03-.67.08-1H15V9H9.24C10.32 6.92 12.5 5.5 15 5.5c1.61 0 3.09.59 4.23 1.57L21 5.3C19.41 3.87 17.3 3 15 3c-3.92 0-7.24 2.51-8.48 6H3v2h3.06c-.04.33-.06.66-.06 1 0 .34.02.67.06 1H3v2h3.52c1.24 3.49 4.56 6 8.48 6 2.31 0 4.41-.87 6-2.3l-1.78-1.77c-1.13.98-2.6 1.57-4.22 1.57z" transform="translate(-1200 -1141) translate(26 898) translate(1163 233) translate(11 10)"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>

);
export const EuroIcon = props => <Icon component={EuroSvg} {...props} />;


const QuantitaSvg = () => (
    <svg width="1em" height="1em" aria-hidden="true" data-icon="appstore" focusable="false" version="1.1" viewBox="64 64 896 896" xmlns="http://www.w3.org/2000/svg">
      <path opacity='.5' d="m708.59 66.596h-369.13c-10.685 0-19.428 8.7425-19.428 19.428v369.13c0 10.685 8.7425 19.428 19.428 19.428h369.13c10.685 0 19.428-8.7425 19.428-19.428v-369.13c0-10.685-8.7425-19.428-19.428-19.428zm-63.14 325.41h-242.85v-242.85h242.85zm-184.56 160.28h-369.13c-10.685 0-19.428 8.7425-19.428 19.428v369.13c0 10.685 8.7425 19.428 19.428 19.428h369.13c10.685 0 19.428-8.7425 19.428-19.428v-369.13c0-10.685-8.7425-19.428-19.428-19.428zm-63.14 325.41h-242.85v-242.85h242.85zm548.83-325.41h-369.13c-10.685 0-19.428 8.7425-19.428 19.428v369.13c0 10.685 8.7425 19.428 19.428 19.428h369.13c10.685 0 19.428-8.7425 19.428-19.428v-369.13c0-10.685-8.7425-19.428-19.428-19.428zm-63.14 325.41h-242.85v-242.85h242.85z"/>
    </svg>



);
export const QuantitaIcon = props => <Icon component={QuantitaSvg} {...props} />;
