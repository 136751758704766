
const costiCliente = {
  denominazione :[
    {value:'Coop'},
    {value:'Cadoro'},
    {value:'Famila'},
    {value:'Lidl'},
  ],
  zonaGeografica: [
    {value:'Europa occidentale'},
    {value:'America'},
    {value:'Asia'},
    {value:'Europa orientale'},
  ],
  incoterms : [
    {value:'EXW 123 '},
    {value:'FCA 456'},
    {value:'FAS 789'},
    {value:'FOB 999'},
  ],
  nazioni:{
    'Europa occidentale':[
      {value:'Italia'},
      {value:'Germania'},
      {value:'Autria'},
      {value:'Francia'},
      {value:'Spagna'},
    ],
    'America':[
      {value:'California'},
      {value:'Texas'},
      {value:'Florida'},
      {value:'Arizona'},
      {value:'Alabama'},
      {value:'New Messico'},
    ],
    'Asia':[
      {value:'Cina'},
      {value:'Giappone'},
      {value:'Korea Sud'},
      {value:'India'},
    ],
    'Europa orientale':[
      {value:'Russia'},
      {value:'Albania'},
      {value:'Romania'},
      {value:'Slovenia'},
      {value:'Ucraina'},
    ],
  },
}

const cartone = [
  {
    campo1:'cartoneCartone',
    campo2:'quantitaMin',
    tipi:[
      'cartone1',
      'cartone2',
      'cartone3',
    ],
    quantita:[
      '1000 pz',
      '2000 pz',
      '3000 pz',
    ],
  },
  {
    campo1:'cartoneCoperchio',
    campo2:'quantitaMin',
    tipi:[
      'coperchio1',
      'coperchio2',
      'coperchio3',
      'coperchio4',
    ],
    quantita:[
      '500 pz',
      '1000 pz',
      '1500 pz',
      '2500 pz',
    ],
  },
  {
    campo1:'cartoneEtichetta',
    tipi:[
      'ET001',
      'ET002',
      'ET003',
    ],
  },

];
const packaging = [
  {
    campo1:'packagingFondo',
    tipi:[
      'fondo1',
      'fondo2',
      'fondo3',
    ],
  },
  {
    campo1:'packagingTop',
    tipi:[
      'top1',
      'top2',
      'top3',
    ],
    campo2:'quantitaMin',
    quantita:[
      '1000 pz',
      '2000 pz',
      '3000 pz',
      '4000 pz',
      '5000 pz',
      '6000 pz',
    ],
  },
  {
    campo1:'packagingConfezionamento1',
    campo2:'quantitaMin',
    tipi:[
      'confezionamento1',
      'confezionamento2',
      'confezionamento3',
    ],
    quantita:[
      '1000 pz',
      '2000 pz',
      '3000 pz',
    ],
  },
  {
    campo1:'packagingConfezionamento2',
    campo2:'quantitaMin',
    tipi:[
      'confezionamento21',
      'confezionamento22',
      'confezionamento23',
      'confezionamento24',
    ],
    quantita:[
      '1000 pz',
      '2000 pz',
      '3000 pz',
      '5000 pz',
      '6000 pz',
    ],
  },
];

const costiRicetta ={
  listaFormato:[
    {id:0, value:'90g', label:'90g'},
    {id:1, value:'100g', label:'100g'},
    {id:2, value:'120g', label:'120g'},
    {id:3, value:'250g', label:'250g'},
    {id:4, value:'400g', label:'400g'},
  ],
  listaPezziCartone:[
    {id:0, value:'100', label:'100'},
    {id:1, value:'200', label:'200'},
    {id:2, value:'300', label:'300'},
  ],
  listaRateManodopera:[
    {value:'rate 1'},
    {value:'rate 2'},
  ],
  listaRateEnergia:[
    {value:'rateEnergia 1'},
    {value:'rateEnergia 2'},
    {value:'rateEnergia 3'},
  ],
  listaRicarico:[
    {id:1, value:'2%', label:'2%'},
    {id:2, value:'4%', label:'4%'},
    {id:3, value:'5%', label:'5%'},
    {id:4, value:'12%', label:'12%'},
  ],
}



export const costi_mock = {
  cliente_mock:costiCliente,
  costiRicetta_mock:costiRicetta,
  cartone_mock:cartone,
  packaging_mock:packaging,
}




const cartone_update = [
  {
    campo1:'cartoneCartone',
    campo2:'quantitaMin',
    tipi:[
      'cartone1',
      'cartone2',
      'cartone3',
      'cartone4',
    ],
    quantita:[
      '1000 pz',
      '2000 pz',
      '3000 pz',
      '4000 pz',
    ],
  },
  {
    campo1:'cartoneCoperchio',
    campo2:'quantitaMin',
    tipi:[
      'coperchio1',
      'coperchio2',
      'coperchio3',
      'coperchio4',
    ],
    quantita:[
      '500 pz',
      '1000 pz',
      '1500 pz',
      '2500 pz',
    ],
  },
  {
    campo1:'cartoneEtichetta',
    tipi:[
      'ET001',
      'ET002',
      'ET003',
      'ET004',
    ],
  },

];

export const costi_mock_update = {
  cliente_mock:costiCliente,
  costiRicetta_mock:costiRicetta,
  cartone_mock:cartone_update,
  packaging_mock:packaging,
}
