import React from 'react';
import {Select} from 'antd';
import 'antd/dist/antd.css';
import './../../App.scss';
import FloatLabel from '../floatLabel/FloatLabel';
import PropTypes from 'prop-types';
import style from '../newOfferta/NewOfferta.module.scss';


const {Option} = Select;

const FloatSelectCode = props => {
  // const [selectValue, setSelectValue] = useState();

  // let options = [
  //   'Jack', 'Lucy', 'Tim', 'Tom', 'Jerry',
  // ];
  const trattino = ' - ';

  const onSelectVal = (value) => {
    // lo spara fuori e lo riceve poi dal parente via newValue pechè andrà di volta in volta aggiornato
    // setSelectValue(record);
    props.onSelected(value);
  }


  function getNewValue() {
    const nValue = props.newValue !== undefined ? props.options.find(elm => elm?.id === props.newValue):'';
    if(nValue){
      if(props.isRicette){

        const ric = nValue.code? `${nValue?.code}${trattino}${nValue?.nome}`:nValue.nome;
        return ric;
      }
      const finale = nValue.code? `${nValue?.code}${trattino}${nValue?.label}`:nValue.label;
      return finale;
    }else{
      return null;
    }
    // const nValue = props.newValue !== undefined ? props.options.find(elm => elm.id === props.newValue):'';
    // return nValue?.label;
  }



  return (props.options? <FloatLabel
    label={props.field} name='name' value={getNewValue()}>
    <Select
      showSearch={true}
      optionFilterProp='children'
      className={`${props.disabled? style.selectBlocked:''}`}
      showArrow={!props.disabled}
      style={{width:'100%'}}
      onChange={value => onSelectVal(value)}
      value={getNewValue()}

      // disabled={props.disabled}
      allowClear={!props.notAllowClear}>
      {
        // props.options.map((elm, index) => <Option key={`${elm} ${index}`} record={elm.toLowerCase()}>{elm}</Option>)
        props.isRicette?
          props.options.map((elm, index) => <Option key={`${elm.nome} ${index}`} value={elm.id}>{elm.code}{trattino}{elm.nome}</Option>)
          :props.options.map((elm, index) => <Option key={`${elm.label} ${index}`} value={elm.id}>{elm.code}{trattino}{elm.label}</Option>)
      }
    </Select>
  </FloatLabel>:null);
};


FloatSelectCode.propTypes = {
  field:PropTypes.string.isRequired,
  // valueImpost:PropTypes.string,
  options:PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelected:PropTypes.func.isRequired,
  newValue:PropTypes.any,
  disabled:PropTypes.bool,
  notAllowClear:PropTypes.bool,
  isRicette:PropTypes.bool,
};
export default FloatSelectCode;
