export const lingua = {
  login:{
    username:'Username',
    password:'Password',
    insertUser:'Digitare il proprio Username',
    insertPass:'Digitare la proprio Password',
  },
  loading:'Caricamento in corso…',

  user:{
    logout:'Logout',
  },

  offerte:{
    title:'Offerte',
    table:{
      id:'Id',
      codice:'Codice',
      cliente:'Cliente',
      formato:'Formato',
      pezziCartone:'Pezzi cartone',
      fondo:'Fondo / Vaschetta carta',
      top:'Top / Flowpack / Sacchetto',
      conf1:'Fascetta / Cluster / Astuccio / Etichetta',
      confr2:'Etichetta Retro',
      ricette:'Ricette',
    },
    filtri:{
      selectRicetta:'Seleziona una ricetta',
      selectCliente:'Seleziona un cliente',
      dataStart:'Da',
      dataEnd:'A',
    },
    dropdown:['Ricette Incluse','Copia','Elimina', 'Esporta'],
    noResults:[
      'Non ci sono offerte',
      'Clicca il pulsante +OFFERTA in alto a destra per aggiungere offerte',
    ],
    campoVuoto:'Campo vuoto' ,
    ricetteIncluse:{
      titolo:'Ricette incluse',
      testo:'Le ricette incluse sono:',
      nessunaRicetta:'Non ci sono ricette incluse.',
    },
    eliminaRicette:{
      titolo:'Eliminare',
      testo:'Sarà impossibile recuperare l’offerta una volta eliminata.',
    },
    eliminaSelezionate:{
      titolo:'Eliminare le #numero offerte selezionate?',
      testo:'Sarà impossibile recuperare le offerte una volta eliminate.',
    },
    stampa:{
      titolo:'Esportazione offerta',
      testo:'Download del file in corso',
    },
  },

  offerta:{
    nuovaOfferta:'Nuova Offerta',
    offerta:'Offerta',
    aggiungiRicetta:'Ricetta',
    riga:{
      costoSingolo:'Quantità',
      costoTot:'Costo Totale',
    },
    cliente:{
      nome:'Cliente',
      denominazione:'Inserisci la denominazione',
      zonaGeografica:'Inserisci la zona geografica',
      nazione:'Inserisci la nazione',
      incoterms:'Scegli un INCOTERMS',
      descrizione:'Inserisci una descrizione',
    },
    packagingFondo:{
      label:'Scegli il Fondo / Vaschetta di carta',
      quantita:'Q.tà min. ordine',
    },
    packagingTop:{
      label:'Scegli il Top / Flowpack / Sacchetto',
      quantita:'Q.tà min. ordine',
    },
    packagingConfezionamento1:{
      label:'Scegli la Fascetta / Cluster / Astuccio / Etichetta',
      quantita:'Q.tà min. ordine',
    },
    packagingConfezionamento2:{
      label:'Scegli l\'Etichetta Retro',
      quantita:'Q.tà min. ordine',
    },
    packagingConfezionamento3:{
      label:'Altro',
      quantita:'Q.tà min. ordine',
    },
    cartoneCartone:{
      label:'Scegli il Cartone / Cassetta',
      quantita:'Q.tà min. ordine',
    },
    cartoneCoperchio:{
      label:'Scegli il Coperchio',
      quantita:'Q.tà min. ordine',
    },
    cartoneEtichetta:{
      label:'Con l\'Etichetta?',
      quantita:'Q.tà min. ordine',
    },
    formatoRicetta:{
      nome:'Formato',
    },
    packaging:{
      nome:'Packaging',
    },
    cartone:{
      nome:'Cartone',
    },
    nuovaRicetta:'Nuova Ricetta',
    ricette:{
      eliminaRicetta:{
        titolo:'Attenzione',
        testo:'Sarà impossibile recuperare la ricetta una volta eliminata.',
      },
      ricetta:'Ricetta',
      formato:'Scegli il Formato',
      pezziCartoneOld:'Scegli i Pezzi Cartone',
      pezziCartone:'Numero Pezzi Cartone',
      rateManodopera:'Insersci il Rate manodopera €/Kg',
      descrizione:'Descrizione',
      rateEnergia:'Insersci il Rate energia €/Kg',
      techmod:'Inserisci lo scarto ricetta',
      margine:'Inserisci il Ricarico',
      costoKg:'Costo al Kg',
      costoRicetta:'Costo della ricetta',
      totale:'Totale',
      totale1:'Prezzo di vendita:',
      totale2:'Costo cartone:',
      totale3:'Costo singolo pack:',
      totale4:'Prezzo singolo pack:',
      nessunIngrediente:'Attenzione, nessun ingrediente presente nella ricetta.',
    },
    ingredienti:{
      costo: 'Costo',
      prezzo: 'Prezzo / UM',
      scarto:'Scarto',
      scartoNullo:' - ',
      grammi:'Grammi / Quantità',
      nome:'Ingrediente',
    },
  },
  generic:{
    inputCosto:'Inserire il valore',
    deseleziona:'Deseleziona',
  },

  bts:{
    accedi:'Accedi',
    salva:'Salva',
    addOfferta:'OFFERTA',
    login:'Log in',
    chiudi:'Chiudi',
    indietro:'Indietro ',
    backOfferte:'Torna alle offerte',
    riprova:'Riprova',
    elimina:'Elimina',
    annulla:'Annulla',
  },
  errors:{
    titolo:'Attenzione',
    generic:'C\'è stato un errore:',
    offertaNotSaved:'C\'è stato un errore. Offerta non salvata:',
    offertaNotDeleted:'C\'è stato un errore. Cancellazione non riuscita.',
    ricettaNotLoaded:'C\'è stato un errore. La ricetta non è stata caricata.',
    offertaNotLoaded:'C\'è stato un errore. Offerta non caricata.',
    erpFails:'C\'è stato un errore di connessione con i servizi erp.bertagni1882.it. Impossibile recuperare la lista degli ingredienti.',
    login:'Username o password non validi',
    generic_api:'C\'è stato un errore. Riprovare più tardi.',
  },
}
