import React from 'react';
import {Col, Row} from 'antd';
import {CestinoIcon} from '../../helpers/Icons';
import {lingua} from '../../Config/Lingua_it';
import PropTypes from 'prop-types';
import style from './ListaOfferte.module.scss';


const DeleteRowsButton = props => {

  const deleteRows = () => {
    //delete list
    // console.log(' - deleteRows - this.props.rows >>> ', props.rows  );
    props.onDeleteSelected();

  }

  return <Row>

    <Col flex='88px'>
      <button className={style.btnElimina} onClick={deleteRows}>
        <CestinoIcon /> {lingua.bts.elimina}
      </button>
    </Col>
    {/*<Col flex='auto'></Col>*/}
  </Row>;
}

DeleteRowsButton.propTypes = {
  rows:PropTypes.arrayOf(PropTypes.number),
  onDeleteSelected:PropTypes.func,
}


export default DeleteRowsButton;
