import React from 'react';
import style from './NewOfferta.module.scss';
import {Link} from 'react-router-dom';
import {SaveOutlined} from '@ant-design/icons';
import {BackIcon} from '../../helpers/Icons';

import PropTypes from 'prop-types';
// import {Col, Row} from "antd";

const OffertaHeader = props => {

  function onPressSalva() {
    props.onPressSave();
  }

  return <div className={style.offertaHeader}>
    {/*<div>*/}
    {/*  <Row gutter={[{xs:6, sm:12, md:25},{xs:6, sm:12, md:24}]}>*/}
    {/*    <Col xs={24} sm={24} md={8}>*/}
    {/*      <Link to='/dashboard'><BackIcon/></Link>*/}
    {/*      <h2>{props.title}</h2>*/}
    {/*    </Col>*/}
    {/*    <Col xs={24} sm={24} md={8} offset={8}>*/}
    {/*      <button className={style.btnSalva} onClick={onPressSalva}><SaveOutlined/> Salva</button>*/}
    {/*    </Col>*/}
    {/*  </Row>*/}
    {/*</div>*/}
    <div>
      <span>
        <Link to='/dashboard'><BackIcon/></Link>
        <h2>{props.title}</h2>
      </span>
      <button className={style.btnSalva} onClick={onPressSalva}><SaveOutlined/> Salva</button>
    </div>

  </div>;
}
OffertaHeader.propTypes = {
  title:PropTypes.string.isRequired,
  onPressSave:PropTypes.func.isRequired,
};

export default OffertaHeader;
