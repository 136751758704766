import {all, call, put, takeLatest} from 'redux-saga/effects'
import actions from 'redux/Authenticate/actions';
import {getRequest, postRequest, deleteRequest, putRequest} from 'Config/axiosClient'


function* login(action) {
  try {
    const response = yield call(() => postRequest('login', action.payload));

    // const response2 = yield call(() => postRequest('offerStart', action.payload));
    // aggiungere altri response MA occhio che la stessa proprietà deve essere
    // usata anche nel reducer, con lo stesso nome

    localStorage.setItem('auth', response.data.token);
    yield put({type: actions.LOGIN_SUCCESS, response});
  } catch (e) {
    console.warn('login - login - e >>> ', e  );
    yield put({type: actions.LOGIN_FAILURE});
  }
}

function* logout() {
  try {
    const response = yield call(() => postRequest('logout'));


    // const response2 = yield call(() => postRequest('offerStart', action.payload));
    // aggiungere altri response
    console.log('logout - logout - SUCCESS >>> '  );
    localStorage.removeItem('auth');
    const casAuth = response.data;
    yield put({type: actions.LOGOUT_SUCCESS, casAuth});
  } catch (e) {
    console.warn('logout - logout - e >>> ', e  );
    yield put({type: actions.LOGOUT_FAILURE});
  }
}

function* casConnect(action) {
  try {
    const result = yield call(() => postRequest('casConnect', action.payload));
    const casAuth = result.data;
    yield put({type: actions.CASCONNECT_RESPONSE, casAuth});
  } catch (e) {
    // console.warn('casConnect - e >>> ', e  );
    yield put({type: actions.CASCONNECT_FAILURE});
  }
}


function* getPageOfferte() {
  try {
    // // aggiungere altri response se necessari, tipo per avere la lista dei clienti, package etc:
    const result = yield call(() => getRequest('offerte')); //?page=2
    // const allRicette = yield call(() => getRequest('ricette'));

    const offerte = result.data.data.offerte;
    yield put({type: actions.GET_OFFERTE, offerte});
    // yield put({type: actions.GET_NOMI_RICETTE, allRicette});
    // console.log('getlista - getlista - response >>> ', response.data  );
    yield put({type: actions.GET_PAGE_OFFERTE_SUCCESS});
  } catch (e) {
    console.warn('getPageOfferte - e >>> ', e  );
    yield put({type: actions.GET_PAGE_OFFERTE_FAILURE, e});
  }
}
function* getPageOfferteByDate(action) {
  try {
    let urlmodifca = `offerte?dataInizio=${action.period.dataInizio}&dataFine=${action.period.dataFine}`;
    if(action.cliente_id){
      urlmodifca += `&cliente_id=${action.cliente_id}`;
    }
    const result = yield call(() => getRequest(urlmodifca));

    const offerte = result.data.data?result.data.data.offerte:[];
    yield put({type: actions.GET_OFFERTE, offerte});
    // console.log('getlista - getlista - response >>> ', response.data  );
    yield put({type: actions.GET_PAGE_OFFERTE_SUCCESS});
  } catch (e) {
    console.warn('getPageOfferte - e >>> ', e  );
    yield put({type: actions.GET_PAGE_OFFERTE_FAILURE});
  }
}



function* getPageOfferta(action?) {
  try {
    //users?delay=3
    // const allRicette = yield call(() => getRequest('users?page=1'));
    // const allCosti = yield call(() => getRequest('unknown?per_page=10'));
    // const allRicette = yield call(() => getRequest('datiOfferta'));
    const allCosti = yield call(() => getRequest('datiOfferta'));
    const allRicette = yield call(() => getRequest('ricette'));

    if(action && action.payload){
      const urlmodificata = `offerta/${action.payload}`;
      const offerta = yield call(() => getRequest(urlmodificata));
      yield put({type: actions.GET_ALL_COSTI, allCosti});
      yield put({type: actions.GET_ALL_RICETTE, allRicette});
      yield put({type: actions.GET_OFFERTA, offerta});

    }else{
      yield put({type: actions.GET_ALL_COSTI, allCosti});
      yield put({type: actions.GET_ALL_RICETTE, allRicette});

    }
    yield put({type: actions.GET_PAGE_OFFERTA_SUCCESS});
    // yield put({type: actions.GET_RICETTE_SUCCESS, nomiRicette});
  } catch (e) {
    console.warn('getPageOfferta - getRicette - e >>> ', e  );
    yield put({type: actions.GET_PAGE_OFFERTA_FAILURE, response: e.response });
  }
}

function* getIngredientiRicetta(action) {
  try {
    const urlmodificata = `ricetta/${action.ricettaId}`;
    const ricettaIngredienti = yield call(() => getRequest(urlmodificata));
    // console.log('getIngredientiRicetta - getIngredientiRicetta - ricettaIngredienti >>> ', ricettaIngredienti  );

    // yield put({type: actions.GET_RICETTE_SUCCESS, nomiRicette});
    yield put({type: actions.GET_INGREDIENTI_RICETTA_START});
    yield put({type: actions.GET_INGREDIENTI_RICETTA_SUCCESS, ricettaIngredienti});
  } catch (e) {
    console.warn('getRicettaIngredienti - getRicettaIngredienti - e >>> ', e  );
    yield put({type: actions.GET_INGREDIENTI_RICETTA_FAILURE, response: e.response});
  }
}

function* updateOfferta(action){
  try {
    // Request /api/users
    // {
    //     "name": "morpheus",
    //     "job": "leader"
    // }


    const response = yield call(() => getRequest('users?page=2'));
    const allCosti = yield call(() => getRequest('unknown?per_page=5')); //?page=2

    yield put({type: actions.UPDATE_GET_ALL_COSTI, allCosti});
    yield put({type: actions.UPDATE_OFFERTA_CAMPI, response});
    yield put({type: actions.UPDATE_OFFERTA_SUCCESS});
  } catch (e) {
    console.warn('updateOfferta - updateOfferta - e >>> ', e  );
    yield put({type: actions.UPDATE_OFFERTA_FAILURE});
  }

}

function* saveOfferta(action) {
  try {

    //api/offerte
    // POST
    //
    // {
    //     "cliente_id":1657,
    //     "cliente_zona_id":null,
    //     "cliente_nazione_id":5,
    //     "cliente_incoterms_id":161,
    //     "cliente_descrizione":"uno due tre",
    //     "packaging_fondo_id":1,
    //     "packaging_fondo_kg_id":55,
    //     "packaging_fondo_costo":90.50
    // }
    // console.log('saveOfferta - saveOfferta - action.payload >>> ', action.payload  );
    const response = yield call(() => postRequest('offerte', action.payload));
    // console.log('saveOfferta - saveOfferta - response >>> ', response  );
    yield put({type: actions.SAVE_OFFERTA_SUCCESS, response});
    yield put({type: actions.SAVE_OFFERTA_END});
  } catch (e) {
    console.warn('ERROR saveOfferta - saveOfferta - e >>> ', e  );
    yield put({type: actions.SAVE_OFFERTA_FAILURE, e});
    yield put({type: actions.SAVE_OFFERTA_END});
  }
}
function* modificaOfferta(action) {
  try {
    // chiama PUT passando l'id nell'URL
    // console.log('modificaOfferta - modificaOfferta -action.payload  >>> ',  action.payload );
    const urlmodifca = `offerta/${action.recordId}`;
    const response = yield call(() => putRequest(urlmodifca, action.payload));
    yield put({type: actions.SAVE_OFFERTA_SUCCESS, response});
    yield put({type: actions.SAVE_OFFERTA_END});
  } catch (e) {
    console.warn('ERROR modificaOfferta - modificaOfferta - e >>> ', e  );
    yield put({type: actions.SAVE_OFFERTA_FAILURE, e});
    yield put({type: actions.SAVE_OFFERTA_END});
  }
}

function* deleteOfferta(action) {
  try {

    //api/offerte
    // basta chiamare /api/offerta/14 con metodo DELETE
    // DELETE
    const response = yield call(() => deleteRequest(`offerta/${action.payload}`));
    yield put({type: actions.DELETE_OFFERTA_SUCCESS, response});
    yield put({type: actions.DELETE_OFFERTA_END});
  } catch (e) {
    console.warn('deleteOfferta - deleteOfferta - e >>> ', e );
    yield put({type: actions.DELETE_OFFERTA_FAILURE});
    yield put({type: actions.DELETE_OFFERTA_END});
  }
}
function* deleteOfferte(action) {
  try {

    //api/offerte
    // basta chiamare /api/offerta/14 con metodo DELETE
    // DELETE
    console.log('deleteOfferte - deleteOfferte - action.ids >>> ', action, action.payload  );
    const response = yield call(() => deleteRequest(`offerte?ids=${action.payload}`));
    yield put({type: actions.DELETE_OFFERTA_SUCCESS, response});
    yield put({type: actions.DELETE_OFFERTA_END});
  } catch (e) {
    console.warn('deleteOfferte - deleteOfferte - e >>> ', e  );
    yield put({type: actions.DELETE_OFFERTA_FAILURE, e});
    yield put({type: actions.DELETE_OFFERTA_END});
  }
}

// function* stampaOfferta(action) {
//   try {
//
//     //  -- /api/export/326 con metodo GET?
//     console.log('stampaOfferta - stampaOfferta - action.payload >>> ', action.payload  );
//
//     const response = yield call(() => getRequest(`export/${action.payload}`));
//     yield put({type: actions.STAMPA_OFFERTA_SUCCESS, response});
//   } catch (e) {
//     console.log('stampaOfferta - stampaOfferta - e >>> ', e  );
//     yield put({type: actions.STAMPA_OFFERTA_FAILURE});
//   }
// }

export default function* rootSaga() {
  yield all(
    [
      takeLatest(actions.LOGIN, login),
      takeLatest(actions.LOGOUT, logout),
      takeLatest(actions.CASCONNECT, casConnect),
      takeLatest(actions.GET_PAGE_OFFERTE, getPageOfferte),
      takeLatest(actions.GET_PAGE_OFFERTE_BY_DATE, getPageOfferteByDate),
      takeLatest(actions.GET_PAGE_OFFERTA, getPageOfferta),
      takeLatest(actions.GET_INGREDIENTI_RICETTA, getIngredientiRicetta),
      takeLatest(actions.UPDATE_OFFERTA, updateOfferta),
      takeLatest(actions.SAVE_OFFERTA, saveOfferta),
      takeLatest(actions.MODIFICA_OFFERTA, modificaOfferta),
      takeLatest(actions.DELETE_OFFERTA, deleteOfferta),
      takeLatest(actions.DELETE_OFFERTE, deleteOfferte),
      // takeLatest(actions.STAMPA_OFFERTA, stampaOfferta),
    ]);
}
