import React from 'react';
import {useDispatch} from 'react-redux'
import {Avatar, Button, Dropdown, Menu} from 'antd';
import {LogoutIcon} from '../../helpers/Icons';
import style from './User.module.scss';
// import {Link} from 'react-router-dom';
import actions from '../../redux/Authenticate/actions';
import {lingua} from '../../Config/Lingua_it';


const User = props => {
  const dispatch = useDispatch();

  const onLogoutClick = (evt) => {
    //
    dispatch({
      type:actions.LOGOUT,
    });
  }
  const menu = (
    <Menu>
      <Menu.Item>
        <Button onClick={onLogoutClick}><LogoutIcon/> {lingua.user.logout}</Button>
      </Menu.Item>
    </Menu>
  );

  return (<div className={style.userBox}>
    <Dropdown overlay={menu} placement='bottomRight'>
      <Avatar
        // src={<Image src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
        src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
      />
      {/*<Button><MoreOutlined/></Button>*/}
    </Dropdown>
  </div>);

}

export default User;
