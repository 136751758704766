import React, {useState} from 'react';

// import './../../index.css';
import './FloatLabel.scss';

/**
 * per avere i vari testi float
 *
 */
const FloatLabel = props => {
  const [focus, setFocus] = useState(false);
  const {children, label, value} = props;

  const labelClass =
    focus || (value && value.length !== 0)? 'label labelFloat':'label';

  return (
    <div
      className='floatLabel'
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass}>{label}</label>
    </div>
  );
};

export default FloatLabel;

