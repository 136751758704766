import React from 'react';
import {InputNumber} from 'antd';
import 'antd/dist/antd.css';
import './../../App.scss';
import FloatLabel from '../floatLabel/FloatLabel';
import PropTypes from 'prop-types';


const FloatNumber = props => {
  // const [selectValue, setSelectValue] = useState();


  const onChangeVal = (value) => {
    // console.log(' ......................   - onChangeVal -  ');
    // questo serve per il clear e l'autodigitazione del campo
    // setSelectValue(record);
    // console.log(' - onChangeVal - value >>> ', value  );
    props.onSelected(value);
  }
  return (
    <FloatLabel label={props.field} name='name' value={props.disabled ? '':props.newValue}>
      {props.isPerc?<InputNumber
        min={0}
        max={100}
        formatter={value => (value > 0)?`${value}%`:''}
        parser={value => value.replace('%', '')}
        disabled = {props.disabled}
        value={props.disabled ? '':props.newValue}
        onChange={onChangeVal}
        // // onSelect={record => onSelectVal(record)}
        // style={{width:'100%', textAlign:'left'}}
        // options={props.options}
        // filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
      />:<InputNumber
        min={ (props.minValue || props.minValue === 0 )? props.minValue:1 }
        max={100000}
        disabled = {props.disabled}
        value={props.disabled || props.newValue === null ? '':props.newValue}
        onChange={onChangeVal} />
      }
    </FloatLabel>
  )
};


FloatNumber.propTypes = {
  allowClear:PropTypes.bool,
  disabled:PropTypes.bool,
  isPerc:PropTypes.bool,
  minValue:PropTypes.number,
  field:PropTypes.string.isRequired,
  onSelected:PropTypes.func.isRequired,
  newValue:PropTypes.any,
};

export default FloatNumber;
