import React, {useEffect, useState} from 'react';
import {Modal} from 'antd';
import style from './NewOfferta.module.scss';
import {OffertaIcon} from '../../helpers/Icons';
import {PlusOutlined} from '@ant-design/icons';
import Ricetta from './ricetta/Ricetta';
import PropTypes from 'prop-types';
import {lingua} from '../../Config/Lingua_it';


const OffertaRicette = props => {
  // const [showModal] = useState(false);

  // const {ricetta, ricettaIngredienti, ingredientiPlane, loader} = useSelector(state => state.authenticateReducer);
  // const [value, setValue] = useState('');
  // const [currentRicettaId, setCurrentRicettaId] = useState(null);
  const [mieRicetteId, setMieRicetteId] = useState([]);


  //
  useEffect( () => {
    const preLista = [...props.recordRicette];
    preLista.forEach((elm, index) => {elm.dontClear = true; elm.bloccoId = index});
    if(props.recordRicette.length > 0) setMieRicetteId(props.recordRicette);
  }, [props.recordRicette]);



  // const dispatch = useDispatch();

  // onChange = ({target:{record}}) => {
  //   this.setState({record});
  // };

  // const callback = (key) => {
  //   console.log(key);
  // };
  //
  //
  // useEffect(() => {
  //   // console.log(' -  - -------------------------- >>> ', costi.cartone_mock  );
  //   // record = {};
  //   // record = null;
  //   alert(props.pezziCartone);
  // }, [props.grammi, props.costiCartone, props.pezziCartone, props.costiPackaging]);

  const onUpdateRicetta = (objRicetta) => {

    // non esiste, quando aggiungo una vuota
    if(!objRicetta.ricetta_id) return;

    // delete objRicetta.bloccoId;

    let listaObjRicetteTemp = [...mieRicetteId];

    // confronto bloccoID per capire se esiste già e non ricetta_id che può essere ripetuto N volte
    // messo elm?.bloccoId perché se ne cancello trova null
    let ce =  listaObjRicetteTemp.find(elm => elm?.bloccoId === objRicetta.bloccoId )
    
    // console.log('ce - onUpdateRicetta - ce >>> ', ce  );

    if(ce ){
      // console.log(' ......................  E S I S T E     - onUpdateRicetta -  ');
      // esiste già, la devo aggiornare
      const pos = listaObjRicetteTemp.indexOf(ce);

      listaObjRicetteTemp[pos] = null;
      // console.log(' - onUpdateRicetta - listaObjRicetteTemp >>> ', listaObjRicetteTemp  );
      listaObjRicetteTemp[pos] = {...objRicetta};
      // const ce2 =  listaObjRicetteTemp.find(elm => elm.bloccoId === objRicetta.bloccoId )
      // console.log(' - onUpdateRicetta - ce2 >>> ', ce2  );
      // console.log(' - onUpdateRicetta - mieRicetteId >>> ', mieRicetteId  );

      setMieRicetteId(listaObjRicetteTemp);

      props.onRicetteUpdate(listaObjRicetteTemp);

    }else{
      console.log(' ...................... N O N   E S I S T E     - onUpdateRicetta -  ');
      // non esiste la aggiungo
      setMieRicetteId([...listaObjRicetteTemp, ...[objRicetta]]);
      props.onRicetteUpdate([...listaObjRicetteTemp, ...[objRicetta]]);
    }

  }
  /**
   * Modale per l'eliminazione
   * @param evt blocco_id, al primo giro è esattamente la posizione della ricetta nella lista
   */
  const onDeleteRicetta = (evt) => {

    const title = `${lingua.offerta.ricette.eliminaRicetta.titolo} `; //${this.sel_cliente.posId}
    let mod = Modal.confirm({
      title:(<h3>{title}</h3>),
      width:563,
      height:300,
      maskClosable:true,
      centered:true,
      content:(<div><p>{lingua.offerta.ricette.eliminaRicetta.testo}</p></div>),
      cancelText:lingua.bts.annulla,
      okText:lingua.bts.elimina,
      onOk:() => {
        mod.destroy();
        // console.log(`OK >>>>   ${evt}`);
        handleEliminaOk(evt);
      },
    });
  }

  /**
   * Serve per eliminare completamente la ricetta dalla lista, DOM + obj
   * @param bloccoId l'id del blocco, che NON è l'id della ricetta ma serve per essere univoco
   */
  const handleEliminaOk = (bloccoId) => {
    // let listaObjRicetteTemp = [...listaObjRicette];
    // console.log(' - handleEliminaOk - bloccoId >>> ', bloccoId , listaObjRicette );
    // console.log(' - handleEliminaOk - mieRicetteId >>> ', mieRicetteId  );

    const mieRicetteIdTemp = [...mieRicetteId];
    // console.log(' - handleEliminaOk - listaObjRicetteTemp >>> ', listaObjRicetteTemp  );
    // togliere l'elemento dalla lista sposta il corrispettivo sbagliato nel dom generato, sostituirlo con un null è la soluzione
    const nuoveRicetteId = [...mieRicetteIdTemp.slice(0, bloccoId), null, ...mieRicetteIdTemp.slice(bloccoId + 1)];
    // console.log(bloccoId, 'nuoveRicetteId - handleEliminaOk - nuoveRicetteId >>> ', nuoveRicetteId  );
    // const nuovaObjRicette = [...listaObjRicette.slice(0, bloccoId), ...listaObjRicette.slice(bloccoId + 1)];
    // console.log(' - handleEliminaOk - nuovaObjRicette >>> ', nuovaObjRicette  );
    setMieRicetteId(nuoveRicetteId);
    props.onRicetteUpdate(nuoveRicetteId);
  };

  /**
   * Aggiunge una nuova ricetta con il nome "nuova ricetta" alla lista
   */
  const onAddRicetta = () => {
    // const {recordRicette} = props;
    // aggiunge la ricetta vuota con il testo "Nuova ricetta" quindi di fatto non ha aggiunto ancora nulla
    const nuoveRicette = [ ...mieRicetteId, {nome:lingua.offerta.nuovaRicetta, bloccoId:mieRicetteId.length }];

    //console.log(' ......................   - onAddRicetta -  ',nuoveRicette);
    setMieRicetteId(nuoveRicette);
    // this.setState({
    //   mieRicetteId:nuoveRicette,
    // });

  }

  /*
  const getRicette = () =>{
    const {recordRicette} = props;


    if (mieRicetteId.length === 0) {
      if (recordRicette && recordRicette.length > 0) {

        // setListaObjRicette([...recordRicette]);
        // this.setState({
        //   mieRicette:record.ricette,
        // });
        // console.log('OffertaRicette - getRicette - record.ricette >>> ', recordRicette  );
        return recordRicette;
      }
    }
    return mieRicetteId;
  }
  */


  // const ricetteOfferta = getRicette();


  const titolo = props?.ordine?.nome || 'Ricette';
  // Ordine - {oggi} {new Date().toLocaleDateString()}
  return <div className={style.gruppo}>
    {/*{console.log(' - OffertaRicette - props  -   recordRicette >>> ', props.recordRicette  )}*/}
    {/*{console.log(' - OffertaRicette - listaObjRicette >>> ', listaObjRicette  )}*/}


    {/*<p>costiPackaging {props?.costiPackaging}</p>*/}
    {/*<p>costiCartone {props?.costiCartone}</p>*/}
    <div className={style.titleBox}><h3><OffertaIcon/> {titolo}</h3></div>
    <br/>
    {mieRicetteId.map((elm, index) => (elm ? <Ricetta
      id={index}
      key={`miaRicetta ${index}`}
      onDelete={onDeleteRicetta}
      onUpdate={onUpdateRicetta}
      pezziCartone={props.pezziCartone}
      grammi={props.grammi}
      costiCartone={props.costiCartone}
      costiPackaging={props.costiPackaging}
      listaRicette={props.listaTutteRicette}
      record={elm}
      dontClear={elm.dontClear}
      {...props}/>:null))}

    <button className={style.btnAddRicetta} onClick={onAddRicetta}><PlusOutlined/> {lingua.offerta.aggiungiRicetta}</button>
  </div>;
}

OffertaRicette.propTypes = {
  // id:PropTypes.number.isRequired,
  // listaNomi:PropTypes.arrayOf(PropTypes.string).isRequired,
  // onDelete:PropTypes.func,
  // listaRicette:PropTypes.arrayOf(
  //   PropTypes.shape({
  //     key:PropTypes.string,
  //     nome:PropTypes.string,
  //     grammi:PropTypes.number,
  //     scarto:PropTypes.number,
  //     costo:PropTypes.number,
  //   }),
  // ),
  onRicetteUpdate:PropTypes.func.isRequired,
  recordRicette:PropTypes.arrayOf(PropTypes.object).isRequired,
  listaTutteRicette:PropTypes.arrayOf(PropTypes.object),
  listaFormato:PropTypes.arrayOf(PropTypes.object),
  listaPezziCartone:PropTypes.arrayOf(PropTypes.object),
  listaRateManodopera:PropTypes.arrayOf(PropTypes.object),
  listaRateEnergia:PropTypes.arrayOf(PropTypes.object),
  listaRicarico:PropTypes.arrayOf(PropTypes.object),
  pezziCartone:PropTypes.number,
  grammi:PropTypes.number,
  costiCartone:PropTypes.number,
  costiPackaging:PropTypes.number,

};

export default OffertaRicette;
