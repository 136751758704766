import React, {useEffect, useRef, useState} from 'react';
import User from '../components/user/User';
import Cliente from '../components/newOfferta/Cliente';
import OffertaHeader from '../components/newOfferta/OffertaHeader';
import OffertaRicette from '../components/newOfferta/OffertaRicette';
import {lingua} from '../Config/Lingua_it';
import {useHistory, useLocation} from 'react-router-dom';
import actions from '../redux/Authenticate/actions';
import BloccoCostiCartone from '../components/newOfferta/BloccoCostiCartone';
import {CartoneIcon, PackagingIcon} from '../helpers/Icons';
import {useDispatch, useSelector} from 'react-redux';
import {costi_mock} from '../helpers/SelectMock';
import BloccoFormato from '../components/newOfferta/BloccoFormato';
import {Modal, Spin} from 'antd';
import GenericLoading from '../components/genericLoading/GenericLoading';
import BloccoCostiPackaging from '../components/newOfferta/BloccoCostiPackaging';
import {ParseToDecimal4} from "../helpers/Helper";

// import PropTypes from 'prop-types';

/*
esempio di OBJ per il save:

{
    "cliente": {
        "id": null,
        "nome": "TestCliente"
    },
    "cliente_zona": {
        "id": null,
        "nome": "zonamia"
    },
    "cliente_nazione": {
        "id": null,
        "nome": "Sealand"
    },
    "cliente_incoterms_id": 183,
    "cliente_descrizione": "uno due tre",
    "formato_id": 1,
    "formato_pezzi_cartone": 15,
    "packaging_fondo_id": 2615,
    "packaging_fondo_costo": 90.50,
    "packaging_top_id": 2626,
    "packaging_top_kg_id": 55,
    "packaging_top_costo": 24,
    "packaging_confezionamento_id": 2636,
    "packaging_confezionamento_pz_id": 33,
    "packaging_confezionamento_costo": 10,
    "packaging_confezionamento2_id": 2636,
    "packaging_confezionamento2_pz_id": 34,
    "packaging_confezionamento2_costo": 5,
    "cartone_cartone_id": 2647,
    "cartone_cartone_pz_id": 28,
    "cartone_cartone_costo": 54,
    "cartone_coperchio_id": 2889,
    "cartone_coperchio_pz_id": 35,
    "cartone_coperchio_costo": 45,
    "cartone_etichetta_id": 3072,
    "cartone_etichetta_costo": 22,
    "totale_costo": 100,
    "ricette" : [
    {
        "ricetta_id":7780,
        "rate_manodopera": 20,
        "rate_energia": 10,
        "margine": 10,
        "totale_costo": 1100,
        "ingredienti":[
            {
                "ricetta_ingrediente_id":644,
                "prezzo": 2001
            }
        ]
    },
    {
        "ricetta_id":7781,
        "rate_manodopera": 22,
        "rate_energia": 12,
        "margine": 10,
        "totale_costo": 1100,
        "ingredienti":[
            {
                "ricetta_ingrediente_id":499,
                "prezzo": 2002
            },
            {
                "ricetta_ingrediente_id":500,
                "prezzo": 2003
            },
            {
                "ricetta_ingrediente_id":503,
                "prezzo": 2004
            }
        ]
    },
    {
        "ricetta_id":7782,
        "rate_manodopera": 22,
        "rate_energia": 12,
        "margine": 10,
        "ingredienti":[
            {
                "ricetta_ingrediente_id":499,
                "prezzo": 2005
            },
            {
                "ricetta_ingrediente_id":501,
                "prezzo": 2006
            }
        ]
    }
    ]
}
 */

// const oggi = `0001/${new Date().getFullYear() % 2000}`;
const OffertaPage = props => {

  const location = useLocation();
  const isInitialMount = useRef(true);
  const [page, setPage] = useState(1);

  const {loader} = useSelector(state => state.authenticateReducer)
  const {saved} = useSelector(state => state.authenticateReducer)
  const {messages} = useSelector(state => state.authenticateReducer)
  const {error} = useSelector(state => state.authenticateReducer)
  const {responseType} = useSelector(state => state.authenticateReducer)
  const {listaRicette} = useSelector(state => state.authenticateReducer);
  const {costi} = useSelector(state => state.authenticateReducer);
  let {record} = useSelector(state => state.authenticateReducer);
  // const [isModalVisible, setModalVisible] = useState(false);
  const [offerta_current_cliente, setOffertaCliente] = useState({});
  const [offerta_current_packaging, setOffertaPackaging] = useState({});
  const [offerta_current_cartone, setOffertaCartone] = useState({});
  const [offerta_current_formato, setOffertaFormato] = useState({});
  const [offerta_current_formato_fondi, setOffertaFormatoFondi] = useState([]);
  const [offerta_current_formato_top, setOffertaFormatoTop] = useState([]);
  const [offerta_current_formato_grammi, setOffertaFormatoGrammi] = useState(0);
  const [offerta_current_pezzicartone, setOffertaPezzicartone] = useState({});
  const [offerta_current_ricette, setOffertaRicette] = useState([]);
  const [offerta_current_tot_packaging, setTotalePackaging] = useState(undefined);
  const [offerta_current_tot_cartone, setTotaleCartone] = useState(undefined);


  // const title = location.record? location.record.codice:`${lingua.offerta.nuovaOfferta} - 0001/${new Date().getFullYear() % 2000}`;
  const title = location.record? `${lingua.offerta.offerta} ${location.record.codice}`:lingua.offerta.nuovaOfferta;
  const history = useHistory();
  const dispatch = useDispatch();


  const [modal, contextHolder] = Modal.useModal();
  const config = {
    title:(<h3>{lingua.errors.titolo}</h3>),
    centered:true,
    width:563,
    height:300,
    okText:lingua.bts.riprova,
    cancelText:lingua.bts.backOfferte,
    cancelButtonProps:{ disabled: false },

    onOk:() => {
      Modal.destroyAll();
      // metto error a false
      dispatch({
        type:actions.RESET_ERROR,
      });

      dispatch({
        type:actions.GET_PAGE_OFFERTA,
        payload:location.record?.id,
      });
      setPage(page + 1);
    },
    onCancel:() => {
      Modal.destroyAll();
      // metto error a false
      dispatch({
        type:actions.RESET_ERROR,
      });

      // torno alla dashboard
      history.goBack();
    },
    content:(<div><p>{lingua.errors.offertaNotSaved}</p>
      <ul>{messages? messages.map((elm, index) => <li key={`err_${index}`}>{elm}</li>):null}</ul>
    </div>),
  };


  history.listen((location, action) => {
    // location is an object like window.location
    // console.log('location >>>', location.pathname);
    if (location.pathname !== '/offerta') {
      // console.log(' -  - record>>> ', record  );
      // setOffertaCliente({});
      // setOffertaPackaging({});
      // setOffertaCartone ({});
      // setOffertaFormato({});
      // setOffertaFormatoFondi([]);
      // setOffertaFormatoTop([]);
      // setOffertaPezzicartone({});
      // setOffertaRicette([]);

      // record = null;
      // location.record = null;

      // console.log(' -  - record>>> ', record  );
    }
  });

  // useEffect(
  //   () => {
  //     console.log(' -  - location.pathname >>> ', location.pathname  );
  //     console.log(' -  -  >>> ',  record );
  //     if (location.pathname !== "/offerta") {
  //       record = null;
  //       this.state.record = null;
  //     }
  //   },
  //   [location]);




  useEffect(() => {
    // record = {};
    // record = null;
    if (location.record) {
      dispatch({
        type:actions.GET_PAGE_OFFERTA,
        payload:location.record.id,
      });
    } else {
      dispatch({
        type:actions.GET_PAGE_OFFERTA,
      });
    }

    //eseguita all' unnmount
    return () => {
      // dispatch reset store
      // console.log('______________ R E S E T _______________');
      dispatch({
        type:actions.RESET_RECORD,
      });
    }
  }, [dispatch]);


  useEffect(() => {
    // console.log(' -  - saved, isInitialMount.current >>> ', saved, isInitialMount.current);
    if (isInitialMount.current) {
      //blocco al primo giro
      isInitialMount.current = false;
    } else {
      // Your useEffect code here to be run on update
      if (saved) {
        // console.log(`--------------------------------------------------   SAVED  ${saved}`)
        history.push('/dashboard');
        record = null;
      } else {

        if (error) {
          if(responseType === actions.GET_PAGE_OFFERTA_FAILURE || responseType === actions.GET_INGREDIENTI_RICETTA_FAILURE) {
            config.content = (<div><p>{lingua.errors.offertaNotLoaded}</p>
              <ul>{messages? messages.map((elm, index) => <li key={`err_${index}`}>{elm}</li>):null}</ul>
            </div>);

            modal.confirm(config);

          }else{
            modal.error(config);
          }
          //onSaveAbort();
        }
        console.log( `.-.-.-.-.-.-.-.-.-.-.-.  NOT SAVED .-.-.-.-.-.-.-.-.-.-.-. ${responseType}` );
      }
    }
  }, [saved, error, responseType, history]);

  // useEffect(() => {
  //   // console.log(' UPDATE >>>>> offerta_current >>> ', offerta_current  );
  // }, [offerta_current]);

  function planeIngredienti(listone, scarto){

    let listaPiatta = [];
    // esiste? perché alcune ricette non hanno dati
    if(listone && listone.length > 0){
      let nl = listone.length;
      let i = -1;
      while(++i < nl){
        let elm = listone[i];

        // const {pezziCartone, grammi} = props;
        const grammi = getGrammi();
        const pezziCartone = getPezziCartone();
        const riga_scarto = scarto?scarto:0;
        const ric_scarto = scarto?scarto:0;
        const tot_riga = ParseToDecimal4(
          (elm.prezzo*elm.grammi*(1 + riga_scarto/100)*pezziCartone*grammi/1000000)*(1+ric_scarto/100));

        let obj = {ricetta_ingrediente_id:elm.id, prezzo: elm.prezzo, costo:tot_riga};

        // salvo sempre l'elemeto, anche il parent, come detto da Anto:
        // listaPiatta.push(obj);

        // console.log('planeIngredienti - planeIngredienti - elm >>> ', elm  );
        if(elm.ingredienti && elm.ingredienti.length > 0 ){
          // ha figli? metto il costo a 0 così da non usarlo nella somma totale, visto che da email del 27 non tornano i conti
          obj.costo = 0;
          listaPiatta.push(obj);
          //ha figli? ricorsione:
          listaPiatta = [...listaPiatta, ...planeIngredienti(elm.ingredienti, scarto)];
        }else{
          listaPiatta.push(obj);
        }
      }
    }else{
      // console.warn('NESSUN INGREDIENTE');
    }
    return listaPiatta;
  }
// Funzione per rimuovere i duplicati dalla seconda lista
//   function rimuoviDuplicatiLista1(daRecord, daAggiunta) {
//     // quelli nuovi che erano in record
//     const idSet = new Set(daAggiunta.map((elemento) => elemento.code));
//     return daRecord? daRecord.filter((elemento) => !idSet.has(elemento.code)): [];
//   }

  const onSaveOfferta = () => {
    // console.log(' - 1 onSaveOfferta - offerta_current_ricette.ricette >>> ', offerta_current_ricette.ricette);
    // console.log(' - 1 onSaveOfferta - record.ricette >>> ',  record?.ricette );

    // history.push('/dashboard');

    let all_ricette;
    if (offerta_current_ricette.ricette) {


      /*

      const listaNonmodificati = rimuoviDuplicatiLista1(record?.ricette, offerta_current_ricette?.ricette);
      // console.log('rimuoviDuplicati - testObj - lista >>> ', listaNonmodificati  );
      listaNonmodificati.map(elm => {

        const ingredientiPlane = planeIngredienti(elm.ingredienti, elm.scarto);
        elm.ingredienti = ingredientiPlane;
      });

// Unisci le liste di oggetti

      const listaUnificata =  [...listaNonmodificati, ...offerta_current_ricette?.ricette];

// Output
      console.log(listaUnificata);
      offerta_current_ricette.ricette = listaUnificata;

      */
      offerta_current_ricette.ricette = offerta_current_ricette.ricette.filter(el => el);
      offerta_current_ricette.ricette.forEach(elm => {
        if(!elm.ingredienti[0]?.ricetta_ingrediente_id){
          elm.ingredienti = planeIngredienti(elm.ingredienti, elm.scarto);
        }
        delete elm.bloccoId;
      });
      all_ricette = {...offerta_current_ricette};
      // elimino la prop che uso per distinguere le ricette, messa da me perché l'id della ricetta può essere multiplo
      // all_ricette.ricette.map(elm => {
      //   delete elm.bloccoId;
      // });
    }


    if (location && location.record) {
      // SE esiste il record lo sovrascrivo con quello che mi torna dalla modifica - se il campo è null viene cmq sovrascritto

      let offerta_current_modifica = (
        {
          ...record,
          ...offerta_current_cliente,
          ...offerta_current_formato,
          ...offerta_current_pezzicartone,
          ...offerta_current_packaging,
          ...offerta_current_cartone,
          ...offerta_current_ricette,
        }
      );
      // if (offerta_current_modifica.ricette.length > 0) {
        //toDO questa forse non serve, tira un errore nel save perché sovrascrive ricetta_id in undefined

        // offerta_current_modifica.ricette[0].id = offerta_current_modifica.ricette[0].ricetta_id;
        // offerta_current_modifica.ricette[0].ricetta_id = record?.ricette[0]?.ricetta_id;;
      // }
      const id_modifica = offerta_current_modifica.id;
      delete offerta_current_modifica.id;
      delete offerta_current_modifica.key;
      console.log(' - o n S a v e    M O D I F I C A - offerta_current_modifica >>> ', offerta_current_modifica);
      // return;
      dispatch({
        type:actions.MODIFICA_OFFERTA,
        recordId:id_modifica,
        payload:offerta_current_modifica,
      });
    } else {
      // NON esiste il record, è un'offerta nuova - SALVO
      // console.warn(' --------------------------------------- offerta_current_cliente >>> ', offerta_current_cliente  );
      const offerta_current_completa = {
        ...offerta_current_cliente,
        ...offerta_current_formato,
        ...offerta_current_pezzicartone,
        ...offerta_current_packaging,
        ...offerta_current_cartone,
        ...all_ricette,
      };
      console.log(' --- o n S a v e   N U O V A - offerta_current_completa >>> ', offerta_current_completa);
      dispatch({
        type:actions.SAVE_OFFERTA,
        payload:offerta_current_completa,
      });
    }
  };

  // uso l'oggetto direttamente dentro onSaveOfferta

  const onUpdateCliente = (obj) => {
    //update oggetto cliente per il save
    setOffertaCliente(obj);
  }
  const onUpdatePackging = (objCosti) => {
    // console.log(' ......................   - onUpdatePackging -  ');
    // console.log(' - onUpdatePackging -  objCosti >>> ', objCosti  );

    let totPack = 0;
    // console.log(' - 0 > ', totPack  );
    if (objCosti.packaging_confezionamento_costo_totale  !== undefined) {
      totPack += objCosti.packaging_confezionamento_costo_totale;
    }else if (record?.packaging_confezionamento_costo_totale)
      totPack += record.packaging_confezionamento_costo_totale;

    // console.log(' - 1 > ', objCosti.packaging_confezionamento_costo_totale, totPack  );
    // if (objCosti.packaging_confezionamento_costo)
    //   totPack += objCosti.packaging_confezionamento_costo;
    if (objCosti.packaging_confezionamento2_costo_totale !== undefined) {
      totPack += objCosti.packaging_confezionamento2_costo_totale;
    }else if (record?.packaging_confezionamento2_costo_totale)
      totPack += record.packaging_confezionamento2_costo_totale;


    // console.log(' - 2 > ', objCosti.packaging_confezionamento2_costo_totale, totPack  );
    if (objCosti.packaging_confezionamento3_costo_totale !== undefined) {
      totPack += objCosti.packaging_confezionamento3_costo_totale;
    }else if (record?.packaging_confezionamento3_costo_totale)
      totPack += record.packaging_confezionamento3_costo_totale;


    // console.log(' - 3 > ', objCosti.packaging_confezionamento3_costo_totale,  totPack  );
    // if (objCosti.packaging_fondo_costo)
    //   totPack += objCosti.packaging_fondo_costo;
    if (objCosti.packaging_fondo_costo_totale !== undefined) {
      totPack += objCosti.packaging_fondo_costo_totale;
    }else if (record?.packaging_fondo_costo_totale)
      totPack += record.packaging_fondo_costo_totale;


    // console.log(' -4 > ',objCosti.packaging_fondo_costo_totale,  totPack  );
    if (objCosti.packaging_top_costo_totale !== undefined) {
      totPack += objCosti.packaging_top_costo_totale;
    }else if (record?.packaging_top_costo_totale)
      totPack += record.packaging_top_costo_totale;
    // console.log(' - 5 > ',objCosti.packaging_top_costo_totale,  totPack  );

    // console.log('  $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ '   );
    // console.log(' - onUpdatePackging - totPack >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ', totPack  );
    // console.log(' - onUpdatePackging - totPack >>> ', objCosti  );
    setTotalePackaging(totPack);
    //update oggetto costi packaging per il save
    setOffertaPackaging(objCosti);
  }
  const onUpdateCartone = (objCosti) => {

    // console.log(' - totCart - objCosti >>> ', objCosti  );

    let totCart = 0;
    if (objCosti.cartone_cartone_costo_totale)
      totCart += objCosti.cartone_cartone_costo_totale;
    if (objCosti.cartone_coperchio_costo_totale)
      totCart += objCosti.cartone_coperchio_costo_totale;
    if (objCosti.cartone_etichetta_costo_totale)
      totCart += objCosti.cartone_etichetta_costo_totale;

    // console.log(' - totCart - totCart >>> ', totCart  );
    setTotaleCartone(totCart);


    //update oggetto costi cartone per il save
    setOffertaCartone(objCosti);
  }

  const onUpdateFormato = (objFormato) => {
    // creo l'oggetto per il save
    const offerta_formato = {
      formato_id:objFormato.id,
    };
    if (offerta_current_formato !== offerta_formato) {
      if (location.record) {
        // purtroppo se lascio il record nel caso di modifica non mi resetta i campi
        location.record.formato = null;
      }
      // console.log(' - onUpdateFormato - objFormato >>> ', objFormato  );
      setOffertaFormato(offerta_formato);
      // aggiorno le select per fondi e top
      setOffertaFormatoFondi(objFormato.fondi);
      setOffertaFormatoTop(objFormato.top);

      setOffertaFormatoGrammi(objFormato.grammi);
    }
  }

  const onUpdatePezziCartone = (objPezzi) => {
    // console.log('obj - onUpdatePezziCartone - objPezzi >>> ', objPezzi  );
    setOffertaPezzicartone(objPezzi);
  }

  const onUpdateRicette = (objRicette) => {
    // console.log(' ------------------------------------- onUpdateRicette - objRicette >>> ', objRicette  );
    // copia dell'array
    // console.log(' - onUpdateRicette - offerta_current_ricette >>> ', offerta_current_ricette  );
    setOffertaRicette({ricette:[...objRicette]});
  }

  // const handleOk = () => {
  //   setModalVisible(false);
  //
  // }
  // const handleCancel = () => {
  //   setModalVisible(false);
  //
  // }
  // const getAlertModal = () => {
  //   Modal.destroyAll();
  //   let mod = Modal.confirm({
  //     title:(<h3>{lingua.errors.titolo}</h3>),
  //     width:563,
  //     height:300,
  //     centered:true,
  //     content:(<div><p>{lingua.errors.offertaNotSaved}</p></div>),
  //     cancelText:'',
  //     okText:lingua.bts.elimina,
  //     onOk:() => {
  //       mod.destroy();
  //       handleOk();
  //     },
  //   });
  // }

  const getListaFondi = () => {
    if (location.record && location.record.formato && location.record.formato.id) {
      const trovato = costi.data.formato.find(elm => elm.id === location.record.formato.id);
      return trovato?.fondi;
    } else {
      // console.log(' - getListaFondi - offerta_current_formato_fondi >>> ', offerta_current_formato_fondi  );
      return offerta_current_formato_fondi;
    }
  }
  const getListaTop = () => {
    if (location.record && location.record.formato && location.record.formato.id) {
      const trovato = costi.data.formato.find(elm => elm.id === location.record.formato.id);

      return trovato?.top;
    } else {
      return offerta_current_formato_top;
    }
  }

  const getTotPack = () => {



    if (offerta_current_tot_packaging !== undefined) {
      // console.warn("ESISTE", offerta_current_tot_packaging)
      return offerta_current_tot_packaging;
    }
    if (location?.record && record) {

      // i tot sono da record NON location.record
      let totPack = 0;
      if (record.packaging_confezionamento_costo_totale)
        totPack += record.packaging_confezionamento_costo_totale;
      if (record.packaging_confezionamento2_costo_totale)
        totPack += record.packaging_confezionamento2_costo_totale;
      if (record.packaging_confezionamento3_costo_totale)
        totPack += record.packaging_confezionamento3_costo_totale;
      if (record.packaging_fondo_costo_totale)
        totPack += record.packaging_fondo_costo_totale;
      if (record.packaging_top_costo_totale)
        totPack += record.packaging_top_costo_totale;
      return totPack;
    }
    // const pezzi = offerta_current_pezzicartone?offerta_current_pezzicartone.formato_pezzi_cartone:0
    return 0;
  }

  const getTotCart = () => {
    if (offerta_current_tot_cartone !== undefined) {
      return offerta_current_tot_cartone;
    }
    // i tot sono da record NON location.record
    if (location?.record && record) {
      let totCart = 0;
      if (record.cartone_cartone_costo_totale)
        totCart += record.cartone_cartone_costo_totale;
      if (record.cartone_coperchio_costo_totale)
        totCart += record.cartone_coperchio_costo_totale;
      if (record.cartone_etichetta_costo_totale)
        totCart += record.cartone_etichetta_costo_totale;

      // console.log(' - totCart - totCart >>> ', totCart  );
      return totCart;
    }
    return 0;

  }
  const getPezziCartone = () => {
    if (offerta_current_pezzicartone?.formato_pezzi_cartone) {
      return offerta_current_pezzicartone.formato_pezzi_cartone;
    }
    if (location?.record) {
      return location?.record?.formato_pezzi_cartone;
    }
    return 0;
    // const pezzi = offerta_current_pezzicartone?offerta_current_pezzicartone.formato_pezzi_cartone:0
  }
  const getGrammi = () => {
    if (!!offerta_current_formato_grammi) {
      // console.log(' - getGrammi - offerta_current_formato_grammi >>> ', offerta_current_formato_grammi  );

      return offerta_current_formato_grammi;
    }
    if (location?.record) {
      // console.log(' - getGrammi - location?.record >>> ', location?.record  );
      return location?.record?.formato?.grammi;
    }
    //toDO check qui che è ZERO?
    // console.log(' ......................   - getGrammi -  Z E R O ');
    return 0;
    // const pezzi = offerta_current_pezzicartone?offerta_current_pezzicartone.formato_pezzi_cartone:0
  }

  return <div className='App'>
    {contextHolder}
    {/*{console.log('R E N D E R --> record passato', location?.record)}*/}
    {/*{console.log('R E N D E R --> record chiamata', record)}*/}
    {(!location.record? record = null:'')}
    {/*{console.log('R E N D E R', costi?.data)}*/}
    <header className='App-header'>
      <div className={'header-box'}>
        <img className={'header-logo'} alt={'Bertagni logo'} src={'/assets/bertagni.png'}/>
        <User/>
      </div>
    </header>

    {/*{console.log('location?.record - OffertaPage - location?.record >>> ', location?.record  )}*/}
    <OffertaHeader onPressSave={onSaveOfferta} title={title}/>

    {/*<div className={'loadingOfferta'}><LoadingOutlined/> <span>Caricamento in corso...</span></div>*/}

    {loader? <GenericLoading/>:null}
    {/*{record? <h3>{record.codice}</h3>:null}*/}
    {/*{<h3>{loader?'carico':'fatto'}</h3>}*/}

    {costi.data? <div className={`App-page offerta-page  ${loader? 'loadingRecord':''}`}>
      {loader? <div className={'ricettaLoader'}><Spin color={'#ff0000'}/></div>:null}

      <Cliente
        {...record}
        {...costi.data}
        record={record}
        onUpdateCliente={onUpdateCliente}
      />

      <BloccoFormato
        nome={lingua.offerta.formatoRicetta.nome}
        icona={<PackagingIcon/>}
        record={location?.record}
        onUpdateFormato={onUpdateFormato}
        onUpdatePezziCartone={onUpdatePezziCartone}
        {...costi.data}
      />

      <BloccoCostiPackaging
        nome={lingua.offerta.packaging.nome}
        icona={<PackagingIcon/>}
        onUpdateCosti={onUpdatePackging}
        pezziCartone={getPezziCartone()}
        grammi={getGrammi()}
        costi={[
          {
            campo1:lingua.offerta.packagingFondo.label,
            // campo2:lingua.offerta.packagingFondo.quantita,
            lista:getListaFondi(),
            // values:location?.record
            fromFormato:true,
            value:{
              elem_id:location?.record?.packaging_fondo?.id,
              quantita_id:location?.record?.packaging_fondo?.quantita?.id,
              formato_quantita:location?.record?.packaging_fondo?.formato_quantita,
              costo:location?.record?.packaging_fondo_costo,
              unita_misura:location?.record?.packaging_fondo?.unita_misura,
            },
          },
          {
            campo1:lingua.offerta.packagingTop.label,
            campo2:lingua.offerta.packagingTop.quantita,
            lista:getListaTop(),
            fromFormato:true,
            value:{
              elem_id:location?.record?.packaging_top?.id,
              quantita_id:location?.record?.packaging_top_kg?.id,
              costo:location?.record?.packaging_top_costo,
              unita_misura:location?.record?.packaging_top?.unita_misura,
            },
          },
          {
            campo1:lingua.offerta.packagingConfezionamento1.label,
            campo2:lingua.offerta.packagingConfezionamento1.quantita,
            lista:costi.data.confezionamento,
            fromFormato:false,
            value:{
              elem_id:location?.record?.packaging_confezionamento?.id,
              quantita_id:location?.record?.packaging_confezionamento_pz?.id,
              costo:location?.record?.packaging_confezionamento_costo,
              unita_misura:location?.record?.packaging_confezionamento?.unita_misura,
            },
          },
          {
            campo1:lingua.offerta.packagingConfezionamento2.label,
            campo2:lingua.offerta.packagingConfezionamento2.quantita,
            lista:costi.data.confezionamento2,
            fromFormato:false,
            value:{
              elem_id:location?.record?.packaging_confezionamento2?.id,
              quantita_id:location?.record?.packaging_confezionamento2_pz?.id,
              costo:location?.record?.packaging_confezionamento2_costo,
              unita_misura:location?.record?.packaging_confezionamento2?.unita_misura,
            },
          },
          {
            campo1:lingua.offerta.packagingConfezionamento3.label,
            campo2:lingua.offerta.packagingConfezionamento3.quantita,
            lista:costi.data.confezionamento3,
            fromFormato:false,
            value:{
              elem_id:location?.record?.packaging_confezionamento3?.id,
              quantita_id:location?.record?.packaging_confezionamento3_pz?.id,
              costo:location?.record?.packaging_confezionamento3_costo,
              unita_misura:location?.record?.packaging_confezionamento3?.unita_misura,
            },
          },
        ]}/>

      <BloccoCostiCartone
        nome={lingua.offerta.cartone.nome}
        icona={<CartoneIcon/>}
        pezziCartone={getPezziCartone()}
        onUpdateCosti={onUpdateCartone}
        costi={[
          {
            campo1:lingua.offerta.cartoneCartone.label,
            campo2:lingua.offerta.cartoneCartone.quantita,
            lista:costi.data.cartone,
            value:{
              elem_id:location?.record?.cartone_cartone?.id,
              quantita_id:location?.record?.cartone_cartone_pz?.id,
              costo:location?.record?.cartone_cartone_costo,
            },
          },
          {
            campo1:lingua.offerta.cartoneCoperchio.label,
            campo2:lingua.offerta.cartoneCoperchio.quantita,
            lista:costi.data.coperchio,
            value:{
              elem_id:location?.record?.cartone_coperchio?.id,
              quantita_id:location?.record?.cartone_coperchio_pz?.id,
              costo:location?.record?.cartone_coperchio_costo,
            },
          },
          {
            campo1:lingua.offerta.cartoneEtichetta.label,
            lista:costi.data.etichetta,
            value:{
              elem_id:location?.record?.cartone_etichetta?.id,
              quantita_id:location?.record?.cartone_etichetta_pz?.id,
              costo:location?.record?.cartone_etichetta_costo,
            },
          },
        ]}/>

      <OffertaRicette
        // offerta={miaOfferta}
        recordRicette={record?.ricette || []}
        listaTutteRicette={listaRicette}
        costiPackaging={getTotPack()}
        costiCartone={getTotCart()}
        onRicetteUpdate={onUpdateRicette}
        pezziCartone={getPezziCartone()}
        grammi={getGrammi()}
        {...costi_mock.costiRicetta_mock}
      />
      {/*<button*/}
      {/*  onClick={onShowStats}*/}
      {/*> stampa </button>*/}
    </div>:null}

  </div>;
};

// OffertaPage.propTypes = {
//   onSaved:PropTypes.func.isRequired,
// };

export default OffertaPage;
