import React from 'react';
import {AutoComplete} from 'antd';
import 'antd/dist/antd.css';
import './../../App.scss';
import FloatLabel from '../floatLabel/FloatLabel';
import PropTypes from 'prop-types';


const FloatAutoComplete = props => {
  // const [selectValue, setSelectValue] = useState();

  // const options = [
  //   {record:'Burns Bay Road'},
  //   {record:'Downing Street'},
  //   {record:'Wall Street'},
  // ];

  // const onSelectVal = (record) => {
  //   console.log(' ......................   - onSelectVal -  ');
  //   setSelectValue(record);
  //   props.onSelected(record);
  // }

  const onChangeVal = (value) => {
    // console.log(' ......................   - onChangeVal -  ');
    // questo serve per il clear e l'autodigitazione del campo
    // setSelectValue(record);
    props.onSelected(value);
  }
  return (
    <FloatLabel label={props.field} name='name' value={props.disabled ? '':props.newValue}>
      <AutoComplete
        defaultActiveFirstOption={false}
        allowClear={props.allowClear || true}
        disabled = {props.disabled}
        value={props.disabled ? '':props.newValue}
        onChange={value => onChangeVal(value)}
        // onSelect={record => onSelectVal(record)}
        style={{width:'100%', textAlign:'left'}}
        options={props.options}
        filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
      />
    </FloatLabel>
  );
};


FloatAutoComplete.propTypes = {
  allowClear:PropTypes.bool,
  disabled:PropTypes.bool,
  field:PropTypes.string.isRequired,
  options:PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelected:PropTypes.func.isRequired,
  newValue:PropTypes.string,
};
export default FloatAutoComplete;
