import {configureStore} from '@reduxjs/toolkit'
// import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import rootReducer from './rootReducers'
import rootSaga from './rootSaga';

export default function configureAppStore(preloadedState) {

  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer:rootReducer,
    middleware:[sagaMiddleware],
    preloadedState,
  })
  // toDO OCCHIO: eliminato il getDefaultMiddleware perché tira un errore, più info qui: https://github.com/rt2zz/redux-persist/issues/988
  // FUNZIONA lo stesso, da capire se ci sono controindicazioni future ma per il momento NO
  // middleware: [...getDefaultMiddleware(), sagaMiddleware],
  sagaMiddleware.run(rootSaga)


  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('redux/rootReducers', () => store.replaceReducer(rootReducer))
  }
  return store
}
