import actions from 'redux/Authenticate/actions';
import Modal from 'antd/es/modal/Modal';
import {lingua} from '../../Config/Lingua_it';
// import {listaRicette_mock, ricetteNomi_mock} from '../../helpers/ListaMock';

const initialState = {
  isAuthenticated:false,
  casAuth: null,
  loader:false,
  listaOfferte:[],
  listaNomiRicette:[],
  listaRicette:[],
  ricettaIngredienti:[],
  ingredientiPlane:[],
  costi:[],
  record:{},
  saved:false,
  error:false,
  responseType: '',
  messages:[],
  message:'',
}

function Reducer(state = initialState, action) {

  function planeIngredienti(listone){

    let listaPiatta = [];
    // esiste? perché alcune ricette non hanno dati
    if(listone){
      let nl = listone.length;
      let i = -1;
      while(++i < nl){
        let elm = listone[i];
        let obj = {ricetta_ingrediente_id:elm.id, prezzo: elm.costo};
        if(elm.ingredienti.length > 0 ){
          listaPiatta = [...listaPiatta, ...planeIngredienti(elm.ingredienti)];
        }else{
          listaPiatta.push(obj);
        }
      }
    }
    return listaPiatta;
  }


  let allErrors;
  let propErrors;
  let mess = [];
  let ingredientiPlane;
  // console.log(' ........................................................................................................ ');
  // console.log('Reducer - Reducer - action >>> ', action  );
  switch (action.type) {
    case actions.RESET_ERROR:
      return {...state, error: false}
    case actions.LOGIN:
    case actions.CASCONNECT:
      return {...state, loader:true}
    case actions.LOGIN_SUCCESS:
      // console.log('Reducer - Reducer - action.response >>> ', action.response);
      // return {...state, isAuthenticated:!!action.response.data.token, loader:false}
      return {...state, isAuthenticated:true, loader:false, error:false}
    case actions.LOGIN_FAILURE:
    case actions.CASCONNECT_FAILURE:
      //todo ripristinare con isAuth a false, ora usa i services di Anto e le chiamate a eve.holt@... non funzionano
      Modal.error({
        title: lingua.errors.titolo,
        content: action.type===actions.CASCONNECT_FAILURE? lingua.errors.generic_api : lingua.errors.login,
      });
      return {...state, isAuthenticated:false, loader:false}
      // return {...state, isAuthenticated:true, loader:false}
    case actions.LOGOUT_SUCCESS:
      if(action?.casAuth?.code===203){
        return {...state, isAuthenticated:false, loader:false, casAuth:action.casAuth}
      } else {
        Modal.error({
          title: lingua.errors.titolo,
          content: lingua.errors.generic_api,
        });
      }
      break
    case actions.CASCONNECT_RESPONSE:
      if(action?.casAuth?.code===400){
        Modal.error({
          title: lingua.errors.titolo,
          content: lingua.errors.generic_api,
        });
      } else return {...state, isAuthenticated:action.casAuth.code===201 || null, casAuth:action.casAuth}
      break
    case actions.GET_PAGE_OFFERTE:
      // per il loader
      // console.log('Reducer - Reducer - action.response >>> ', action.response  );
      return {...state, loader:true, error:false}
    case actions.GET_PAGE_OFFERTE_SUCCESS:
      // console.log('Reducer - Reducer - action.response >>> ', action.response  );
      return {...state, loader:false, error:false}
    case actions.GET_PAGE_OFFERTE_FAILURE:
      // console.log('Reducer - Reducer - action >>> ', action  );
      if(action.e && action.e.message) return {...state, loader:false, error:true, message:action.e.message};
      return {...state, loader:false, error:true}
    case actions.GET_OFFERTE:
      ////////////////////////////////////////////////////////////////
      return {...state, listaOfferte:action.offerte, loader:false, error:false}

    case actions.GET_PAGE_OFFERTA:
      return {...state, loader:true, error:false};
    case actions.GET_PAGE_OFFERTA_SUCCESS:
      // console.log('GET_PAGE_OFFERTA_SUCCESS //////////////////////////   - S U C C E S S >>> '   );
      return {...state, loader:false, error:false};
    case actions.GET_PAGE_OFFERTA_FAILURE:
      console.warn('GET_PAGE_OFFERTA_FAILURE //////////////////////////   - F A I L U R E >>> ', action   );
      return {...state, loader:false, error:true, responseType:action.type};
    case actions.GET_ALL_RICETTE:
      // tutte le ricette per le select
      ////////////////////////////////////////////////////
      // offerte.data = {data:[...listone], page:1};

      // console.warn('Reducer - Reducer - action.allRicette >>> ', action.allRicette.data.data.ricette  );
      // action.allCosti.data = [...listaRicette_mock];
      ////////////////////////////////////////////////////////////////
      return {...state, listaRicette:action.allRicette.data.data.ricette, loader:false};
    case actions.GET_ALL_COSTI:
      // console.log(' .........................................................  Reducer - Reducer -  ');
      // console.log('Reducer - Reducer - action.allCosti.data >>> ', action.allCosti.data  );
      // tutti i testi delle select della pagina
      ////////////////////////////////////////////////////
      // offerte.data = {data:[...listone], page:1};
      // const clienti = [...action.allCosti.data.clienti];
      // action.allCosti.data = {...costi_mock, 'clienti':clienti};
      // action.allCosti.data = costi_mock;
      // console.log('Reducer - Reducer - action.costi.data >>> ', action.allCosti.data  );
      ////////////////////////////////////////////////////////////////
      return {...state, costi:{...action.allCosti.data}, loader:false}

    case actions.GET_OFFERTA:
      // console.log('Reducer - Reducer - action.offerta.data >>> ', action.offerta.data.data.offerta  );
      ////////////////////////////////////////////////////////////////
      return {...state, record:{...action.offerta.data.data.offerta}, loader:true}



    case actions.UPDATE_OFFERTA:
      return {...state, loader:true}
    case actions.UPDATE_GET_ALL_COSTI:
      // toDO questa in realtà è in più e serve per la simulazione MA potremmo lasciarla che tanto male non fa
      // tutti i testi delle select della pagina
      ////////////////////////////////////////////////////
      // console.log('Reducer - Reducer - action.costi.data >>> ', action.allCosti.data  );
      ////////////////////////////////////////////////////////////////
      return {...state, costi:{...action.allCosti.data}, loader:false}
    case actions.UPDATE_OFFERTA_CAMPI:
      // console.log('Reducer - Reducer - action.response >>> ', actions.UPDATE_OFFERTA_SUCCESS, action.response);
      return {...state, loader:false}
    case actions.UPDATE_OFFERTA_SUCCESS:
      // console.log('UPDATE_OFFERTA_SUCCESS €€€€€€€€€€€€€€€€€€€€€€€€   - S U C C E S S >>> '   );
      return {...state, loader:false}
    case actions.UPDATE_OFFERTA_FAILURE:
      console.warn('UPDATE_OFFERTA_FAILURE - €€€€€€€€€€€€€€€€€€€€€€€€ >>> ', actions.UPDATE_OFFERTA_FAILURE, action.response);
      return {...state, loader:false}


    case actions.GET_INGREDIENTI_RICETTA:
      return {...state, loader:true}
    case actions.GET_INGREDIENTI_RICETTA_START:
      // console.log(' ......................  GET_INGREDIENTI_RICETTA_START  -  ');
      // console.log('Reducer - Reducer - action.offerte.data >>> ', action.offerte  );
      // per il recupero della lista delle offerte
      // offerte.data = {data:[...listone], page:1};
      // action.offerte.data = [...listaOfferte_mock];
      ////////////////////////////////////////////////////////////////
      return {...state,  loader:false};
    case actions.GET_INGREDIENTI_RICETTA_SUCCESS:
      const ricettaCompleta = action.ricettaIngredienti.data.data.ricetta;
      // console.log('Reducer - Reducer - ricettaCompleta >>> ', ricettaCompleta  );
      ingredientiPlane = planeIngredienti(ricettaCompleta.ingredienti);
      return {...state, ricetta:ricettaCompleta, ricettaIngredienti:ricettaCompleta.ingredienti, ingredientiPlane:ingredientiPlane, loader:false }
    case actions.GET_INGREDIENTI_RICETTA_FAILURE:
      console.warn('GET_INGREDIENTI_RICETTA_FAILURE - €€€€€€€€€€€€€€€€€€€€€€€€ >>> ', actions.GET_INGREDIENTI_RICETTA_FAILURE, action.response);
      return {...state, loader:false}


    case actions.SAVE_OFFERTA:
      // console.log(' ......................  Reducer - Reducer -  ');
      return {...state, loader:true}
    case actions.SAVE_OFFERTA_SUCCESS:
      // console.log(' ......................  Reducer - Reducer -  ', action.response);
      return {...state, saved:!!action.response.data, loader:false}
    case actions.SAVE_OFFERTA_FAILURE:
      console.warn('Reducer - Reducer - NOT SAVED >>> ', action.e.response   );
      // console.warn('Reducer - Reducer -  NOT SAVED >>> ', action.e.response.data.message   );
      allErrors = action.e.response.data.errors;
      propErrors = allErrors? Object.getOwnPropertyNames(allErrors):[];
      mess = [];
      if(propErrors) mess = propErrors.map(elm => allErrors[elm]);
      if(action.e.response.data && action.e.response.data.message) mess.push(action.e.response.data.message);
      return {...state, saved:false, error:true, loader:false, messages:mess}
    case actions.SAVE_OFFERTA_END:
      return {...state, saved:false, error:false, loader:false}

    case actions.DELETE_OFFERTA:
      // action.offerte.data = [...listaOfferte_mock];
      ////////////////////////////////////////////////////////////////
      return {...state, loader:true};
    case actions.DELETE_OFFERTA_SUCCESS:
      // action.offerte.data = [...listaOfferte_mock];
      ////////////////////////////////////////////////////////////////
      return {...state, loader:false};
    case actions.DELETE_OFFERTA_FAILURE:
      console.warn('Reducer - Reducer -  NOT DELETED >>> ', action.e   );
      return {...state, loader:false, error:true, messages:mess};
    case actions.DELETE_OFFERTA_END:
      // action.offerte.data = [...listaOfferte_mock];
      ////////////////////////////////////////////////////////////////
      return {...state, loader:false, error:false};

    case actions.RESET_RECORD:
      // passo i dati cancellati >>> record
      // console.log('Reducer - Reducer -  >>>  D O N E *************'  );
      return {...state, record:null};
    default:
      return state
  }
}

export default Reducer;

// case actions.STAMPA_OFFERTA:
//   return {...state, loader:true};
// case actions.STAMPA_OFFERTA_SUCCESS:
//   return {...state, loader:false};
// case actions.STAMPA_OFFERTA_FAILURE:
//   console.warn('Reducer - Reducer -  NOT PRINTED >>> ', action.e.response   );
//   return {...state, loader:false};
