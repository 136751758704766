/* eslint-disable */

import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';
import '../../../index.css';
import '../../../App.scss';
import style from './CostiIngredienti.module.scss';

import {Space, Table} from 'antd';
import {lingua} from '../../../Config/Lingua_it';
import NumericInput from '../../numericInput/NumericInput';
import {EuroIcon, QuantitaIcon} from '../../../helpers/Icons';
import PropTypes from 'prop-types';
import {ParseToDecimal4}from '../../../helpers/Helper';
// import Icon from '@ant-design/icons';
//


const CostoIngredienti = props => {

  // const [data, setData] = useState(originData);

  const [data, setData] = useState(props.data);
  // ALberto 1 dic 2023: tolgo questi due metodi che apparentemente non fanno nulla
  // const [totale, setTotaleRiga] = useState(0);
  // const [editingKey, setEditingKey] = useState('');

  useEffect(() => {
    if(props.data.length>0){
      for(var item in props.data){
        updateIngredienti(parseFloat(props.data[item].prezzo), props.data[item].id, getTotaleCifra(props.data[item]), props.data[item].grammi);
      }
    }
  },[]);
  // const [record, updateRecord] = useState({});

  const updateIngredienti = (value, id, costo, grammi_quantita) =>{
    props.onUpdateRiga(parseFloat(value), id, costo, grammi_quantita);
  }

  const save = async (key) => {

    try {
      // const row = (await form.validateFields());// as Item; // tira errori, tiparlo di fatto non è strettamente necessario

      const newData = [...data];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
        const item = newData[index];

        newData.splice(index, 1, {
          ...item,
        });
        setData(newData);
        // setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        // setEditingKey('');
      }
      // console.table( data  );
    } catch (errInfo) {
      console.log('Edit campo scarto o costo non valido :', errInfo);
    }
  };

  const onEditQuantita = (evt, record) => {
    if (record) {
      record.grammi = evt;
      save(record.key);
      // console.log(' - onEditCosto - evt, record >>> ', evt, record  );
      // setTotaleRiga(getTotaleCifra(record));
      updateIngredienti(record.prezzo, record.id, getTotaleCifra(record), evt);
    }
  }
  const onEditCosto = (evt, record) => {
    if (record) {
      // TODO verifcare questo
      record.prezzo = evt;
      save(record.key);
      // console.log(' - onEditCosto - evt, record >>> ', evt, record  );
      // setTotaleRiga(getTotaleCifra(record));
      updateIngredienti(evt, record.id, getTotaleCifra(record), record.grammi);
    }
  }
  const getTotaleCifra = (record) =>{
    const {pezziCartone, grammi, scartoRicetta} = props;
    const rec_scarto = record.scarto?record.scarto:0;
    const ric_scarto = scartoRicetta?scartoRicetta:0;
    return ParseToDecimal4(
      ((record.prezzo*record.grammi*(1 + rec_scarto/100)/1000000)*pezziCartone*grammi)*(1+ric_scarto/100));

  }
  const getTotale = (record) => {
    if (record) {
      // console.log('%c - onUpdateRigaIngrediente -  >>> ', 'background: #222; color: #bada55');
      // record.prezzo = evt;
      // save(record.key);
      // console.log(' - onEditCosto - evt, record >>> ', evt, record  );
      // updateIngredienti(evt, record.id);
        return getTotaleCifra(record);
    }
  }

  // teniamolo che non si sa mai:
  // const onEditScarto = (evt, record) => {
  //   if (record) {
  //     record.scarto = evt;
  //     save(record.key);
  //   }
  // }


  const columns = [
    // {
    //   title:'Ingrediente',
    //   dataIndex:'ingrediente',
    //   key:'ingrediente',
    //   render:text => <a>{text}</a>,
    // },

    // QUESTO DI TEST per mostrare anche gli id nella tabella:
    // {
    //   title:'id',
    //   dataIndex:'id',
    //   key:'id' ,
    // },
    {
      title:lingua.offerta.ingredienti.nome,
      dataIndex:'nomecode',
      key:'nome',
    },
    {
      title:lingua.offerta.ingredienti.grammi,
      // dataIndex:lingua.offerta.ingredienti.grammi.toLowerCase(),
      dataIndex:'grammi',
      key:lingua.offerta.ingredienti.grammi.toLowerCase(),
      render:(text, record) => (
          <Space size="small" style={{maxWidth: 120}}>
            <NumericInput icona={<QuantitaIcon/>} style={{height:50}}
                          record={record}
                          maxLength={6}
                          value={record.grammi? record.grammi.toString():record.grammi}
                          onChange={(evt) => onEditQuantita(evt, record)}/>
          </Space>
      ),
    },
    {
      title:lingua.offerta.ingredienti.scarto,
      key:lingua.offerta.ingredienti.scarto.toLowerCase(),

      dataIndex:lingua.offerta.ingredienti.scarto.toLowerCase(),
      render:(text, record) => (
        <Space size='middle'>
          {record.scarto === 0? lingua.offerta.ingredienti.scartoNullo:record.scarto.toString()}
          {/*<a>Invite {record.name}</a>*/}
          {/*<a>Delete</a>*/}
          {/*<Input record={record.costo} />*/}

          {/*<InputNumber*/}
          {/*  defaultValue={record.costo}*/}
          {/*  formatter={record => `€ ${record}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
          {/*  parser={record => record.replace(/\$\s?|(,*)/g, '')}*/}
          {/*  onChange={onChangeInpN}*/}
          {/*/>*/}
        </Space>
      ),
      // render:(text, record) => (
      //   <Space size='middle'>
      //     {/*<a>Invite {record.name}</a>*/}
      //     {/*<a>Delete</a>*/}
      //     {/*<DeleteTwoTone twoToneColor='#bbb' style={{fontSize:'22px'}}/>*/}
      //     <NumericInput icona={<ScissorOutlined style={{color:'#bbb', fontSize:'22px'}}/>} style={{height:50}}
      //                   record={record}
      //                   record={record.scarto.toString()}
      //                   onChange={(evt) => onEditScarto(evt, record)}/>
      //     {/*<Input record={record.scarto} />*/}
      //   </Space>
      // ),
    },
    {
      title:lingua.offerta.ingredienti.prezzo,
      key:lingua.offerta.ingredienti.prezzo.toLowerCase(),
      render:(text, record) => (
        <Space size='middle'>
          {/*<a>Invite {record.name}</a>*/}
          {/*<a>Delete</a>*/}
          {/*<Input record={record.costo} />*/}

          {/*<InputNumber*/}
          {/*  defaultValue={record.costo}*/}
          {/*  formatter={record => `€ ${record}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
          {/*  parser={record => record.replace(/\$\s?|(,*)/g, '')}*/}
          {/*  onChange={onChangeInpN}*/}
          {/*/>*/}
          <NumericInput icona={<EuroIcon/>} style={{height:50}}
                        record={record}
                        value={record.prezzo? record.prezzo.toString():record.costo}
                        onChange={(evt) => onEditCosto(evt, record)}/>
        </Space>
      ),
    },
    {
      title:lingua.offerta.ingredienti.costo,
      // dataIndex:'prezzo',
      key:lingua.offerta.ingredienti.costo.toLowerCase(),

      render:(text, record) => (
        <Space size='middle'>
          {getTotale(record)}
        </Space>
      ),
    },
  ];

  return <>
    <Table className={style.tabella} pagination={false} columns={columns} dataSource={data}/>
  </>
}

CostoIngredienti.propTypes = {
  data:PropTypes.any,
  pezziCartone:PropTypes.number,
  grammi:PropTypes.number,
  scartoRicetta:PropTypes.number,
  onUpdateRiga:PropTypes.func.isRequired,
}
export default CostoIngredienti;
