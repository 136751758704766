import React from 'react';
import {OffertaIcon} from '../../helpers/Icons';
import {lingua} from '../../Config/Lingua_it';
import style from './NoOfferte.module.scss';

const  NoOfferte  = props => {
  return (<div className={style.fullPageMessage}>
    <div><OffertaIcon/></div>
        {props.loading?<div className={style.mess}> <strong> {lingua.loading}</strong></div>:
            <div className={style.mess}><strong> {lingua.offerte.noResults[0]} </strong><br/><h5>{lingua.offerte.noResults[1]}</h5> </div>
        }
    </div>
  );
}

export default NoOfferte;
