import React, {useState} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import style from './OfferteHeader.module.scss';
import {Link} from 'react-router-dom';
import {Col, DatePicker, Row} from 'antd';
import FloatSelect from '../floatSelect/FloatSelect';
import PropTypes from 'prop-types';
import {lingua} from '../../Config/Lingua_it';
import FloatSelectCode from '../floatSelectCode/FloatSelectCode';

const {RangePicker} = DatePicker;

const OfferteHeader = props => {

  // const [selectValue, setSelectValue] = useState();
  //
  // onFilterRicette = (evt) => {
  //   console.log('OfferteHeader - onFilterRicette - evt >>> ', evt);
  // }
  const [clienteScelto, setRicercaCliente] = useState('');
  const [ricettaScelta, setRicercaRicetta] = useState('');

  const onSelectRicetta = (value) => {
    setRicercaRicetta(value);
    props.onSelectedRicetta(value);
  }
  const onSelectCliente = (value) =>{
    setRicercaCliente( value);
    props.onSelectedCliente(value);
  }
  const onSelectRange = (date, dateString) => {
    /**
     * onCalendarChange
     * >>>>
     * 	function(dates: [moment, moment], dateStrings: [string, string], info: { range:start|end })
     */
    props.onSelectedDateRange(date, dateString);
  }

  return (<>
    <div className={style.offerteHeaderBox}>
      <h2>{lingua.offerte.title}</h2>
      <Link
        className={style.btnAdd}
        to='/offerta'><PlusOutlined color={'#ececea'}/>
        {lingua.bts.addOfferta}
      </Link>
      {/*<Route render={({ history}) => (*/}
      {/*  <button onClick={() => { history.push('/users') }}*/}
      {/*  ><PlusOutlined color={'#ececea'} /> OFFERTA</button>*/}
      {/*)} />*/}
    </div>
    <div className={style.offerteHeaderFiltri}>
      <Row gutter={[{xs:6, sm:12, md:25},{xs:6, sm:12, md:24}]}>
        <Col xs={24} sm={24} md={8}>
          <FloatSelect
            newValue={ricettaScelta}
            field={lingua.offerte.filtri.selectRicetta}
            options={props.ricette}
            onSelected={onSelectRicetta}/>
        </Col>
        <Col xs={24} sm={24} md={8} >
          <FloatSelectCode
            newValue={clienteScelto}
            field={lingua.offerte.filtri.selectCliente}
            options={props.clienti} onSelected={onSelectCliente}/>
        </Col>
        <Col xs={24} sm={24} md={{span: 8, offset:0}}>
          <Row justify='end'>
            <Col>
              <RangePicker onChange={onSelectRange}/>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  </>);
}

OfferteHeader.propTypes = {
  ricette:PropTypes.arrayOf(PropTypes.object).isRequired,
  clienti:PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectedRicetta:PropTypes.func.isRequired,
  onSelectedCliente:PropTypes.func.isRequired,
  onSelectedDateRange:PropTypes.func.isRequired,
};

export default OfferteHeader;
