import React, {useEffect, useRef, useState} from 'react';
import style from './NewOfferta.module.scss';
import {Row} from 'antd';
// import {PackagingIcon} from '../../helpers/Icons';
import PropTypes from 'prop-types';
import RigaCostoEstesoBis from './rigaCosto/RigaCostoEstesoBis';


const BloccoCostiPackaging = props => {
  const isInitialMount = useRef(true);
  //
  // const [totale, setTotale] = useState(0);
  // l'ho messo per l'eslint che altrimenti vuole il return tolto e la roba subito nella prima riga
  // const useless = null;
  // const setFirstName = (record) => {
  //   // console.log('BloccoCostiPackaging - setFirstName - record >>> ', record);
  // }
  const [total_obj, setCostiBlocco] = useState({});

  //
  // useEffect(() => {
  //   console.warn(' -useEffect -  >>> '  );
  //
  // }, [props.costi]);


  useEffect(() => {
    if (isInitialMount.current) {
      //blocco al primo giro
      isInitialMount.current = false;
      return;
    }
    const {onUpdateCosti} = props;
    // console.log(' --- >>> ', total_obj  );
    onUpdateCosti(total_obj);

    // props.onUpdateCosti(offerta_obj);
  }, [total_obj]);

  const onUpdateRiga = (obj) => {
    const tot = {...total_obj, ...obj};
    // console.log(' --------------------------------------------- onUpdateRiga - obj >>> ', tot  );
    setCostiBlocco(tot);
    // gli passo l'oggetto da montare perché aggiorna total_obj nel ciclo di render successivo
    const {onUpdateCosti} = props;
    onUpdateCosti(tot);
  }
  return <div className={style.gruppo}>
    {/*<h2> totale: {totale}</h2>*/}
    <div className={style.titleBox}>
      <h3>{props.icona} {props.nome}</h3>
      {/*{setFirstName('')}*/}
    </div>

    <Row gutter={[{xs:6, sm:12, md:25}, {xs:6, sm:12, md:24}]}>
      {props.costi? props.costi.map(elm => (elm && elm.lista? <RigaCostoEstesoBis
        key={`${elm.campo1} ${elm.campo2}`}
        // pezziCartone={elm.fromFormato?props.pezziCartone*10:props.pezziCartone}
        pezziCartone={props.pezziCartone}
        grammi={props.grammi}
        fromFormato={elm.fromFormato}
        field1={elm.campo1}
        field2={elm.campo2}
        // offerta_obj={props.offerta_obj}
        optionsField={elm.lista}
        onUpdateCosti={onUpdateRiga}
        record={elm.value}
        category={`${props.nome}_${elm?.lista[0]?.tipo}`.toLowerCase()}/>:null)):null}
    </Row>
  </div>;

}

BloccoCostiPackaging.propTypes = {
  // offerta_obj:PropTypes.object.isRequired,
  onUpdateCosti:PropTypes.func,
  nome:PropTypes.string.isRequired,
  icona:PropTypes.element,
  // optionsCosto:PropTypes.arrayOf(PropTypes.string).isRequired,
  // optionsQuantita:PropTypes.arrayOf(PropTypes.string),
  // onDelete:PropTypes.func,
  pezziCartone:PropTypes.number,
  grammi:PropTypes.number,
  costi:PropTypes.arrayOf(
    PropTypes.shape({
      campo1:PropTypes.string.isRequired,
      campo2:PropTypes.string,
      fromFormato:PropTypes.bool,
      lista:PropTypes.arrayOf(PropTypes.shape({
        id:PropTypes.number,
        tipo:PropTypes.string,
        label:PropTypes.string,
        quantita:PropTypes.arrayOf(PropTypes.object),
      })).isRequired,
      value:PropTypes.shape({
        elem_id:PropTypes.number,
        quantita_id:PropTypes.number,
        formato_quantita:PropTypes.number,
      }),
    })),
};


export default BloccoCostiPackaging;
