import {Col} from 'antd';
import FloatSelect from '../../floatSelect/FloatSelect';
import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import NumericInput from '../../numericInput/NumericInput';
import {EuroIcon} from '../../../helpers/Icons';
import FloatSelectCode from '../../floatSelectCode/FloatSelectCode';
import {lingua} from '../../../Config/Lingua_it';
import FloatText from '../../floatText/FloatText';
import {ParseToDecimal4} from '../../../helpers/Helper';

const RigaCostoEstesoBis = props => {




  const [costo, setCosto] = useState(props.record?.costo);
  const [unita_misura, setUnitaMisura] = useState(null);
  const [tipo, setTipo] = useState(props.record?.elem_id);
  const [scarto, setScarto] = useState(null);
  const [formato_quantita, setFormatoQuantita] = useState(props.record?.formato_quantita);
  const [quantita, setQuantita] = useState(props.record?.quantita_id);
  // const [optionsQuantita, setOptionsQuantita] = useState([]);
  const [optionsQuantitaObj, setOptionsQuantitaObj] = useState(null);
  const [optionsQuantitaObjValue, setOptionsQuantitaObjValue] = useState(null);
  const [totale, setTotale] = useState(parseInt(props.record?.totale || 0));
  // const dispatch = useDispatch();
  const [offerta_obj, setCostiObj] = useState({});

  const kg_string = 'kg';

  const isInitialMount = useRef(true);
  const isInitialMountBis = useRef(true);

  useEffect(() => {
    if (isInitialMountBis.current) {
      //blocco al primo giro
      isInitialMountBis.current = false;
      setOptionsQuantitaObjValue(onSetValueFromRecord('elem_id'));
      setTotale(getTotaleRiga());
      return;
    }
    setTotale(getTotaleRiga());

    const isEmpty = Object.values(props.record).every(elm => elm === null || elm === undefined);

    // const styles = ['color: green', 'background: yellow'].join(';');
    // const message = `Obj vuoto: ${isEmpty} `;
    // console.log('%c%s', styles, message);

    // console.log(' -  - props.fromFormato && !props.record >>> ', props.fromFormato , props.record  );
    // record può essere {}
    if (props.fromFormato && isEmpty) {

      // RESET
      const nuovo = {...offerta_obj}
      Object.getOwnPropertyNames(nuovo).forEach(elm => {
        nuovo[elm] = null;
      });
      setCostiObj(nuovo);
      onSelectTipo(null);
    }
  }, [props.optionsField]);

  useEffect(() => {
    if (isInitialMount.current) {
      //blocco al primo giro
      isInitialMount.current = false;
      return;
    }

    const campo1 = props.field1;//.toLowerCase();
    const campo2 = props.field2;//.toLowerCase();

    const payload = {};
    payload[campo1] = tipo;

    // quantita è l'id
    if (campo2) payload[campo2] = quantita;

    // console.log('LISTA OGGETTI DELLA RIGA: ', props.optionsField);
    const obj_cat_id = `${props.category}_id`;
    const obj_cat_costo = `${props.category}_costo`;
    const obj_cat_tot = `${props.category}_costo_totale`;

    if (offerta_obj && props.optionsField.length > 0) {
      //creo il nome delle proprietà
      //recupero l'obj se esiste
      const scelto_obj = props.optionsField.find(elm => elm.id === tipo);
      //console.log(' -  - scelto_obj >>> ', scelto_obj  );
      if (scelto_obj) {
        offerta_obj[obj_cat_id] = scelto_obj.id;
        const new_tot = getTotaleRiga();
        offerta_obj[obj_cat_tot] = new_tot;
        // questo manda in loop
        setTotale(new_tot);

        // console.log(' -  - obj_cat_tot >>> ', obj_cat_tot,offerta_obj[obj_cat_tot]  );
        if (campo2 && scelto_obj.quantita?.length && quantita) {
          //creo il nome della proprietà
          // const obj_cat_quant = `${props.category}_${props.optionsField[0].quantita[0].tipo}_id`;
          //recupero la quantita via id

          const quantita_obj = scelto_obj.quantita.find(elm => elm.id === quantita);
          if (quantita_obj) {
            //ricerco il primo che ha un array di quantità e mi creo la prop, tipo _pz o _kg o quello che è
            const primoConQuant = props.optionsField.find(elm => elm.quantita.length > 0);
            let obj_cat_quant = props.optionsField[0].quantita.length > 0? `${props.category}_${primoConQuant.quantita[0].tipo}_id`:null;
            if(props.category=== 'packaging_top') {
              obj_cat_quant = `${props.category}_kg_id`
            }
            //console.log(' -  - obj_cat_quant >>> ', obj_cat_quant  );
            offerta_obj[obj_cat_quant] = quantita;
          }
        }else{
          if(props.category === 'packaging_top') {
            let obj_cat_quant = `${props.category}_kg_id`;
            offerta_obj[obj_cat_quant] = null;
          }
        }
        offerta_obj[obj_cat_costo] = parseFloat(costo); // torna string for some reason
      } else {

        const qulabel = props.optionsField.find(elm => elm.quantita.length > 0);
        //console.log(' -  - qulabel.quantita[0] >>> ', qulabel?.quantita[0]  );
        // console.log(' -  - qulabel >>> ', qulabel  );
        offerta_obj[obj_cat_id] = null;
        offerta_obj[obj_cat_tot] = null;
        if (qulabel) offerta_obj[`${props.category}_${qulabel.quantita[0].tipo}_id`] = null;
        offerta_obj[obj_cat_costo] = null;

        if(props.category=== 'packaging_top') {
          offerta_obj[`${props.category}_kg_id`] = null;
        }
      }
      //
    }

    // console.log(' ..................................');
    // console.log(' ->>> USE EFFECT - offerta_obj >>> ', offerta_obj);

    props.onUpdateCosti(offerta_obj);

    // toDO 26 luglio: questa non serve più. a quanto pare:
    //chiamata per il ri-calcolo dei costi e aggiornamento dei dati, purtroppo asfalta l'obj passato
    // dispatch({
    //   type:actions.UPDATE_OFFERTA,
    //   payload:payload,
    // });
  }, [tipo, costo, quantita, props.field1, props.field2, props.pezziCartone]);


  useEffect(() => {
    if (isInitialMount.current) {
      //blocco al primo giro
      isInitialMount.current = false;
      return;
    }


    const obj_cat_tot = `${props.category}_costo_totale`;

    const scelto_obj = props.optionsField.find(elm => elm.id === tipo);
    if (offerta_obj.obj_cat_tot && props.optionsField.length > 0 && scelto_obj) {

      const new_tot =  getTotaleRiga();
      offerta_obj[obj_cat_tot] = new_tot;

      setTotale(new_tot);
      // console.log(' ->>> USE EFFECT - offerta_obj >>> ', offerta_obj);

      props.onUpdateCosti(offerta_obj);
    }


  }, [props.pezziCartone]);

  const resetRecordCosto = () => {
    const {record} = props;
    // asfalto eventulae valore costo dalle props
    record.costo = undefined;
    record.prezzo = undefined;
  }

  const resetRecordQuantita = () => {
    resetRecordCosto();
    const {record} = props;
    // asfalto eventulae valore costo dalle props
    record.quantita_id = undefined;
  }


  const onSelectTipo = (evt) => {

    const {record} = props;
    // asfalto eventule valore dalle props
    record.elem_id = undefined;
    resetRecordQuantita();

    // se entrambi undefined non va pi vanti ^_^'
    // inoltre se scelgo lo stesso cmq faccio il reset del costo, 2 righe più sopra
    if (evt === tipo && evt !== undefined) return;

    //reset dei campi associati, al cambio del tipo
    if (!evt) {
      // console.log(' U N D E F I N E D- onSelectTipo -  >>> ');
      // window.blur();
      // evt  === ''
      setTipo('');
      setQuantita('');
      setOptionsQuantitaObj(null);
      setCosto(0);
      setUnitaMisura(null);
      setScarto(0);
      setFormatoQuantita(null);

      setTotale(0);

      const obj_cat_tot = `${props.category}_costo_totale`;
      offerta_obj[obj_cat_tot] =  getTotaleRiga();

      props.onUpdateCosti(offerta_obj);
      return;
    }

    setOptionsQuantitaObjValue(onSetValueFromRecord('elem_id'));
    setQuantita('');
    setTipo(evt);

    //cerco l'elem scelto e imposto le proprietà
    const elem = props.optionsField.find(elm => elm.id === evt);


    if(unita_misura !== elem.unita_misura) {
      setUnitaMisura(elem.unita_misura);
    }

    if (elem.scarto && elem.scarto !== scarto) {
      setScarto(elem.scarto);
    } else {
      setScarto(0)
    }
    if (elem.formato_quantita && elem.formato_quantita !== formato_quantita) {
      setFormatoQuantita(elem.formato_quantita);
    } else {
      setFormatoQuantita(null)
    }
    if (elem.quantita && elem.quantita.length > 0 && elem.quantita !== quantita) {
      setOptionsQuantitaObj(elem.quantita);
    } else {
      setOptionsQuantitaObj(null);
    }

    if (elem.costo && elem.costo !== costo) {
      setCosto(elem.costo);
    } else {
      setCosto(0)
    }

  }

  const setCostoToTipoValue = () => {
    const elem = props.optionsField.find(elm => elm.id === tipo);
    if (elem && elem.costo) {
      setCosto(elem.costo);
    } else {
      setCosto(0)
    }
  }
  const onSelectQuantita = (evt, sel_options) => {
    resetRecordQuantita();
    if (!evt) {
      // riporto il costo a 0 o al valore del elem selezionato
      setCostoToTipoValue();

      return setQuantita('');
    }

    setQuantita(evt);

    const quantita_obj = sel_options.find(elm => elm.id === evt);

    const costoDiQuantita = quantita_obj? quantita_obj.costo:0;

    setCosto(costoDiQuantita);

  }


  const onChangeNumCosto = newValue => {
    if(newValue === '') newValue = 0;
    if(costo === newValue) return;
    resetRecordCosto();
    setCosto(newValue);
    //
    // toDo metterci un debounce QUI non dentro il component MA in attesa di capire come funzionerà

    // toDO va scommentato per l'aggiornamento dei dati SE NECESSARIO - SETT 2021: a quanto pare non più necessario
    // dispatch({
    //   type:actions.UPDATE_OFFERTA,
    //   payload:{'costo':newValue},
    // });
  };

  const onSetValueFromRecord = (campo) => {


    // chiamato a OGNI ciclo di render
    const {record, optionsField} = props;
    if (!optionsField.length) {

      resetRecordQuantita();
      record.quantita_id =
        record.elem_id =
          record.costo = undefined;
      return {
        optionsQuantitaObjValue:undefined,
      };
    }

    if(record.elem_id && record.costo === undefined) {
      record.costo = 0;
    }

    if ( (record && record.elem_id) || totale === 0){
      setTotale(getTotaleRiga());
    }
    
    if ( record && record.elem_id && !props.hideQuantita) {
      //imposto il costo, quale che sia anche undefined
      // record_costo = record.costo? record.costo:costo;

      // esiste il record, autocompleto
      // non è stato scelto nulla con la select quindi entro solo se il dato è undefined (if forse inutile in realtà)
      if (tipo === record.elem_id) {
        const elm_trovato = optionsField.find(elm => elm.id === record[campo]);
        if (elm_trovato) {
          // console.warn('T R O V A ', record['quantita_id'])
          // console.warn('elm_trovato ',elm_trovato);
          const quantita_trovato = elm_trovato.quantita.find(quan => quan.id === record['quantita_id'])

          if (quantita_trovato) {
            return {
              optionsQuantitaObjValue:elm_trovato.quantita,
            };
          } else {
            // quantita non trovata ma la lista potrebbe esistere, verifico
            const quantita_lista = (elm_trovato.quantita && elm_trovato.quantita.length > 0)? elm_trovato.quantita:null

            return {
              optionsQuantitaObjValue:quantita_lista,
            };
          }
        }

      }
    }
    return {optionsQuantitaObjValue:null};
  }

  const onSetListFromRecord = (campo) => {

    // chiamato a OGNI ciclo di render
    const {record, optionsField} = props;
    if (!optionsField.length) {

      resetRecordQuantita();
      record.quantita_id =
          record.elem_id =
              record.costo = undefined;
      return        undefined;
    }
    if(record.elem_id && record.costo === undefined) {
      record.costo = 0;
    }
    // if ( record && record.elem_id && totale === 0){
      //setTotale(getTotaleRiga());
    // }
    if ( record && record.elem_id && !props.hideQuantita) {
      //imposto il costo, quale che sia anche undefined
      // record_costo = record.costo? record.costo:costo;

      // esiste il record, autocompleto
      // non è stato scelto nulla con la select quindi entro solo se il dato è undefined (if forse inutile in realtà)
      if (tipo === record.elem_id) {
        const elm_trovato = optionsField.find(elm => elm.id === record[campo]);
        if (elm_trovato) {
          const quantita_trovato = elm_trovato.quantita.find(quan => quan.id === record['quantita_id'])

          if (quantita_trovato) {
            return elm_trovato.quantita;
          } else {
            // quantita non trovata ma la lista potrebbe esistere, verifico
            return (elm_trovato.quantita && elm_trovato.quantita.length > 0) ? elm_trovato.quantita : null;
          }
        }

      }
    }
    return null;
  }

  const getTotaleRiga = (giveNum = true) => {
    let totTemp = costo || 0;
    // let formato_quantita_curr = formato_quantita;
    // let unita_misura_curr = unita_misura;

    if(!scarto && tipo) {

      const el = props.optionsField.find(elm => elm.id === tipo);
      let unita_misura_curr = el.unita_misura;

      if(el.scarto && scarto !== el.scarto) {
        setScarto(el.scarto);
      }
      if(unita_misura_curr && unita_misura_curr !== unita_misura) {
        setUnitaMisura(unita_misura_curr);
      }
      // questo NON è la quantità della select e va lasciata sempre
      let formato_quantita_curr = el.formato_quantita;
      if(formato_quantita_curr && formato_quantita_curr !== formato_quantita) {
        setFormatoQuantita(formato_quantita_curr);
      }
    }

    if (props.fromFormato) {
      //caso controllato da formato ( >> 250-300 gr)

      if (formato_quantita) {
        // c'è formato_quantità nell'obj (NON è pezzi cartone ma un campo del costo)
        if (!unita_misura || unita_misura.toLowerCase() !== kg_string) {
          // caso NON kg ( >> PZ o qualsiasi altro valore)
          totTemp = totTemp * formato_quantita * props.pezziCartone;
        } else {
          // kg
          // totTemp = totTemp * formato_quantita * (1 + scarto_curr / 100) * props.pezziCartone;
          // -- W A R N -- ATTENZIONE - tolto lo scarto come da richiesta, rimettere la riga (con (1 + scarto_curr / 100) ) sopra se cambiando idea:
          // https://logicweb.atlassian.net/browse/BR-79
          totTemp = totTemp * formato_quantita * props.pezziCartone;
        }
      } else {
        // non c'è formato_quantità nell'obj per qualche motivo
        //
        // totTemp = totTemp * (1 + scarto_curr / 100) * props.pezziCartone;
        // -- W A R N -- ATTENZIONE - tolto lo scarto come da richiesta, rimettere la riga (con (1 + scarto_curr / 100) ) sopra se cambiando idea:
        // https://logicweb.atlassian.net/browse/BR-79
        totTemp = totTemp * props.pezziCartone;
      }

    } else {

      if (props.hideQuantita) {
        // per le righe senza quantità

        // totTemp = (totTemp*props.pezziCartone*(1+scarto_curr)*.000001)*props.grammi*props.pezziCartone;
      } else {
        // con quantità

        if (!unita_misura || unita_misura.toLowerCase() !== kg_string) {
          // caso NON kg ( >> PZ o qualsiasi altro valore)
          totTemp = totTemp * props.pezziCartone;
        } else {
          // KG
          totTemp = totTemp * props.pezziCartone * (1 + scarto / 100);
        }
      }
    }

    totTemp = ParseToDecimal4(totTemp);
    if (giveNum) return totTemp;
    return `${totTemp}`;
  }

  const getFormatoQuantita = () => {
    let quant;
    if (props.fromFormato) {
      if(tipo === ''){
        // nella select ho cancellato il dato, metto la quantità a 0
        quant = 0;
        return `${quant}`;
      }
      if (formato_quantita) quant = formato_quantita;
      else {
        const scelto_obj = props.optionsField.find(elm => elm.id === props.record.elem_id);
        quant = scelto_obj? scelto_obj.formato_quantita:null;
      }
    } else {
      quant = (props.pezziCartone? props.pezziCartone:0);
    }
    quant = ParseToDecimal4(quant);
    return `${quant}`;
  }
  //
  // const {
  //   optionsQuantitaObjValue,
  // } = onSetValueFromRecord('elem_id');


  // const {first} = firstGiroWithRecord();


  return <>
    {/*<Col xs={24} sm={24} md={24} className={'rigaTest'}>*/}
    {/*  <h2>---- T E S T ----</h2>*/}
    {/*  <h2>TOTALE {totale}</h2>*/}
    {/*  <h3>props.fromFormato {props.fromFormato? 'si':'no'}  --- </h3>*/}
    {/*  <p>record.elem_id {props.record?.elem_id} -- tipo: {tipo} --- scarto {scarto}</p>*/}
    {/*  <p>costo {costo} record_costo {record_costo} --- record_tot {record_tot} >>>> tot {getTotaleRiga()}</p>*/}
    {/*</Col>*/}
    <Col xs={24} sm={24} md={12}>
      <FloatSelectCode
        disabled={!props.optionsField.length}
        newValue={tipo}
        field={props.field1}
        options={props.optionsField}
        onSelected={onSelectTipo}/>
    </Col>
    {!props.hideQuantita? <Col xs={24} sm={24} md={2}>
      <FloatText field={lingua.offerta.riga.costoSingolo} newValue={getFormatoQuantita()}/>
    </Col>:null}


    {(optionsQuantitaObj || optionsQuantitaObjValue)? <>
      <Col xs={24} sm={24} md={{span:3, offset:props.hideQuantita? 2:0}}>
        <FloatSelect
          disabled={!tipo}
          newValue={quantita}
          field={props.field2}
          options={onSetListFromRecord('elem_id') || (optionsQuantitaObj && optionsQuantitaObj.length > 0? optionsQuantitaObj:null)}
          // options={optionsQuantitaObj && optionsQuantitaObj.length > 0? optionsQuantitaObj:(optionsQuantitaObjValue? optionsQuantitaObjValue:null)}
          onSelected={onSelectQuantita}/>
      </Col>
      <Col xs={24} sm={24} md={{span:4, offset:0}}>
        <NumericInput
          disabled={!tipo} icona={<EuroIcon/>}
          value={costo} onChange={onChangeNumCosto}/>
      </Col>
      {/*{showTotale()}*/}
    </>:<>
      <Col xs={24} sm={24} md={{span:4, offset:props.hideQuantita? 5:3}}>
        <NumericInput
          disabled={!tipo} icona={<EuroIcon/>} value={costo}
          onChange={onChangeNumCosto}/>
      </Col>
      {/*{showTotale()}*/}
    </>}

    <Col xs={24} sm={24} md={3}>
      <FloatText field={lingua.offerta.riga.costoTot} newValue={totale.toString()}/>
    </Col>
  </>
};

RigaCostoEstesoBis.propTypes = {
  // offerta_obj:PropTypes.object,
  field1:PropTypes.string.isRequired,
  field2:PropTypes.string,
  hideQuantita:PropTypes.bool,
  optionsField:PropTypes.arrayOf(PropTypes.object).isRequired,
  record:PropTypes.object,
  tipo:PropTypes.string,
  category:PropTypes.string.isRequired,
  onUpdateCosti:PropTypes.func.isRequired,
  pezziCartone:PropTypes.number,
  grammi:PropTypes.number,
  fromFormato:PropTypes.bool,
  // optionsQuantita:PropTypes.arrayOf(PropTypes.string),
  // onDelete:PropTypes.func,
  // options1:PropTypes.arrayOf(
  //   PropTypes.shape({
  //
  //     record:PropTypes.string.isRequired,
  //     costo:PropTypes.string,
  //     days:PropTypes.arrayOf(PropTypes.string),
  //     record:PropTypes.string.isRequired,
  //   }),
  // ),
};

export default RigaCostoEstesoBis;
