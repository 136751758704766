import {useDispatch, useSelector} from 'react-redux'
import actions from 'redux/Authenticate/actions';
import { Button, Form, Input} from 'antd';
// import type { ColumnsType } from 'antd/es/table';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import React, {useEffect} from 'react';
import {lingua} from '../Config/Lingua_it';
import constants from "../Config/constants";
import { useLocation } from 'react-router-dom'

function LoginPage() {

  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const skiploginQuery = new URLSearchParams(window.location.search).get("skiplogin");


  const {loader} = useSelector(state => state.authenticateReducer)
  // const {listaOfferte} = useSelector(state => state.authenticateReducer)
  const dispatch = useDispatch();


  useEffect(() => {
    const skiplogin = searchParams.get('skiplogin') ;

    // per gestire la visualizzazione della login, nostra o loro
    // check doppio sia nel param passato a login che in tutto l'url
    if(constants.SKIP_LOGIN ==='TRUE') {

      if(skiplogin !== null || skiploginQuery !== null){
        dispatch({
          type: actions.LOGIN,
          payload: {'email': 'user@bertagni1882.it', 'password': 'CbaAb66R'},
        });
      }
    }
    // fino a qui
  }, [search]);


  let onFinish = (values) => {
    // console.log('LoginPage - onFinish - values >>> ', values  );

    /* dispatch({
      type:actions.LOGIN,
      payload:{'email':'eve.holt@reqres.in', 'password':'cityslicka'},
    }); */
    // user@bertagni1882.it
    // CbaAb66R

    //toDo rimettere questa:
    dispatch({
      type:actions.LOGIN,
      payload:{'email':values.username, 'password':values.password},
    });
  };

  // interface DataType {
  //   key: React.Key;
  //   name: string;
  //   age: number;
  //   address: string;
  // }

  // const columns: ColumnsType<DataType> = [
  //   {
  //     title: 'Full Name',
  //     width: 100,
  //     dataIndex: 'name',
  //     key: 'name',
  //     fixed: 'left',
  //   },
  //   {
  //     title: 'Age',
  //     width: 100,
  //     dataIndex: 'age',
  //     key: 'age',
  //     fixed: 'left',
  //   },
  //   {
  //     title: 'Column 1',
  //     dataIndex: 'address',
  //     key: '1',
  //     width: 150,
  //   },
  //   {
  //     title: 'Column 2',
  //     dataIndex: 'address',
  //     key: '2',
  //     width: 150,
  //     sorter: (a, b) => a.name.length - b.name.length,
  //     sortDirections: ['descend'],
  //   },
  //   {
  //     title: 'Column 3',
  //     dataIndex: 'address',
  //     key: '3',
  //     width: 150,
  //   },
  //   {
  //     title: 'Column 4',
  //     dataIndex: 'address',
  //     key: '4',
  //     width: 150,
  //   },
  //   {
  //     title: 'Column 5',
  //     dataIndex: 'address',
  //     key: '5',
  //     width: 150,
  //   },
  //   {
  //     title: 'Column 6',
  //     dataIndex: 'address',
  //     key: '6',
  //     width: 150,
  //   },
  //   { title: 'Column 7', dataIndex: 'address', key: '7' },
  //   {
  //     title: 'Action',
  //     key: 'operation',
  //     fixed: 'right',
  //     width: 100,
  //     render: () => <a>action</a>,
  //   },
  // ];

  // const data: DataType[] = [];
  // for (let i = 0; i < 100; i++) {
  //   data.push({
  //     key: i,
  //     name: `Edrward ${i}`,
  //     age: 32,
  //     address: `London Park no. ${i}`,
  //   });
  // }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    dispatch({
      type:actions.LOGIN_FAILURE,
    });
  };
  //
  // const onPressTest = () =>{
  //   dispatch({
  //     type:actions.GET_PAGE_OFFERTE,
  //   });
  // }
  return (
    <div className='container container_login' style={{backgroundImage:'url(/assets/bgLogin.jpg)'}}>
      {/*<div className='robacancella'><button onClick={onPressTest}> Recupera roba da url</button>*/}
      {/*  <ul>*/}
      {/*    {listaOfferte && listaOfferte.data? listaOfferte.data.map(elm => <li key={elm.id}>{elm.email}</li>) :null}*/}
      {/*  </ul>*/}
      {/*</div>*/}
      {/*<Table className='tabella-temp' columns={columns} dataSource={data} scroll={{ x: 1500, y: 300 }} />*/}

      {(constants.SKIP_LOGIN ==='FALSE' || (constants.SKIP_LOGIN ==='TRUE' && searchParams.get('skiplogin') === null) ) && constants.LOCAL_LOGIN === 'TRUE' && (
        <Form style={{backgroundImage:'url(/assets/bertagni.png)'}}
          name='normal_login'
          className='form'
          initialValues={{
            remember:true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name='username'
            rules={[
              {
                required:true,
                message:lingua.login.insertUser,
              },
            ]}>
            <Input
              size='large'
              prefix={<UserOutlined className='site-form-item-icon'/>}
              placeholder={lingua.login.username} autoComplete='username'/>
          </Form.Item>
          <Form.Item
            name='password'
            rules={[
              {
                required:true,
                message:lingua.login.insertPass,
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className='site-form-item-icon'/>}
              type='password'
              placeholder={lingua.login.password}
              size='large'
              autoComplete='current-password'
            />
          </Form.Item>
          {/*<Form.Item>*/}
          {/*  <Form.Item name='remember' valuePropName='checked' noStyle>*/}
          {/*    <Checkbox>Remember me</Checkbox>*/}
          {/*  </Form.Item>*/}
          {/*</Form.Item>*/}

          <Form.Item>
            <Button loading={loader} type='primary' htmlType='submit' className='login-form-button' size='large'>{lingua.bts.login}
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}

export default LoginPage;
