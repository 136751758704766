import React from 'react';
import {Select} from 'antd';
import 'antd/dist/antd.css';
import './../../App.scss';
import FloatLabel from '../floatLabel/FloatLabel';
import PropTypes from 'prop-types';


const {Option} = Select;

const FloatSelect = props => {
  // const [selectValue, setSelectValue] = useState();

  // let options = [
  //   'Jack', 'Lucy', 'Tim', 'Tom', 'Jerry',
  // ];

  const onSelectVal = (value) => {
    // lo spara fuori e lo riceve poi dal parente via newValue pechè andrà di volta in volta aggiornato ESTERNAMENTE
    // setSelectValue(record);
    // console.log('record - onSelectVal - record >>> ', value  );
    props.onSelected(value, props.options);
  }

  // const componentDidUpdate = (propsPrecedenti) => {
  //   // Utilizzo tipico (non dimenticarti di comparare le props):
  //   if (this.props.idUtente !== propsPrecedenti.idUtente) {
  //     // this.fetchData(this.props.idUtente);
  //   }
  //   console.log(' - componentDidUpdate - >>>>>>>>>>>>>>>>>>>>>>>>>>> >>> ');
  // }

  function getNewValue() {
    const nValue = props.newValue !== undefined? props.options.find(elm => elm.id === props.newValue):'';
    if(nValue){
      if(nValue.label) return nValue.label;
      if(nValue.nome) return nValue.nome;
    }
    return null;

  }

  return (props.options? <FloatLabel
    label={props.field} name='name' value={getNewValue()}>
    <Select
      disabled={props.disabled}
      showSearch={true}
      optionFilterProp='children'
      style={{width:'100%'}}
      onChange={value => onSelectVal(value)}
      value={getNewValue()}
      allowClear>
      {
        // props.options.map((elm, index) => <Option key={`${elm} ${index}`} record={elm.toLowerCase()}>{elm}</Option>)
        props.options.map((elm, index) => <Option key={`${elm.label} ${index}`}
          value={elm.id}> {elm.label} {elm.code} {elm.nome}</Option>)
      }
    </Select>
  </FloatLabel>:null);
};


FloatSelect.propTypes = {
  field:PropTypes.string.isRequired,
  // valueImpost:PropTypes.string,
  options:PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelected:PropTypes.func.isRequired,
  newValue:PropTypes.any,

};
export default FloatSelect;
