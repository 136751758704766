// sostituito BrowserRouter con HashRouter
import {HashRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import './App.scss';
// import './AppTheme.less';
import LoginPage from './pages/LoginPage';
import OffertePage from './pages/OffertePage';
import OffertaPage from 'pages/OffertaPage';
import ScrollToTop from './helpers/ScrollToTop';
import {useEffect} from "react";

import actions from "./redux/Authenticate/actions";
import Modal from "antd/es/modal/Modal";
import {lingua} from "./Config/Lingua_it";
import constants from './Config/constants';

// import {warning} from "@ant-design/icons/es/utils";


function App() {
    const dispatch = useDispatch();

    // const search = useLocation().search
    // const searchParams = new URLSearchParams(search)

    //Getting isAuthenticated store record from Authentication reducer.
    const {isAuthenticated} = useSelector(state => state.authenticateReducer);
    const {casAuth} = useSelector(state => state.authenticateReducer);

    const queryParams = new URLSearchParams(window.location.search)
    const ticket = queryParams.get("ticket")
    const skiplogin = queryParams.get("skiplogin")

    // const search = useLocation();
    // const searchParams = new URLSearchParams(search)

    useEffect(() => {

        // ticket da url qui
        // per gestire la visualizzazione della login, nostra o loro
        // check doppio sia nel param passato a login che in tutto l'url
        const saltaLogin = constants.SKIP_LOGIN === 'FALSE' ||(  constants.SKIP_LOGIN === 'TRUE' && skiplogin == null );
        if (saltaLogin) {

            // const skiplogin = searchParams.get('skiplogin');
            if (constants.LOCAL_LOGIN === 'FALSE') {
                dispatch({
                    type: actions.CASCONNECT,
                    payload: {
                        ticket: ticket,
                    },
                });
            }
        }

    }, []);

    useEffect(() => {
        // console.log("casAuth",casAuth);
        if (casAuth?.code) switch (casAuth.code) {
            case 201: // logged
                // console.log("code 201, logged, managed on reducer");

                break;
            case 203: // redirect
                // console.log("code 203, redirect to:",casAuth.data.redirect);
                if (casAuth.data.redirect.length > 0) {
                    window.location.href = casAuth.data.redirect;
                    break;
                }
            default:
                Modal.error({
                    title: lingua.errors.titolo,
                    content: lingua.errors.generic_api,
                });
        }
    }, [casAuth]);

    return (
        <Router>
            <ScrollToTop/>
            <Switch>
                <PublicRoute path='/login' isAuthenticated={isAuthenticated}>
                    <LoginPage/>
                </PublicRoute>
                <PrivateRoute path='/' isAuthenticated={isAuthenticated}>
                    <ProtectedRoutes/>
                </PrivateRoute>
            </Switch>
        </Router>
    );
}

// Private route restrict to access public pages after login.
function PrivateRoute({children, isAuthenticated, ...rest}) {
    return (
        <Route
            {...rest}
            render={({location}) =>
                (isAuthenticated ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {from: location},
                        }}
                    />
                ))
            }
        />
    );
}

// Public route restrict to access authenticated pages before login.
function PublicRoute({children, isAuthenticated, ...rest}) {
    return (
        <Route
            {...rest}
            render={
                ({location}) =>
                    (!isAuthenticated ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/dashboard',
                                state: {from: location},
                            }}
                        />
                    ))
            }
        />
    );
}

// Here we include the components which need to be accesses after successful login.
function ProtectedRoutes() {

    return (
        <Switch>
            <Route path='/dashboard'>
                <OffertePage/>
            </Route>
            <Route path='/offerta'>
                <OffertaPage/>
            </Route>
        </Switch>
    );
}

export default App;
